import { enumReducerType } from '../common';

export const filterReducer = (
  state = {
    filts: [],
    selectedfilts: [],
    searcheddata: [],
    filterpayloadinsight: '',
    countryData: [],
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.SET_FILTER_SELECTED_DATA:
      return {
        ...state,
        selectedfilts: [...state.selectedfilts, action.payload],
      };
    case enumReducerType.REMOVE_FILTER_SELECTED_DATA:
      const { name, id } = action.payload;
      const filteredData = state.selectedfilts.filter(
        (item) => !(item.name === name && item.id === id)
      );
      return {
        ...state,
        selectedfilts: filteredData,
      };

    case enumReducerType.SET_SEARCHED_LARGE_LIST_DATA:
      return {
        ...state,
        searcheddata: action.payload,
      };
    case enumReducerType.SET_FILTER_PAYLAOD_INSIGHT:
      return {
        ...state,
        filterpayloadinsight: action.payload,
      };
    case enumReducerType.SET_SEARCH_TERM:
      return {
        ...state,
        searchterm: action.payload,
      };
    case enumReducerType.SET_COUNTRY_DATA:
      return {
        ...state,
        countryData: action.payload,
      };
    default:
      return state;
  }
  return state;
};
