import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import CardInsightsCarouselFull from '../../core/Cards/CardInsightsCarouselFull';
import CardInsightsFeatured from '../../core/Cards/CardInsightsFeatured';
import { enumReducerType } from '../../../state/common';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { mapBackgroundColor } from '../../../utils/HelperUtils';
import { ComponentDataAttributes } from '../../../constants/gtm';

// const CarouselInsights = ({ type, backgroundColor, items, ...props }) => {
const CarouselInsights = (props, type = 'component') => {
  const [showTags, setShowTags] = useState([]);
  const dispatch = useDispatch();
  const {
    fields,
    rendering: { componentName },
  } = props;
  const {
    title,
    subTitle,
    featuredInsight,
    tagsToShow,
    insightitems = [],
    isShowGlobalButton,
    backgroundTextColor,
  } = fields;
  let slidesToShow = 0;
  const insightitemsLength = insightitems && insightitems?.length > 0 ? insightitems?.length : 0;

  switch (insightitemsLength) {
    case 1:
      slidesToShow = 1;
      break;
    case 2:
      slidesToShow = 2;
      break;
    default:
      // eslint-disable-next-line no-unused-vars
      slidesToShow = 3.2;
  }

  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // variableWidth: true,
    swipeToSlide: true,
    arrows: true,
    slide: '.card',
    accessibility: true,
    // afterChange: () => hideAriaHiddenTiles(),
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">
          {i < 9 ? '0' : ''}
          {i + 1}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">
          {insightitems && insightitems?.length < 10 ? '0' : ''}
          {insightitems && insightitems?.length}
        </span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1.2,
          slidesToScroll: 0.95,
        },
      },
    ],
  };
  useEffect(() => {
    tagsToShow &&
      tagsToShow.map((item) => {
        const type = enumReducerType.SET_CAROUSEL_SHOW_TAG_LIST;
        let payload = [];
        switch (item?.fields?.name?.value.replace(/\s/g, '').toLowerCase()) {
          case 'lineofbusiness':
            setShowTags(fields.lineOfBusiness);
            payload = fields.lineOfBusiness;
            break;
          case 'solutions':
            setShowTags(fields.solutions);
            payload = fields.solutions;
            break;
          default:
            setShowTags([]);
        }
        const action = { type, payload };

        dispatch(action);
      });
  }, [tagsToShow]);

  return (
    <section
      className={`carousel standard insights cards__${insightitemsLength} ${type} ${backgroundTextColor?.fields?.text?.value}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row section-header">
          <div className="col-12 col-md-8">
            {title?.value && (
              <h2 className={`title ${backgroundTextColor?.fields?.text?.value} `}>
                <TextSitecoreField field={title} />
              </h2>
            )}
            {subTitle?.value && (
              <p className={`sub-title ${backgroundTextColor?.fields?.text?.value}`}>
                <TextSitecoreField field={subTitle} />
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col carousel-wrapper related-insights">
            <Slider {...settingsSliderNav}>
              {featuredInsight &&
                featuredInsight?.length > 0 &&
                showTags &&
                featuredInsight.map((item1, index1) => (
                  <CardInsightsCarouselFull
                    key={index1}
                    title={item1?.fields?.Title?.value}
                    subTitle={item1?.fields?.subTitle?.value}
                    contentTags={item1?.fields?.contentType}
                    date={item1?.fields?.publicationDate}
                    text={item1?.fields?.Content?.value}
                    isShowGlobalButton={isShowGlobalButton ? isShowGlobalButton.value : false}
                    image={item1.fields?.image}
                    componentName={componentName}
                    item={item1}
                    fields={fields}
                    backgroundColor={backgroundTextColor?.fields?.text?.value}
                    ctaUrl={item1.url}
                  />
                ))}
              {insightitems &&
                insightitems?.length > 0 &&
                insightitems?.map((item, index2) => (
                  <CardInsightsCarouselFull
                    key={index2}
                    item={item}
                    date={item?.fields?.publicationDate}
                    title={item?.fields?.Title?.value}
                    text={item?.text}
                    url={item?.url}
                    ctaText={item?.ctaText}
                    backgroundColor={backgroundTextColor?.fields?.text?.value}
                    contentTags={item?.fields?.contentType}
                    tagsToShowList={tagsToShow}
                    componentName={componentName}
                    subTitle={item?.fields?.subTitle?.value}
                    isShowGlobalButton={isShowGlobalButton ? isShowGlobalButton.value : false}
                    image={item.fields?.image}
                    fields={fields}
                  />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

CarouselInsights.defaultProps = {
  // eslint-disable-next-line quotes

  backgroundColor: 'primary',
  type: 'component',
  fields: {
    insightitems: [],
    title: {
      value: '',
    },
  },
  fields: {},
  backgroundTextColor: '',
};

CarouselInsights.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.oneOf(['component', 'sub-component']),
  /** Cards */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      ctaText: PropTypes.string,
    })
  ),
};

export default CarouselInsights;
