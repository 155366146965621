import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { isEmpty } from 'lodash';

const FeaturettePromoIntro = ({ cssClass, ...props }) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { Title, backgroundColor, SubTitle } = fields;

  return (
    <section
      className={`featurette-section standard featurettePromoIntro ${backgroundColor?.fields?.text?.value}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div>
        <div className="row content-wrapper">
          <div className="col-lg-12 featurettePromoIntro-wrapper">
            <div className="featurette-body-standard">
              <h3 className={`title ${isEmpty(SubTitle?.value) ? 'titleFont' : ''}`}>
                {<TextSitecoreField field={Title} />}
              </h3>
              {SubTitle && !isEmpty(SubTitle?.value) && (
                <div className="sub-title">{<RichTextSitecoreField field={SubTitle} />}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturettePromoIntro.defaultProps = {
  fields: {
    Title: {
      value: '',
    },
    SubTitle: {
      value: '',
    },
    backgroundColor: {
      fields: {
        text: {
          value: '',
        },
      },
    },
  },
};
FeaturettePromoIntro.propTypes = {
  fields: PropTypes.shape(
    { title: PropTypes.shape({ value: PropTypes.string }) },
    { subTitle: PropTypes.shape({ value: PropTypes.string }) },
    {
      backgroundColor: PropTypes.string,
    }
  ),
};

export default FeaturettePromoIntro;
