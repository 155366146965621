import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CardEventCarousel from '../Cards/CardEventCarousel';
import Slider from 'react-slick';
import { isBrowserDocumentAvailable } from '../../../utils/HelperUtils';

const CarouselEvents = ({ items, eventCarousalTitle, componentName, item, fields }) => {
  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    cssEase: 'ease-in-out',
    accessibility: true,
    afterChange: () => hideAriaHiddenTiles(),
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">0{i + 1}</span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">0{items.length}</span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.2,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          infinite: false,
        },
      },
    ],
  };

  function hideAriaHiddenTiles() {
    if (isBrowserDocumentAvailable) {
      Array.from(document?.querySelectorAll('.slick-slide')).forEach((slide) => {
        slide.style.visibility = slide.classList?.contains('slick-active') ? 'visible' : 'hidden';
        // if (slide.classList.contains('slick-active')) slide.setAttribute('tabindex', -1);
        // else slide.removeAttribute('tabindex');
      });
    }
  }

  useEffect(() => {
    hideAriaHiddenTiles();
  });

  return (
    <section className={'carousel standard events'}>
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="col-sm-8 section-header">
            <h3 className="title">{eventCarousalTitle?.value}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col carousel-wrapper">
            <Slider {...settingsSliderNav}>
              {items &&
                items?.map((item) => (
                  <CardEventCarousel
                    key={item?.id}
                    title={item?.fields?.Title?.value}
                    subTitle={item?.fields?.subTitle?.value}
                    text={item?.fields?.Content?.value}
                    contentType={item?.fields?.contentType?.fields?.title?.value}
                    date={item?.fields?.publicationDate?.value}
                    videoLength={item?.fields?.videoLength?.value}
                    componentName={componentName}
                    item={item}
                    fields={fields}
                  />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

CarouselEvents.defaultProps = {
  title: '',
  cssClass: '',
  variant: 'events',
  items: [],
};

CarouselEvents.propTypes = {
  title: PropTypes.string,
  cssClass: PropTypes.string,
  variant: PropTypes.string,
  /** Cards */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      backgroundColor: PropTypes.string,
    })
  ),
};

export default CarouselEvents;
