import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { ButtonDataAttributes } from '../../constants/gtm';
import { isObject } from 'lodash';

const Button = ({
  cssClass,
  text,
  fontSize,
  variant,
  icon,
  iconPosition,
  arrow,
  BtnFunction,
  type,
  componentName = '',
  dataItemName = '',
  addMoreClass = '',
}) => {
  if (type) {
    return (
      <button
        className={`button ${addMoreClass} ${variant.toLowerCase()} ${cssClass} ${fontSize} ${iconPosition} ${
          arrow ? 'signpost' : ''
        }`}
        onClick={BtnFunction}
        {...ButtonDataAttributes({
          name: componentName,
          text: isObject(text) ? text?.props?.field?.value : text?.value || text,
          dataItemName: isObject(dataItemName)
            ? dataItemName?.props?.field?.value
            : dataItemName?.value || dataItemName,
          variant,
          arrow,
        })}
      >
        {icon && (
          <img
            src={mediaApi.updateImageUrl(icon)}
            className={`icon ${iconPosition}`}
            alt={text ? text : ''}
          />
        )}
        {text}
        {arrow && <div className="arrow-icon"></div>}
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={BtnFunction}
      className={`button ${addMoreClass} ${variant} ${cssClass} ${fontSize} ${iconPosition} ${
        arrow ? 'signpost' : ''
      }`}
      {...ButtonDataAttributes({
        name: componentName,
        text,
        variant,
        arrow,
        dataItemName: isObject(dataItemName)
          ? dataItemName?.props?.field?.value
          : dataItemName?.value || dataItemName,
      })}
    >
      {icon && !arrow && (
        <img src={mediaApi.updateImageUrl(icon)} className={`icon ${iconPosition}`} alt={text} />
      )}
      {text}
      {arrow && !icon && <div className="arrow-icon"></div>}
    </button>
  );
};

Button.defaultProps = {
  text: '',
  dataItemName: '',
  fontSize: 'medium',
  cssClass: '',
  icon: '',
  iconPosition: 'icon__left',
  variant: 'primary-button',
  arrow: false,
  type: false,
};

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  dataItemName: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),

  /** Enabled for SignPost CTA */
  arrow: PropTypes.bool,

  /** Size of Button Text */
  fontSize: PropTypes.oneOf(['medium', 'small']),

  /** Icon Source and Position */
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['icon__left', 'icon__right']),

  /** Button Color */
  cssClass: PropTypes.string,

  /** Solid, Outline or Text */
  variant: PropTypes.oneOf([
    'primary-button',
    'secondary-button',
    'text-button',
    'nav-button',
    'insight',
  ]),

  type: PropTypes.bool,
};

export default Button;
