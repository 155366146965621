import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import DecisionTreeCard from '../DecisionTreeCard/DecisionTreeCard';
import CloseButton from '../../../globals/buttons/CloseButton/CloseButton';
import BackButton from '../../../globals/buttons/BackButton/BackButton';
import DecisionTreeBreadcrumb from '../DecisionTreeBreadcrumb/DecisionTreeBreadcrumb';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import './decisionTreeStep2.scss';

const DecisionTreeStep2 = ({
  subTitle,
  title,
  breadcrumbs,
  featuredImage,
  cards,
  currentStep,
  backBtnText,
  backBtnIcon,
  backBtnMobileIcon,
  closeIcon,
  onBackClick,
  onCardClick,
  closeDecisionTree,
  componentName,
}) => {
  const isVariant = currentStep === 2.2;
  const isBigScreen = useMediaQuery({ minWidth: breakpoints.md });

  return (
    <section className={`dt-step2 dt-step2--${currentStep.toString().replace('.', '-')}`}>
      <div className="dt-step2__container">
        <div className="dt-step2__header">
          {featuredImage && !isBigScreen && (
            <ImageSitecoreField
              field={featuredImage}
              className="dt-step2__image"
            ></ImageSitecoreField>
          )}
          <div className="dt-step2__btn-container">
            <BackButton
              backBtnText={backBtnText}
              backBtnIcon={isBigScreen ? backBtnIcon : backBtnMobileIcon}
              cssClass={!isVariant ? 'black-mobile' : ''}
              onClick={onBackClick}
            />
            {(!isBigScreen || isVariant) && (
              <CloseButton
                icon={closeIcon}
                cssClass={isVariant ? 'black' : 'black'}
                onClick={closeDecisionTree}
              />
            )}
            {(isVariant || isBigScreen) && <DecisionTreeBreadcrumb breadcrumbs={breadcrumbs} />}
          </div>
          <div className="dt-step2__title-container">
            {!isVariant && !isBigScreen && <DecisionTreeBreadcrumb breadcrumbs={breadcrumbs} />}
            <h2 className="dt-step2__sub-title">
              {subTitle && subTitle?.value && (
                <TextSitecoreField field={subTitle}></TextSitecoreField>
              )}
            </h2>
            <h3 className="dt-step2__title">
              {title && title?.value && <TextSitecoreField field={title}></TextSitecoreField>}
            </h3>
          </div>
        </div>
        <div className="dt-step2__card-container">
          {cards &&
            cards.map((card, index) => (
              <DecisionTreeCard
                key={index}
                index={index}
                title={card?.fields?.title}
                text={card?.fields?.subTitle}
                arrowIcon={card?.fields?.arrowBtnIcon}
                buttonLink={card?.fields?.ctaLink?.value?.href}
                buttonText={card?.fields?.ctaText}
                image={card?.fields?.image}
                variant={currentStep}
                onClick={currentStep !== 2.2 ? onCardClick : undefined}
                componentName={componentName}
              />
            ))}
        </div>
      </div>
      {isBigScreen && !isVariant && (
        <div className="dt-step2__img-container">
          {featuredImage && (
            <ImageSitecoreField
              field={featuredImage}
              className="dt-step2__image"
            ></ImageSitecoreField>
          )}
          <CloseButton icon={closeIcon} cssClass="black" onClick={closeDecisionTree} />
        </div>
      )}
    </section>
  );
};

DecisionTreeStep2.defaultProps = {
  subTitle: '',
  title: '',
  breadcrumbs: [],
  featuredImage: '',
  cards: [],
  backBtnText: '',
  backBtnIcon: {},
  closeIcon: {},
  onBackClick: () => {},
  onCardClick: () => {},
  closeDecisionTree: () => {},
};

DecisionTreeStep2.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  featuredImage: PropTypes.string,
  cards: PropTypes.array,
  backBtnText: PropTypes.string,
  backBtnIcon: PropTypes.shape({}),
  closeIcon: PropTypes.shape({}),
  currentStep: PropTypes.number,
  onBackClick: PropTypes.func,
  onCardClick: PropTypes.func,
  closeDecisionTree: PropTypes.func,
};

export default DecisionTreeStep2;
