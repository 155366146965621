import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../src/globals/buttons/Button';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags } from '../../../utils/HelperUtils';

const CardInsightsListing = ({
  title,
  text,
  ctaUrl,
  ctaText,
  ctaLinkHref,
  date,
  contentType,
  latest,
  mediaLength,
  componentName = '',
  fields,
  item,
}) => {
  return (
    <a href={ctaUrl} className={'card card__listing'}>
      <div className="card-header p-0">
        <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />

        {latest ? (
          <>
            <div className="label">
              <div className="button tag solid violet medium button-webinar">Latest</div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="card-body p-0">
        <h3 className="title mt-0">{title}</h3>
        <p>{text}</p>
      </div>
      <div className="">
        <Button
          variant="text-button"
          text={ctaText}
          arrow={true}
          componentName={componentName}
          dataItemName={title}
          BtnFunction={() => {
            location.href = ctaLinkHref || '';
          }}
        />
      </div>
    </a>
  );
};

CardInsightsListing.defaultProps = {
  latest: '',
  tags: [],
  contentType: '',
  date: '',
  title: '',
  text: '',
  ctaUrl: '',
  ctaText: '',
  componentName: '',
  ctaLinkHref: '',
};

CardInsightsListing.propTypes = {
  /** For new content */
  latest: PropTypes.bool,
  /** Content tag (i.e. webinar, article, or movie etc.) */
  tags: PropTypes.array,
  contentType: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  componentName: PropTypes.string,
  ctaLinkHref: PropTypes.string,
};

export default CardInsightsListing;
