import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../../../src/components/sitecorefields/ImageSitecoreField';
import './closeButton.scss';

const CloseButton = ({ onClick, cssClass, icon }) => {
  return (
    <button className={`close-button ${cssClass}`} aria-label="Close button" onClick={onClick}>
      <ImageSitecoreField field={icon}></ImageSitecoreField>
    </button>
  );
};

CloseButton.defaultProps = {
  cssClass: '',
  icon: '',
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
  cssClass: PropTypes.string,
  icon: PropTypes.string,
};

export default CloseButton;
