import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enumReducerType } from '../../../state/common';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { fetchSearchResult } from '../../../service';
// import FilterDropdownSearch from '../FilterDropdownSearch/FilterDropdownSearch';
import SearchResultFilterDropdown from '../SearchResultFilterDropdown/SearchResultFilterDropdown';
import Button from '../../../globals/buttons/Button';
import IconFilter from '../../../static/icons/icon--filter-currentColor.svg';
import IconFilterWhite from '../../../static/icons/icon--filter-white.svg';
import IconSearch from '../../../static/icons/icon--search.svg';
import '../FilterContent/filterContent.scss';
import { getBrowserWindow, isSCExperienceEditorActive } from '../../../utils/HelperUtils';

const SearchResultFilter = ({
  title,
  totalItems,
  sortOptions,
  filters,
  sortPopular,
  sortRelevance,
  selectedSortOption,
  component = '',
  searchKeyword,
  filterToggle,
}) => {
  // let dropdownFilter = filters;
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const dispatch = useDispatch();
  // const [filterToggle, setFilterToggle] = useState(false);
  const [filterGtm, setFilterGtm] = useState('');
  // const [sortRelevance, setSortRelevance] = useState('false');
  // const [sortPopular, setSortPopular] = useState('false');
  // const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0].value);
  const selectedsearchfilts = useSelector((state) => state.searchfilterReducer.selectedsearchfilts);

  const buildPostService = async () => {
    const filtsList = [...new Set(selectedsearchfilts.map((item) => item.key))].filter(
      (item) => item !== undefined
    );
    const InsightsFilters = filtsList
      .map(
        (item) =>
          `${item?.toLowerCase().replace(/\s/g, '')}=${selectedsearchfilts
            .filter((curr) => curr.key === item)
            .map((currItem) =>
              currItem.key === 'Regions' || currItem.key === 'Types'
                ? currItem?.filt
                : currItem?.filt?.flat()
            )
            .join(',')}`
      )
      .join('|');

    const filterForGtm = filtsList
      .map(
        (item) =>
          `${item?.toLowerCase().replace(/\s/g, '')}=${selectedsearchfilts
            .filter((curr) => curr.key === item)
            .map((currItem) => currItem.name)
            .join(',')}`
      )
      .join('|');
    setFilterGtm(filterForGtm);

    const insightAction = {
      type: enumReducerType.SET_SEARCH_FILTER_PAYLAOD_INSIGHT,
      payload: InsightsFilters,
    };
    dispatch(insightAction);
  };
  useEffect(() => {
    buildPostService();
  }, [selectedsearchfilts, sortRelevance, sortPopular]);

  useEffect(() => {
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        if (filterGtm !== '') {
          let parts = filterGtm.split('|');
          const filters = {};
          // Dynamically map each part to the correct filter
          parts.forEach((part) => {
            const [key, value] = part.split('=');
            filters[key] = value.split(',').join(',');
          });
          if (parts) {
            window?.dataLayer?.push({
              event: 'insights_filter',
              filter1: filters.solutions || 'NA', // Solutions
              filter2: filters.regions || 'NA', // Regions
              filter3: filters.topics || 'NA', // Topics
              filter4: filters.types || 'NA', // Types
            });
          }
        }
      }
    }
  }, [filterGtm]);

  return (
    <section className="filter-content">
      <div className="container-xl">
        {title && (
          <div className="row">
            <div className="col">
              <h2 className="title">{title}</h2>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col search_header search_filter">
            {isMobile ? (
              filterToggle && (
                <div className="search_filter_wrapper wrapper_2">
                  {filters?.map((i) => (
                    <SearchResultFilterDropdown
                      key={i.id}
                      filterItem={i?.fields?.name?.value}
                      options={i?.fields?.values}
                    />
                  ))}
                </div>
              )
            ) : (
              <div className="search_filter_wrapper wrapper_3">
                {filters &&
                  filters?.map((i) => (
                    <SearchResultFilterDropdown
                      key={i.id}
                      filterItem={i?.fields?.name?.value}
                      options={i?.fields?.values}
                      sortPopular={sortPopular}
                      sortRelevance={sortRelevance}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
        {component !== 'siteSearch' && (
          <div className="row search_footer">
            <div className="col search_count">
              <p>{`We have ${totalItems} insights`}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

SearchResultFilter.defaultProps = {
  title: '',
  solutions: [],
  regions: [],
  topics: [],
  types: [],
  sortOptions: [
    { label: 'Relevant', value: 'relevant' },
    { label: 'Recent', value: 'recent' },
    { label: 'Popular', value: 'popular' },
  ],
};

SearchResultFilter.propTypes = {
  title: PropTypes.string,
  solutions: PropTypes.array,
  regions: PropTypes.array,
  topics: PropTypes.array,
  types: PropTypes.array,
  sortOptions: PropTypes.array,
};

export default SearchResultFilter;
