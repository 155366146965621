import React from 'react';
import PropTypes from 'prop-types';
import CardForFooterAction from '../../core/Cards/cardforfooteraction';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CalloutBarFooterAction = (props) => {
  const {
    fields = {},
    rendering: { componentName = '' },
  } = props;
  const { title, ctaText, backgroundColor, ctaLink } = fields;

  return (
    <section
      className="calloutbar-section footer footerAction"
      {...ComponentDataAttributes(componentName)}
    >
      <div className="row">
        <div className="col-lg-12 card-wrapper">
          <CardForFooterAction
            title={title}
            ctaText={ctaText || ctaText?.value}
            backgroundColor={backgroundColor?.fields?.text?.value || 'violet'}
            href={ctaLink?.value?.href}
            linkType={ctaLink?.value?.linktype}
            linkFields={ctaLink}
            componentName={componentName}
          />
        </div>
      </div>
    </section>
  );
};

CalloutBarFooterAction.defaultProps = {
  fields: {
    title: {},
    ctaText: {},
    ctaLink: {},
  },
  componentName: '',
};

CalloutBarFooterAction.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    ctaLink: PropTypes.shape({}),
  }),
  componentName: PropTypes.string,
};

export default CalloutBarFooterAction;
