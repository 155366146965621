import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import SubNavPanel from '../SubNav/SubNavPanel';
import './accordion.scss';
import { accessibilityButtonProps } from '../../../../constants/accessibility';

const AccessibleDiv = ({ children, ...props }) => {
  const { className, onShow, ...rest } = props;
  const { role, tabIndex, onKeyDown } = accessibilityButtonProps(onShow, 0);
  return (
    <div className={className} role={role} tabIndex={tabIndex} onKeyDown={onKeyDown} {...rest}>
      {children}
    </div>
  );
};

const AccordionTabItem = ({
  title,
  name,
  text,
  ctaText,
  ctaLink,
  linkHeading,
  links,
  cssClass,
  iconSrc,
  isActive,
  onShow,
  backgroundColor,
  APIID,
  ChartHeading,
  chartComponentName,
}) => {
  return (
    <AccessibleDiv className={`accordion-block ${isActive ? 'active' : ''}`} onClick={onShow}>
      <h3 className="accordion-title">
        <img src={mediaApi.updateImageUrl(iconSrc)} alt={name} />
        {name}
        <div className="icon"></div>
      </h3>
      <div className="accordion-body" onClick={(e) => e.stopPropagation()}>
        <SubNavPanel
          title={title}
          text={text}
          ctaText={ctaText}
          ctaLink={ctaLink}
          linkHeading={linkHeading}
          links={links}
          cssClass={cssClass}
          backgroundColor={backgroundColor}
          APIID={APIID}
          ChartHeading={ChartHeading}
          name={name}
          chartComponentName={chartComponentName}
        />
      </div>
    </AccessibleDiv>
  );
};

AccordionTabItem.defaultProps = {
  title: '',
  iconSrc: '',
};

AccordionTabItem.propTypes = {
  title: PropTypes.string,
  iconSrc: PropTypes.string,
};

export default AccordionTabItem;
