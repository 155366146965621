import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CardInsightsFull from '../Cards/CardInsightsFull';
import Pagination from '../Navigation/Pagination/Pagination';
import { fetchCardBlockLargeList } from '../../../service';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardsListingLargeNoFilter = ({ items, columns, ...props }) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;

  const { tagsToShow, templateType } = fields;
  const [itemsPerPage] = useState(6);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [showTags, setShowTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [contentTags, setContentTags] = useState([]);
  const allfiltoptionswithduplicates = useSelector(
    (state) => state.masterReducer.allfiltoptionswithduplicates
  );
  const [data, setData] = useState([]);
  const [selectedfilts, setSelectedfilts] = useState([]);
  const [limit] = useState(6);
  const [offset] = useState(0);
  const sendDataToParent = async (index) => {
    setPageNumber(index);
    const result = await fetchCardBlockLargeList(selectedfilts, 6, index - 1, 'en');
    const payload = result.data;
    setData(payload.searchResult);
  };

  const buildPostService = async () => {
    const filtsList2 = templateType.map((item) => item?.name);

    const InsightsFilters =
      'types=' + [...new Set(filtsList2.map((value) => value.toLowerCase()))].join(',');
    setSelectedfilts(InsightsFilters);

    const result = await fetchCardBlockLargeList(
      InsightsFilters,
      limit,
      offset,
      'en',
      'false',
      'false'
    );
    const payload = result.data;
    setData(payload.searchResult);
    setTotalItems(payload.totalCount);
  };

  const getContentTags = (ct) => {
    const filtered = fields?.contentType?.filter((item) => item?.id === ct);
    const ctTags = filtered.map((item) => ({
      id: item?.id,
      text: item?.fields?.title?.value,
      icon: item?.fields?.title?.icon,
    }));
    return ctTags;
  };
  useEffect(() => {
    buildPostService();
  }, []);

  useEffect(() => {
    if (tagsToShow.length > 0) {
      tagsToShow.forEach((tag) =>
        setTags(
          fields[tag?.name?.toLowerCase()]
            .map((tagData) =>
              tagData?.fields?.subTag
                ? {
                    name: tagData?.fields?.subTag?.name,
                    icon: tagData?.fields?.subTag?.icon?.value,
                  }
                : { name: tagData?.fields?.title?.value, icon: tagData?.fields?.icon?.value }
            )
            .slice(0, 2)
        )
      );
    }
  }, [allfiltoptionswithduplicates]);

  return (
    <section
      className="card-block insights insights__listing"
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <h1>{fields.title.value}</h1>
        <div className={`col columns columns__${columns} cards cards__${items.length}`}>
          {data &&
            data.map((item, id) => (
              <CardInsightsFull
                key={id}
                subTitle={item?.subTitle}
                title={item?.title}
                text={item?.content}
                ctaUrl={item?.url}
                contentTags={getContentTags(item?.contentType[0])}
                contentType={item?.contentType}
                solutionsTags={tags}
                date={item?.date}
                latest={item?.latest}
                img={item?.image?.src}
                altText={item?.altText}
                item={item}
                fields={fields}
              />
            ))}
        </div>
        {totalItems && (
          <div className={`col columns columns__${columns} cards cards__${items.length}`}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={pageNumber}
              sendDataToParent={sendDataToParent}
            />
          </div>
        )}
      </div>
    </section>
  );
};

CardsListingLargeNoFilter.defaultProps = {
  title1: '',
  items: [],
  columns: '3',
};

CardsListingLargeNoFilter.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  columns: PropTypes.oneOf(['1', '2', '3', '4']),
};

export default CardsListingLargeNoFilter;
