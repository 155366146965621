import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import {
  getBrowserWindow,
  getGlobalLanguageFromCookie,
  getLanguageConfig,
  languageTodisplayOnFooter,
} from '../../../utils/HelperUtils';
import LocationGlobeWhite from '../MultiLanguageModal/images/icon-location-global-white.svg';

const FooterLanguageSelector = ({ id, target, icon, countriesCtaLink }) => {
  const { country, language } = getBrowserWindow
    ? getLanguageConfig(window?.location?.pathname)
    : { isGlobal: true, language: 'en', country: 'Global' };

  const [savedCountry, setsavedCountry] = useState(country);
  const [savedLanguage, setsavedLanguage] = useState(
    languageTodisplayOnFooter(language) || getGlobalLanguageFromCookie() || 'EN'
  );

  useEffect(() => {
    setsavedCountry(country);
    setsavedLanguage(languageTodisplayOnFooter(language || getGlobalLanguageFromCookie() || 'EN'));
  }, [savedCountry, savedLanguage]);
  return (
    <button
      id={id}
      type="button"
      className="lang-selector button-text"
      data-bs-toggle="modal"
      data-bs-target={target}
      onClick={() => {
        window.location.href = countriesCtaLink?.value?.href;
      }}
    >
      {Object.keys(icon?.value).length > 0 ? (
        <ImageSitecoreField field={icon} />
      ) : (
        <img src={LocationGlobeWhite} alt="language" />
      )}
      &nbsp;
      {savedCountry}&nbsp;{savedLanguage ? `(${savedLanguage?.toUpperCase()})` : ''}
    </button>
  );
};

FooterLanguageSelector.defaultProps = {
  id: 'footerLangNav',
  target: '#langModal',
  icon: {},
};

FooterLanguageSelector.propTypes = {
  id: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.shape({}),
};

export default FooterLanguageSelector;
