import React from 'react';
import PropTypes from 'prop-types';
import CardInsightsEvent from '../Cards/CardInsightsEvent';
import CarouselEvents from '../Carousels/CarouselEvents';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardBlockEvent = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    topicTitle,
    topicDescription,
    featuredEvent,
    insightitems = [],
    eventCarousalTitle = {},
    tagsToShow = [],
  } = fields;
  const cssClass = '';
  return (
    <section
      className={`card-block section-event ${cssClass}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row section-header">
          <div className="col-lg-10">
            {topicTitle && (
              <h2 className="title xs-mobile-0">
                <TextSitecoreField field={topicTitle} />
              </h2>
            )}
            {topicDescription && (
              <p className="sub-title m-0 color-lightgray">
                <TextSitecoreField field={topicDescription} />
              </p>
            )}
          </div>
        </div>
        <div className="row card-wrapper xs-pad-lr-20">
          {featuredEvent && featuredEvent?.length > 0 ? (
            <CardInsightsEvent
              featured={featuredEvent}
              tags={tagsToShow}
              fields={fields}
              componentName={componentName}
            />
          ) : (
            <CardInsightsEvent solutionsTags={fields} componentName={componentName} />
          )}
        </div>
      </div>
      {insightitems && insightitems?.length > 0 && (
        <div className="carousel-wrapper">
          <CarouselEvents
            items={insightitems}
            eventCarousalTitle={eventCarousalTitle}
            componentName={componentName}
            fields={fields}
          />
        </div>
      )}
    </section>
  );
};

CardBlockEvent.defaultProps = {
  fields: {
    topicTitle: '',
    topicDescription: '',
    featuredEvent: [],
    insightitems: [],
    eventCarousalTitle: {},
    tagsToShow: [],
  },
  cssClass: '',
};

CardBlockEvent.propTypes = {
  fields: PropTypes.shape({}),
  cssClass: PropTypes.string,
};

export default CardBlockEvent;
