import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import Button from '../../../globals/buttons/Button';
import DataBlockFeaturetteChart from '../DataBlock/DataBlockFeaturetteChart';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { wrapChartData } from '../../../utils/HelperUtils';

import './dataBlockFeaturette.scss';
import { ComponentDataAttributes } from '../../../constants/gtm';
import ButtonHref from '../../../globals/buttons/ButtonHref';

const DataBlockFeaturette = (props) => {
  const {
    fields,
    textAlign,
    backgroundColor = 'secondary',
    cssClass,
    rendering: { componentName = '' },
  } = props;
  const { ctaLink } = fields;
  const _BarChartAlt = wrapChartData(fields);
  const isTiny = useMediaQuery({ maxWidth: breakpoints.xxs - 1 });
  const isMobile = useMediaQuery({
    minWidth: breakpoints.xxs,
    maxWidth: breakpoints.xs - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: breakpoints.xs,
    maxWidth: breakpoints.xl,
  });

  const getSize = () => {
    if (isTiny) {
      return 'Tiny';
    } else if (isMobile) {
      return 'XXS';
    } else if (isTablet) {
      return 'S';
    }
    return 'M';
  };
  return (
    <section
      className={`featurette-section standard ${fields?.textAlign?.fields?.text?.value} ${cssClass} ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className={`row content-wrapper ${fields?.textAlign?.fields?.text?.value}`}>
          <div
            className={`col-lg-5 FeaturetteChart-col-lg-5 ${fields?.textAlign?.fields?.text?.value}`}
          >
            <div className="featurette-body" style={{ backgroundColor: 'black' }}>
              {fields?.subTitle && (
                <div className="sub-title">
                  <TextSitecoreField field={fields?.subTitle} />
                </div>
              )}
              {fields?.title && (
                <h2 className="title">
                  <TextSitecoreField field={fields?.title} />
                </h2>
              )}
              <div className="richtext-wrapper">
                <RichTextSitecoreField field={fields?.description} />
              </div>
              {fields?.ctaText?.value && (
                <div className="cta-wrapper">
                  <ButtonHref
                    text={<TextSitecoreField field={fields?.ctaText} />}
                    cssClass={`${backgroundColor === 'secondary' ? 'white' : ''}`}
                    arrow={true}
                    variant="text-button"
                    href={ctaLink?.value?.href}
                    linkType={ctaLink?.value?.linktype}
                    componentName={componentName}
                    linkFields={ctaLink}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={`col-lg-7 FeaturetteChart-col-lg-7 ${fields?.textAlign?.fields?.text?.value}`}
          >
            <div className="featurette-data">
              {_BarChartAlt && (
                <DataBlockFeaturetteChart
                  title={_BarChartAlt && _BarChartAlt?.title && _BarChartAlt?.title}
                  descLabel={_BarChartAlt && _BarChartAlt?.descLabel && _BarChartAlt?.descLabel}
                  description={
                    _BarChartAlt && _BarChartAlt?.description && _BarChartAlt?.description
                  }
                  size={getSize()}
                  color={_BarChartAlt && _BarChartAlt?.color}
                  backgroundColor={backgroundColor}
                  chartType={_BarChartAlt && _BarChartAlt?.chartType}
                  data={_BarChartAlt && _BarChartAlt?.data}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

DataBlockFeaturette.defaultProps = {
  title: '',
  subTitle: '',
  description: '',
  ctaText: '',
  ctaLink: '',
  textAlign: 'left',
  cssClass: '',
  backgroundColor: 'secondary',
  data: {
    title: '',
    descLabel: '',
    description: '',
    chartType: '',
    data: {},
  },
};

DataBlockFeaturette.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes,
  ctaLink: PropTypes,
  /* Types ['bar', 'bar-alt', 'bubble', 'doughnut', 'line', 'linear-gauge'] */
  data: PropTypes.object,
};

export default DataBlockFeaturette;
