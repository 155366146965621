import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Checkbox from '../../../globals/forms/CheckboxFn';
import { useForm } from 'react-hook-form';
import TextBox from './TextBoxContact';
import TextAreaBox from './TextAreaContact';
import SelectContact from './SelectContact';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ButtonDataAttributes, ComponentDataAttributes } from '../../../constants/gtm';
import OverlayLoader from '../ErrorBoundary/OverlayLoader';
import CheckboxForm from '../../core/ContactUs_form/Checkbox';
import {
  currentLanguage,
  getBrowserWindow,
  isSCExperienceEditorActive,
} from '../../../utils/HelperUtils';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import './GlobalContact.scss';
import { isEmpty } from 'lodash';
const GlobalContactUsForm = (props) => {
  const [cbCheck, setCbCheck] = useState(false);
  const [cbError, setCbError] = useState('');

  const [showMsg, setMsg] = useState(false);
  const [showErrMsg, setErrMsg] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [formCheck, setFormCheck] = useState(false);
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { firstColumnFormFields, secondColumnFormFields, hubspotFormId, hubspotPortalId } = fields;
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors, submitCount },
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });

  const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/HubSpot/FormSubmit`;
  const countryData = useSelector((state) => state.filterReducer?.countryData);

  const patternRegexCheck = (pattern) => {
    let regex = '';
    if (pattern && pattern.match) {
      const match = pattern.match(new RegExp('^/(.*?)/([gimy]*)$'));
      regex = match ? new RegExp(match[1], match[2]) : new RegExp(pattern);
    }
    return regex;
  };

  const validateCb = () => {
    if (fields?.consentText?.value !== '') {
      if (cbCheck === false && !fields?.isConsentRequired?.value) {
        return true;
      }
      if (cbCheck === false && fields?.isConsentRequired?.value) {
        if (!isSCExperienceEditorActive) {
          if (getBrowserWindow) {
            window?.dataLayer?.push({
              event: 'form_error',
              form_id: hubspotFormId?.value || '',
              form_name: componentName || 'ContactForm',
              form_error: `${fields?.consentRequiredMessage?.value}`,
            });
          }
        }
        setCbError(`${fields?.consentRequiredMessage?.value}`);
        return false;
      }
      if (cbCheck === true) {
        setCbError('');
        return true;
      }
    }

    return true;
  };
  useEffect(() => {
    if (submitCount > 0) {
      let form_error =
        `${errors?.firstName?.message ? errors.firstName.message + ' | ' : ''}` +
        `${errors?.lastName?.message ? errors.lastName.message + ' | ' : ''}` +
        `${errors?.company?.message ? errors.company.message + ' | ' : ''}` +
        `${errors?.email?.message ? errors.email.message + ' | ' : ''}` +
        `${errors?.message?.message ? errors.message.message : ''}`;
      // For GTM error

      if (errors && !isEmpty(errors) && !isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_error',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'ContactForm',
            form_error: form_error.trim().replace(/\|$/, ''),
          });
        }
      }
    }
    if (sessionStorage) {
      setTimeout(() => {
        let utmValues = sessionStorage.getItem('utmReferrer');
        utmValues = !isEmpty(utmValues) ? JSON.parse(utmValues) : {};
        Object.entries(utmValues).map(([key, value]) =>
          setValue(key.toLowerCase(), value, { shouldValidate: true })
        );
      }, 2000);
    }
  }, [submitCount]);
  async function apiPost(postdata) {
    if (validateCb()) {
      setLoader(true);
      try {
        const data = {
          FormId: hubspotFormId?.value,
          PortalID: hubspotPortalId?.value,
          language: currentLanguage() || 'en',
          formData: `{"fields" : ${JSON.stringify(postdata)}}`,
        };
        const apiCall = await axios.post(url, data);
        const apiStatus = await JSON.parse(apiCall?.data?.statusCode);
        if (apiStatus === 200) {
          if (!isSCExperienceEditorActive) {
            if (getBrowserWindow) {
              window?.dataLayer?.push({
                event: 'form_submit',
                form_id: hubspotFormId?.value || '',
                form_name: componentName || 'ContactForm',
              });
            }
          }
          setMsg(true);
          setCbCheck(false);
          setLoader(false);
          setTimeout(() => {
            setMsg(false);
            reset();
          }, 10000);
        } else {
          if (!isSCExperienceEditorActive) {
            if (getBrowserWindow) {
              window?.dataLayer?.push({
                event: 'form_error',
                form_id: hubspotFormId?.value || '',
                form_name: componentName || 'ContactForm',
                form_error: `${fields?.errorMessage?.value}`,
              });
            }
          }
          setLoader(false);
          setErrMsg(true);
          setTimeout(() => {
            setErrMsg(false);
            false;
          }, 4000);
        }
      } catch (error) {}
    }
  }

  const onSubmit = (data) => {
    let newFormatdata = [];
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newFormatdata.push({ name: `${key}`, value: `${data[key]}` });
        }
      }
    }
    if (fields?.consentMappingField?.value !== '') {
      newFormatdata.push({ name: `${fields?.consentMappingField?.value}`, value: `${cbCheck}` });
    }
    newFormatdata.push({ name: 'talent_solutions_contact_options', value: 'test contact options' }); // This line is added for the hubspot form field made as mandatory from customer side but it is not using in site core, that's why we added manually to remove unnesesary issue.
    newFormatdata ? apiPost(newFormatdata) : '';
  };
  const handleInputChange = (event) => {
    setValue(event.target.name, event.target.value, { shouldValidate: true });
    clearErrors(event.target.name);
    if (!formChanged) {
      setFormChanged(true);
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_start',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'ContactForm',
          });
        }
      }
    }
  };

  return (
    (!isEmpty(countryData) || countryData?.countryClick === true) && (
      <section
        className="global-contactus contact contact-section"
        id="contact-section"
        {...ComponentDataAttributes(componentName)}
      >
        {!showMsg ? (
          <div className="container-xl contact-container">
            <div className="col-lg-10  col-xl-7 col-md-12">
              <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="contact-column-12">
                    <div className="section-contact-form">
                      <div className="section-1">
                        {firstColumnFormFields &&
                          firstColumnFormFields?.map((item) => {
                            let label = item?.fields?.Label?.value;
                            let name = item?.fields?.hubspotFieldMapping?.value;
                            let isRequired = item?.fields?.isRequiredField?.value;
                            switch (item?.fields?.fieldType?.fields?.text?.value) {
                              case 'text':
                                return (
                                  <TextBox
                                    Label={label}
                                    type="text"
                                    name={name}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                        maxLength: {
                                          value: item?.fields?.maxLength?.value || null,
                                          message: 'Exceeded the limits',
                                        },
                                        pattern: {
                                          value: item?.fields?.validationRegex?.fields?.text?.value
                                            ? patternRegexCheck(
                                                item?.fields?.validationRegex?.fields?.text?.value
                                              )
                                            : '',
                                          message: `${item?.fields?.validationErrorMessage?.value}`,
                                        },
                                      }),
                                    }}
                                    placeholder={item?.fields?.placeholderText?.value}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                    handleInputChange={handleInputChange}
                                  />
                                );
                              case 'checkbox':
                                return (
                                  <CheckboxForm
                                    id={`contactus-check_${name}`}
                                    className={
                                      'contact-checkbox-label ' +
                                      fields?.consentCheckboxPosition?.fields?.text?.value
                                    }
                                    Label={label}
                                    checked={formCheck}
                                    onClick={(e) => {
                                      setFormCheck(e.target.checked);
                                    }}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                      }),
                                    }}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                  />
                                );

                              case 'richtext':
                                return (
                                  <TextAreaBox
                                    Label={label}
                                    type="text"
                                    name={name}
                                    placeholder={item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                        maxLength: {
                                          value: item?.fields?.maxLength?.value || null,
                                          message: 'Exceeded the limits',
                                        },
                                        pattern: {
                                          value: item?.fields?.validationRegex?.fields?.text?.value
                                            ? patternRegexCheck(
                                                item?.fields?.validationRegex?.fields?.text?.value
                                              )
                                            : '',
                                          message: `${item?.fields?.validationErrorMessage?.value}`,
                                        },
                                      }),
                                    }}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                    handleInputChange={handleInputChange}
                                  />
                                );
                              case 'dropdown':
                                return (
                                  <SelectContact
                                    labelText={label}
                                    title={label}
                                    cssClass="label"
                                    options={item?.fields?.dropdownOptionList}
                                    placeholder={item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                      }),
                                    }}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                  />
                                );
                              case 'hidden':
                                return (
                                  <TextBox
                                    type="hidden"
                                    name={name + item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        value: item?.fields?.placeholderText?.value,
                                      }),
                                    }}
                                  />
                                );
                              default:
                                return '';
                            }
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="contact-column-12 second-column-12">
                    <div className="section-contact-form">
                      <div className="section-2">
                        {secondColumnFormFields &&
                          secondColumnFormFields?.map((item) => {
                            let label = item?.fields?.Label?.value;
                            let name = item?.fields?.hubspotFieldMapping?.value;
                            let isRequired = item?.fields?.isRequiredField?.value;

                            switch (item?.fields?.fieldType?.fields?.text?.value) {
                              case 'text':
                                return (
                                  <TextBox
                                    Label={label}
                                    type="text"
                                    name={name}
                                    placeholder={item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                        maxLength: {
                                          value: item?.fields?.maxLength?.value || null,
                                          message: 'Exceeded the limits',
                                        },
                                        pattern: {
                                          value: item?.fields?.validationRegex?.fields?.text?.value
                                            ? patternRegexCheck(
                                                item?.fields?.validationRegex?.fields?.text?.value
                                              )
                                            : '',
                                          message: `${item?.fields?.validationErrorMessage?.value}`,
                                        },
                                      }),
                                    }}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                    handleInputChange={handleInputChange}
                                  />
                                );
                              case 'richtext':
                                return (
                                  <TextAreaBox
                                    Label={label}
                                    type="text"
                                    name={name}
                                    placeholder={item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                        maxLength: {
                                          value: item?.fields?.maxLength?.value || null,
                                          message: 'Exceeded the limits',
                                        },
                                        pattern: {
                                          value: item?.fields?.validationRegex?.fields?.text?.value
                                            ? patternRegexCheck(
                                                item?.fields?.validationRegex?.fields?.text?.value
                                              )
                                            : '',
                                          message: `${item?.fields?.validationErrorMessage?.value}`,
                                        },
                                      }),
                                    }}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                    handleInputChange={handleInputChange}
                                  />
                                );
                              case 'dropdown':
                                return (
                                  <SelectContact
                                    labelText={label}
                                    title={label}
                                    cssClass="label"
                                    options={item?.fields?.dropdownOptionList}
                                    placeholder={item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        required: isRequired
                                          ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                          : '',
                                      }),
                                    }}
                                    isRequiredField={isRequired}
                                    errors={errors[`${name}`]}
                                  />
                                );
                              case 'hidden':
                                return (
                                  <TextBox
                                    type="hidden"
                                    name={name + item?.fields?.placeholderText?.value}
                                    reg={{
                                      ...register(`${name}`, {
                                        value: item?.fields?.placeholderText?.value,
                                      }),
                                    }}
                                  />
                                );
                              default:
                                return '';
                            }
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                {showLoader && <OverlayLoader />}
                <div className="form-footer-wraper">
                  {fields?.consentText?.value !== '' && (
                    <div className="contact-form-footer form-footer">
                      <Checkbox
                        id="contactus-check"
                        className={
                          'contact-checkbox-label ' +
                          fields?.consentCheckboxPosition?.fields?.text?.value
                        }
                        // text={fields?.consentText?.value}
                        text={<RichTextSitecoreField field={fields?.consentText} />}
                        onClick={(e) => {
                          setCbCheck(e.target.checked);
                        }}
                      />
                      {cbError && <span className="error-message">{cbError}</span>}
                    </div>
                  )}

                  {showErrMsg && (
                    <div className="form-submit-error-msg hs-main-font-element">
                      <RichTextSitecoreField field={fields?.errorMessage} />
                    </div>
                  )}
                  <button
                    className="button primary-button violet medium icon__right signpost contact-submit"
                    type="submit"
                    {...ButtonDataAttributes({
                      name: componentName,
                      text: `${fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}`,
                      variant: 'primary-button',
                      arrow: true,
                      dataItemName: 'contactusForm',
                    })}
                  >
                    {fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}{' '}
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="submitted-message hs-main-font-element">
            <RichTextSitecoreField field={fields?.successMessage} />
          </div>
        )}
      </section>
    )
  );
};

export default GlobalContactUsForm;
GlobalContactUsForm.defaultProps = {
  fields: {},
};
GlobalContactUsForm.propTypes = {
  fields: PropTypes.shape({}),
};
