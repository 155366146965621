import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextHeader from '../TextBlocks/TextHeader';
import RichTextArea from '../TextBlocks/RichTextArea';
import './textblock.scss';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { getBrowserWindow, isBrowserDocumentAvailable } from '../../../utils/HelperUtils';

export const UtilityTextBlock = ({ backgroundColor, cssClass, title, subTitle, richText }) => (
  <section className={`rich-text-block ${backgroundColor}`}>
    <div className="container-xl">
      <div className="row">
        {title && (
          <div className="col-lg-12">
            <TextHeader cssClass={cssClass} title={title} subTitle={subTitle} />
          </div>
        )}
        <div className="content col-lg-12 utility-typography">
          <RichTextArea richText={richText} />
        </div>
      </div>
    </div>
  </section>
);

UtilityTextBlock.defaultProps = {
  backgroundColor: 'light',
  title: '',
  subTitle: '',
  richText: '',
  cssClass: 'standard',
};

UtilityTextBlock.propTypes = {
  backgroundColor: PropTypes.oneOf(['light', 'gray']),
  cssClass: PropTypes.oneOf(['standard', 'small']),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  richText: PropTypes.string,
};

const Utility = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  useEffect(() => {
    if (getBrowserWindow && isBrowserDocumentAvailable) {
      const ot_policy_list = document.getElementById('ot-sdk-cookie-policy');
      if (window?.Optanon && ot_policy_list) {
        window?.Optanon?.initializeCookiePolicyHtml();
      }
    }
  }, [fields?.Content]);

  return (
    <div className="utility-block" {...ComponentDataAttributes(componentName)}>
      <UtilityTextBlock richText={fields?.Content} />
    </div>
  );
};
export default Utility;
