import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchField from '../SearchField/SearchField';
import SearchResultFilter from '../SearchResultFilter/SearchResultFilter';
import SearchResultCard from '../SearchResultCard/SearchResultCard';
import Pagination from '../Navigation/Pagination/Pagination';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import Button from '../../../globals/buttons/Button';
import IconList from '../../../../src/static/icons/icon--list.svg';
import OverlayLoader from '../ErrorBoundary/OverlayLoader';

import {
  sitecoreHostUrl,
  isSCExperienceEditorActive,
  isBrowserDocumentAvailable,
  getBrowserWindow,
} from '../../../utils/HelperUtils';
import { fetchSearchResult } from '../../../service';
import './searchResults.scss';

const sortOptions = [
  { label: 'Relevance', value: '' },
  { label: 'Date', value: 'date' },
  { label: 'Popularity', value: 'popularity' },
];

export default function SearchResults(props) {
  const { sortOptions, fields = {} } = props;
  const { filters = [] } = fields;
  const [currentPage, setCurrentPage] = useState(1);
  const [screenWidth, setScreenWidth] = useState(0);
  const [totalResult, settotalResult] = useState(0);
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [sortRelevance, setSortRelevance] = useState('false');
  const [sortPopular, setSortPopular] = useState('false');
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) ?? 1;
  const searchQuery = searchParams.get('search');
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0].value);
  const [results, setResult] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(searchQuery);
  const selectedsearchfilts = useSelector((state) => state.searchfilterReducer.selectedsearchfilts);
  const [showLoader, setLoader] = useState(false);
  const [filterToggle, setfilterToggle] = useState(false);

  const searchedsearchresultdata = useSelector(
    (state) => state.searchfilterReducer.searchedsearchresultdata
  );
  const navigate = (page) => {
    if (location?.pathname?.includes('site-search')) {
      navigation({
        pathname: location?.pathname,
        search:
          searchKeyword !== null
            ? createSearchParams({ page: page, search: searchKeyword }).toString()
            : createSearchParams({ page: page }).toString(),
      });
    }
  };
  useEffect(() => {
    searchQuery && setSearchKeyword(searchQuery);
  }, [searchQuery]);

  const resultsPerPage = 10;
  const totalResults = totalResult;
  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const filterBtnRef = useRef(null);
  const location = useLocation();
  let finalSearchRes = [];

  const handleAPICall = async (index) => {
    const filtsList = [...new Set(selectedsearchfilts.map((item) => item.key))].filter(
      (item) => item !== undefined
    );
    const InsightsFilters = filtsList
      .map(
        (item) =>
          `${item?.toLowerCase().replace(/\s/g, '')}=${selectedsearchfilts
            .filter((curr) => curr.key === item)
            .map((currItem) =>
              currItem.key === 'Regions' || currItem.key === 'Types'
                ? currItem?.filt
                : currItem?.filt?.flat()
            )
            .join(',')}`
      )
      .join('|');

    setLoader(true);
    const result = await fetchSearchResult(
      InsightsFilters,
      limit,
      page - 1,
      'en',
      sortPopular,
      sortRelevance,
      searchQuery
    );
    const searchResultApi =
      result?.data?.searchResult.length > 0
        ? result?.data?.searchResult
        : result?.data?.insightsSearchResult !== null
        ? result?.data?.insightsSearchResult
        : [];
    setResult(searchResultApi);
    settotalResult(result?.data?.totalCount);
    setCurrentPage(index);
    setLoader(false);
  };

  const sendDataToParent = (currPage) => {
    setOffset(currPage);
  };

  useEffect(() => {
    if (location.state && location.state.pushEventAndNavigate) {
      location.state.pushEventAndNavigate();
    }
  }, [location.state]);

  useEffect(() => {
    handleAPICall();
  }, [selectedsearchfilts, sortPopular, sortRelevance, offset, searchQuery, page]);

  useEffect(() => {
    if (selectedSortOption === 'popularity') {
      setSortPopular('true');
      setSortRelevance('false');
    } else if (selectedSortOption === 'relevance') {
      setSortPopular('false');
      setSortRelevance('true');
    } else {
      setSortPopular('false');
      setSortRelevance('false');
    }
  }, [selectedSortOption]);

  useEffect(() => {
    let isMounted = true;
    setScreenWidth(window.innerWidth);
    function handleResize() {
      setTimeout(() => {
        if (isMounted) {
          setScreenWidth(window.innerWidth);
        }
        // Remove show class from filter dropdown on desktop
        if (!isSCExperienceEditorActive) {
          if (getBrowserWindow) {
            if (window.innerWidth >= breakpoints.md) {
              const filterDropdown = document.querySelector('.search_header__bottom');
              // filterDropdown.classList.remove('show');
            }
          }
        }
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  // Screen width function
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });

  const handleSortOptionChange = (event) => {
    setSelectedSortOption(event.target.value);
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'insight_sort',
          sort_order: event.target.value,
        });
      }
    }
  };

  // Function to render search results
  const renderResults = (results) => {
    if (results.length === 0) {
      return <p>No results found for {`"${searchQuery}"`}</p>;
    }

    // const startIndex = (currentPage - 1) * resultsPerPage;
    // const endIndex = startIndex + resultsPerPage;
    // const visibleResults = sortedResults.slice(startIndex, endIndex);

    return (
      results.length > 0 &&
      results.map((result, index) => (
        <>
          <SearchResultCard
            key={index}
            id={index}
            title={result?.title}
            description={result?.description ? result?.description : result?.content}
            date={result?.publicationDate ? result?.publicationDate : result?.date}
            relevance={result?.relevance}
            popularity={result?.popularity}
            type={result?.contentType}
            item={result}
            fields={fields}
            cardCTA={result?.url}
          />
        </>
      ))
    );
  };

  const firstIndex = (currentPage - 1) * resultsPerPage + 1;
  const lastIndex = Math.min(firstIndex + resultsPerPage - 1, results?.length);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    page ? setCurrentPage(parseInt(page)) : setCurrentPage(1);
    if (page === parseInt(1)) handlePageChange(1);
  }, [page, currentPage]);

  const setPageParams = (page) => {
    navigate(page);
  };

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    const pageNo = selectedPage ?? 1;
    if (pageNo !== currentPage || pageNo === 1) {
      setPageParams(pageNo);
    }
  };
  return (
    <div className="search_page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="search_header">
              <div className="search_header__showing_results">
                {results.length > 0 ? (
                  // <p>
                  //   Showing{' '}
                  //    <span className="bold">
                  //      {firstIndex}-{firstIndex + lastIndex} of {totalResult} results for:
                  //     {` ${limit} `}
                  //     of {totalResult} results for:
                  //   </span> *
                  //   <span className="bold">
                  //     {totalResult} results for:
                  //   </span>
                  //   <p>{results?.length} results found for:</p>
                  // </p>
                  <p>{totalResult} results found for:</p>
                ) : (
                  <p>{totalResult} results found for:</p>
                )}
              </div>

              <div className="search_header__top">
                <div className="row">
                  <div
                    className={[
                      'col-12',
                      'col-lg-9',
                      'search_header__top--left',
                      results ? 'results_shown' : 'no_results',
                    ].join(' ')}
                  >
                    <SearchField getQuery={searchKeyword} handleAPICall={handleAPICall} />
                  </div>

                  <div
                    className={[
                      'col-12',
                      'col-lg-3',
                      'search_header__top--right',
                      isMobileScreen ? 'mobile' : 'desktop',
                      results.length > 0 ? 'results_shown' : 'results_shown',
                    ].join(' ')}
                  >
                    {/* Search Filter Button for mobile */}
                    {isMobileScreen && (
                      <>
                        <div ref={filterBtnRef} className="search_header__top--filterbtn">
                          {results.length > 0 && (
                            <Button
                              BtnFunction={() => {
                                setfilterToggle(!filterToggle);
                              }}
                              cssClass="filter_btn"
                              icon={IconList}
                              text="Filter"
                              fontSize="small"
                              iconPosition="icon__left"
                              variant="secondary-button outline"
                            />
                          )}
                        </div>
                        <div className="col search_sort">
                          <div className="search_dropdown sort_order">
                            <label htmlFor="sort">Sort By</label>
                            <select
                              id="sort"
                              value={selectedSortOption}
                              onChange={handleSortOptionChange}
                            >
                              {sortOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                    {/* <div className="search_dropdown sort_order">
                      {results.length > 0 && (
                        <>
                          <label htmlFor="sort">Sort by</label>
                          <select
                            id="sort"
                            value={selectedSortOption}
                            onChange={handleSortOptionChange}
                          >
                            {sortOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="site-search-filter-content">
                <SearchResultFilter
                  // title="Explore our insights"
                  selectedSortOption={selectedSortOption}
                  totalItems={0}
                  filters={filters}
                  sortRelevance={sortRelevance}
                  sortPopular={sortPopular}
                  searchKeyword={searchKeyword}
                  component="siteSearch"
                  filterToggle={filterToggle}
                />
              </div>
            </div>
          </div>
        </div>
        {showLoader && <OverlayLoader />}
        <div className="row">
          <div className="col-lg-12">
            <div className="search_results results__container">
              <div className="row">
                <div className="col-lg-12">{renderResults(results)}</div>
              </div>
              <div className="row">
                <div className="col-lg-12 pagination_section">
                  {totalPages > 1 && (
                    <Pagination
                      itemsPerPage={limit}
                      totalItems={totalResults}
                      currentPage={currentPage}
                      onPageChanged={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchResults.defaultProps = {
  sortOptions: sortOptions,
  feilds: {
    filters: [],
  },
  results: [],
  selectedSortOption: 'relevance', // default sort option
};
SearchResults.propTypes = {
  sortOptions: PropTypes.shape({}),
  feilds: PropTypes.shape({
    filters: PropTypes.array,
  }),
  results: PropTypes.array,
  selectedSortOption: PropTypes.string,
};
