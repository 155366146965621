import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonHref from './../../../globals/buttons/ButtonHref';
import CloseIcon from './../../../static/images/icon_close--white.svg';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { ComponentDataAttributes } from '../../../constants/gtm';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';

const CalloutBarAlert = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    Content,
    backgroundColor,
    ctaIcon,
    ctaIconPosition,
    ctaLink,
    ctaText,
    mode,
    optCtaIcon,
    optCtaIconPosition,
    optCtaLink,
    optCtaText,
    showCTAIcon,
    optShowCTAIcon,
  } = fields;

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <section
      className={`calloutbar-section alert alert__${
        mode?.fields?.text?.value
      } ${getFooterCallOutBarStylesFromBackgroundColor(backgroundColor?.fields?.text?.value)} ${
        show ? 'open' : 'closed'
      } `}
      {...ComponentDataAttributes(componentName)}
    >
      {mode?.fields?.text?.value === 'initial' && (
        <button className="close-button" onClick={handleClose}>
          <img src={mediaApi.updateImageUrl(CloseIcon)} alt="close icon" />
        </button>
      )}
      <div>
        <div className="row content-wrapper">
          <div className="col rich-text">
            <RichTextSitecoreField className="rich-text-alert" field={Content} />
          </div>
          <div className="col cta__wrap">
            {ctaText?.value && (
              <ButtonHref
                variant="primary-button"
                text={ctaText?.value}
                href={ctaLink?.value?.href}
                arrow={showCTAIcon && showCTAIcon?.value}
                cssClass={`${
                  getFooterCallOutBarStylesFromBackgroundColor(
                    backgroundColor?.fields?.text?.value
                  ) === 'secondary'
                    ? 'white'
                    : 'black'
                }`}
                linkFields={ctaLink}
                linktype={ctaLink?.value?.linktype}
                componentName={componentName}
                icon={ctaIcon && showCTAIcon && !showCTAIcon?.value && ctaIcon?.value?.src}
                iconPosition={
                  optCtaIconPosition ? `icon__${optCtaIconPosition?.fields?.text?.value}` : ''
                }
              />
            )}

            {optCtaText?.value && (
              <ButtonHref
                variant="text-button"
                text={optCtaText?.value}
                href={optCtaLink?.value?.href}
                arrow={optShowCTAIcon && optShowCTAIcon?.value}
                cssClass={`${
                  getFooterCallOutBarStylesFromBackgroundColor(
                    backgroundColor?.fields?.text?.value
                  ) === 'secondary' ||
                  getFooterCallOutBarStylesFromBackgroundColor(
                    backgroundColor?.fields?.text?.value
                  ) === 'violet'
                    ? 'white'
                    : 'black'
                }`}
                linkFields={optCtaLink}
                linktype={optCtaLink?.value?.linktype}
                componentName={componentName}
                icon={
                  optCtaIcon && optShowCTAIcon && !optShowCTAIcon?.value && optCtaIcon?.value?.src
                }
                iconPosition={
                  ctaIconPosition ? `icon__${optCtaIconPosition?.fields?.text?.value}` : ''
                }
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

CalloutBarAlert.defaultProps = {
  richText: '',
  backgroundColor: '',
  mode: 'initial',
};

CalloutBarAlert.propTypes = {
  richText: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary', 'violet', 'green', 'blue']),
  mode: PropTypes.oneOf(['initial', 'ongoing']),
  fields: PropTypes.shape({}),
};

export default CalloutBarAlert;
