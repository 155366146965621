import React from 'react';
import PropTypes from 'prop-types';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';

const SingleBiography = ({ bodyContent }) => (
  <div className="single-bio">
    <RichTextSitecoreField field={bodyContent} />
  </div>
);

SingleBiography.defaultProps = {
  richTextArea: '',
};

SingleBiography.propTypes = {
  richTextArea: PropTypes.string,
};

export default SingleBiography;
