import React from 'react';
import SiteFooterNav from './SiteFooterNav';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ComponentDataAttributes } from '../../../constants/gtm';

const SiteFooter = (props) => {
  return (
    <footer className="site-footer" {...ComponentDataAttributes('SiteFooter')}>
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-8">
            <SiteFooterNav props={props} />
          </div>
          <div className="col-lg-4 newsletter-noleft">
            <div className="footer-rightside newsletter-noleft">
              <Placeholder name="jss-footer-right" rendering={props.rendering} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
