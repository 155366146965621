import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../../sitecorefields/ImageSitecoreField';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';

const CountryCards = ({ img, title, ctaUrl }) => (
  <a href={ctaUrl} className="country-profile-a">
    <div className={'card country-profile'}>
      <div className="card-body">
        <div>
          <ImageSitecoreField className="headshot" field={img} />
        </div>
        <div className="countryName">
          <TextSitecoreField field={title} />
        </div>
      </div>
    </div>
  </a>
);

CountryCards.defaultProps = {
  img: 'https://placehold.co/300',
  alText: 'Headshot of employee',
  title: 'First',
  ctaUrl: '',
};

CountryCards.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  altText: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
};

export default CountryCards;
