import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import CountryCard from './CountryCards';

const CountryNameCards = (props) => {
  const { fields } = props;
  const { countryList } = fields;
  return (
    <section className={'card-block-country-profile'}>
      {countryList &&
        countryList.map((item, index) => (
          <CountryCard
            key={`${item}-${index}`}
            img={item?.fields?.countryImage}
            title={item?.fields?.countryName}
            ctaUrl={item?.fields?.countryLink?.value?.href}
          />
        ))}
    </section>
  );
};

CountryNameCards.defaultProps = {
  fields: {
    teamProfilesList: [
      {
        img: 'https://placehold.co/300',
        alText: 'Headshot of employee',
        title: 'First name Last name',
        text: 'Job Title & Company',
        ctaText: 'View bio',
        ctaUrl: '',
        cssClass: '',
      },
    ],
    title: '',
  },
};

CountryNameCards.propTypes = {
  fields: PropTypes.shape({
    teamProfilesList: PropTypes.arrayOf(PropTypes.shape()),
    title: PropTypes.string,
  }),
};

export default CountryNameCards;
