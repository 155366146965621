import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../globals/buttons/Tag.jsx';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags } from '../../../utils/HelperUtils';
import ResponsivePicture from '../../../globals/icons/ResponsivePicture.jsx';
import ButtonHref from '../../../globals/buttons/ButtonHref.jsx';
import rectangle963 from '../../../static/images/rectangle963.png';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField.jsx';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField.jsx';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const CardInsightsForCallout = (props) => {
  const { item, tagsToShow, ctaText, t, componentName, isLatestPillsColor } = props;
  const { fields = {}, url } = item;
  const { image, Title, subTitle, Content } = fields;
  const latest = t('is-latest');

  return (
    <a
      href={url}
      className={`card card__insights card__featured card__${'width'} ${
        'isFeatured' ? 'featured' : ''
      } ${'cssClass'} ${'imgAlign'}`}
    >
      <div className="img-wrapper">
        {image ? (
          <div className="button tag solid violet medium buttonlatest">{latest}</div>
        ) : (
          <div className="button tag solid white medium buttonlatest">{latest}</div>
        )}
        {image ? (
          <ResponsivePicture imageSmall={image} imageMedium={image} imageLarge={image} />
        ) : (
          <img src={rectangle963} alt={'default images' || ''} />
        )}
      </div>

      <div className="card-body featurette">
        <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
        <div className="card-content insight">
          {subTitle.value && (
            <div className="sub-title">
              <TextSitecoreField className="sub-title" field={subTitle}></TextSitecoreField>
            </div>
          )}
          <h3 className="title">
            <TextSitecoreField className="title" field={Title}></TextSitecoreField>
          </h3>
          <div className="clamp-2">
            <RichTextSitecoreField field={Content}></RichTextSitecoreField>
          </div>
        </div>
        <div className="related-solutions">
          {tagsToShow &&
            tagsToShow?.map((tag, index) => (
              <Tag
                key={`${index}-${tag.text}`}
                cssClass={tag.cssClass}
                fontSize="small"
                icon={tag.icon}
                text={tag.text}
                isLatestPillsColor={isLatestPillsColor?.fields?.text}
              />
            ))}
        </div>
        <div className="cta__wrap">
          {ctaText?.value && (
            <a href={props?.props?.url}>
              <ButtonHref
                variant="text-button"
                text={ctaText}
                arrow={true}
                componentName={componentName}
                dataItemName={Title.value}
              />
            </a>
          )}
        </div>
      </div>
    </a>
  );
};

CardInsightsForCallout.defaultProps = {
  /** Featured image */
  imgMobile: '',
  imgDesktop: '',
  altText: '',
  /** Alignment of featured image (left or right) */
  imgAlign: 'left',
  /** Readtime of runtime tag */
  length: '',
  /** Article tags (i.e. webinar, article, or movie etc.) */
  // articleTags: [],
  contentType: '',
  date: '',
  /** Optional */
  subTitle: '',
  /** Card title (heading 3) */
  title: '',
  /** Card content */
  text: '',
  ctaUrl: '',
  ctaText: '',
  ctaMode: 'arrow',
  cssClass: '',
  isFeatured: false,
  /** Local tag */
  localized: false,
  /** Solutions tags (3 max) */
  solutionsTags: [],
  width: 'lg',
  fields: {},
};

CardInsightsForCallout.propTypes = {
  /** Featured image */
  imgMobile: PropTypes.string,
  imgDesktop: PropTypes.string,
  altText: PropTypes.string,
  /** Alignment of featured image (left or right) */
  imgAlign: PropTypes.string,
  /** Readtime of runtime tag */
  length: PropTypes.string,
  /** Content tag (i.e. webinar, article, or movie etc.) */
  contentTags: PropTypes.array,
  contentType: PropTypes.string,
  date: PropTypes.string,
  /** Optional */
  subTitle: PropTypes.string,
  /** Card title (heading 3) */
  title: PropTypes.string,
  /** Card content */
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  isFeatured: PropTypes.bool,
  /** Local tag */
  localized: PropTypes.bool,
  /** Solutions tags (3 max) */
  solutionsTags: PropTypes.array,
  width: PropTypes.oneOf(['sm', 'md', 'lg']),
  fields: PropTypes.shape({}),
};

export default withTranslation()(withSitecoreContext()(CardInsightsForCallout));
