import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardForFooterAction = ({
  title,
  ctaText,
  backgroundColor,
  href,
  linkType,
  linkFields,
  componentName = '',
}) => {
  return (
    <div
      className={`card footer-action ${getFooterCallOutBarStylesFromBackgroundColor(
        backgroundColor
      )}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="card-body footer-gap">
        <h2 className="title" style={{ margin: 0 }}>
          <TextSitecoreField field={title} />
        </h2>

        <div className="cta__wrap content-end">
          {ctaText?.value && href && (
            <ButtonHref
              cssClass={`${
                getFooterCallOutBarStylesFromBackgroundColor(backgroundColor) === 'primary'
                  ? 'violet'
                  : 'white'
              }`}
              text={<TextSitecoreField field={ctaText} />}
              arrow={true}
              href={href}
              linkType={linkType}
              componentName="CardFooterAction"
              variant="primary-button"
              linkFields={linkFields}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CardForFooterAction.defaultProps = {
  title: '',
  ctaText: {},
  backgroundColor: 'violet',
  componentName: '',
  linkType: 'internal',
  linkFields: {},
};

CardForFooterAction.propTypes = {
  title: PropTypes.string,
  ctaText: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  componentName: PropTypes.string,
  linkFields: PropTypes.shape({}),
  linkType: PropTypes.string,
};

export default CardForFooterAction;
