import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import DecisionTreeCta from '../DecisionTreeCta/DecisionTreeCta';
import DecisionTreeFooter from '../DecisionTreeFooter/DecisionTreeFooter';
import DecisionTreeStep1 from '../DecisionTreeStep1/DecisionTreeStep1';
import DecisionTreeStep2 from '../DecisionTreeStep2/DecisionTreeStep2';
import DecisionTreeStep3 from '../DecisionTreeStep3/DecisionTreeStep3';
import { isSCExperienceEditorActive, getBrowserWindow } from '../../../utils/HelperUtils';
import { fetchCardBlockLargeList } from '../../../service';
import {
  CONTENT_TYPE,
  INDUSTRY,
  LINE_OF_BUSINESS,
  SOLUTIONS,
  BUSINESS_NEED,
  COMPANY,
  PERSONA,
  CAMPAIGN,
  TOPIC,
} from '../../../constants/index';
import './decisionTree.scss';
import './dt-animate.scss';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { default as UpArrow } from '../../../../src/static/images/decision-tree-up-arrows.svg';
import { default as RightArrow } from '../../../../src/static/images/decision-tree-right-arrows.svg';

const DecisionTree = ({ ...props }) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    items,
    bottomText,
    ctaText,
    optCtaText,
    closeIcon,
    backBtnText,
    backBtnIcon,
    backBtnMobileIcon,
    widgetText,
    widgetIconDesktop,
  } = fields;
  const { fields: cards } = items && items?.length > 0 && items[0];
  const { title = {}, subTitle = {} } = cards;

  const nodeRef = useRef(null);
  const [isTreeActive, setIsTreeActive] = useState(false);
  const [displayTree, setDisplayTree] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [displayBg, setDisplayBg] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(title ? [title.value] : []);
  const [cardsStep2, setCardsStep2] = useState(undefined);
  const [cardsStep3, setCardsStep3] = useState(undefined);
  const isBigScreen = useMediaQuery({ minWidth: breakpoints.md });

  useEffect(() => {
    setDisplayBg(currentStep === 2.1 ? false : isBigScreen);
  }, [currentStep, isBigScreen]);

  const setNoScroll = (bool) => {
    if (bool) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const handleDisplayTree = () => {
    setDisplayTree(true);
    setIsTreeActive(true);
    setNoScroll(true);

    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'dt_view',
          step_level: 1,
          step_name: '',
        });
      }
    }
  };

  const handleCloseTree = () => {
    setDisplayTree(false);
    setTimeout(() => {
      setIsTreeActive(false);
    }, 1000);
    setNoScroll(false);

    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'dt_view',
          step_level: 0,
          step_name: '',
        });
      }
    }
  };

  const handleClickStep1 = (selectedCard) => {
    const card = cards.items[selectedCard];
    if (card && card.fields) {
      setBreadcrumbs((breadcrumbs) => [...breadcrumbs, card.name]);
      setCardsStep2(card.fields);
      setCurrentStep(card.fields.foregroundImage ? 2.1 : 2.2);
      nodeRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'dt_view',
          step_level: 2,
          step_name: card.name,
        });
      }
    }
  };

  const buildPostService = async (selectedCard) => {
    const cardApi = cardsStep2.items[selectedCard];
    const fieldsToSelect = [
      CONTENT_TYPE.toLowerCase(),
      INDUSTRY.toLowerCase(),
      LINE_OF_BUSINESS.toLowerCase(),
      SOLUTIONS.toLowerCase(),
      BUSINESS_NEED.toLowerCase(),
      COMPANY.toLowerCase(),
      PERSONA.toLowerCase(),
      CAMPAIGN.toLowerCase(),
      TOPIC.toLowerCase(),
    ];
    // Iterate through the "fields" array
    const filteredArray = [];

    for (const key in cardApi.fields) {
      if (fieldsToSelect.includes(key)) {
        filteredArray.push({
          [key]: cardApi.fields[key],
        });
      }
    }
    // Initialize an empty object to store the filtered data
    const filteredData = {};

    // Extract and format data for InsightsFilters
    filteredArray.forEach((item) => {
      for (const key in item) {
        if (key === CONTENT_TYPE.toLowerCase()) {
          const contentTypeTitle = item[key]?.fields?.title?.value;
          filteredData.contentType = `${contentTypeTitle}`;
        } else if (Array.isArray(item[key]) && item[key].length > 0 && 'id' in item[key][0]) {
          filteredData[key] = item[key].map((entry) => entry.id).join(', ');
        }
      }
    });

    // Create the InsightsFilters string
    const InsightsFilters = Object.entries(filteredData)
      .map(([key, value]) => `${key} = ${value}`)
      .join(' | ');

    const result = await fetchCardBlockLargeList(InsightsFilters, 7, 0, 'en');
    const payload = result.data;
    return payload;
  };

  const handleClickStep2 = (selectedCard) => {
    const card = cardsStep2.items[selectedCard];
    if (card && card.fields && card.fields.decisionTreeCards) {
      setBreadcrumbs((breadcrumbs) => [...breadcrumbs, card.name]);
      setCardsStep3(card.fields);
      setCurrentStep(3);
      nodeRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const result = buildPostService(selectedCard);
      result.then((resolvedValue) => {
        setBreadcrumbs((breadcrumbs) => [...breadcrumbs, card.name]);
        const updatedFields = {
          ...card.fields,
          decisionTreeCards: resolvedValue.searchResult,
        };
        setCardsStep3(updatedFields);
        setCurrentStep(3);
        nodeRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      });
    }

    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'dt_view',
          step_level: 3,
          step_name: card.name,
        });
      }
    }
  };

  const handleBackClick = () => {
    if (currentStep === 3) {
      setBreadcrumbs((breadcrumbs) => breadcrumbs.slice(0, -1));
      setCardsStep3(undefined);
      setCurrentStep(2.1);
    } else if (currentStep === 2.1 || currentStep === 2.2) {
      setBreadcrumbs([title.value]);
      setCardsStep2(undefined);
      setCurrentStep(1);
    }

    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'dt_view',
          step_level: currentStep === 3 ? 2 : 1,
          step_name: '',
        });
      }
    }
  };

  return (
    <section
      className={`decision-tree decision-tree--${
        isTreeActive ? 'show' : 'hide'
      } decision-tree--${currentStep.toString().replace('.', '-')}`}
      {...ComponentDataAttributes(componentName)}
    >
      {!displayTree && (
        <DecisionTreeCta label={widgetText} icon={widgetIconDesktop} onClick={handleDisplayTree} />
      )}
      <CSSTransition
        in={displayTree}
        nodeRef={nodeRef}
        timeout={500}
        classNames="dt-animate"
        mountOnEnter
        unmountOnExit
      >
        <div ref={nodeRef} className="decision-tree__wrapper">
          <div className="decision-tree__container">
            {displayBg && (
              <>
                <img
                  src={mediaApi.updateImageUrl(UpArrow)}
                  className="decision-tree__up-arrow"
                  alt="up arrow"
                />
                <img
                  src={mediaApi.updateImageUrl(RightArrow)}
                  className="decision-tree__right-arrow"
                  alt="right arrow"
                />
              </>
            )}

            {currentStep === 1 && (
              <DecisionTreeStep1
                subTitle={subTitle}
                title={title}
                closeIcon={closeIcon}
                cards={cards}
                onClick={handleClickStep1}
                closeDecisionTree={handleCloseTree}
                componentName={componentName}
              />
            )}

            {(currentStep === 2.1 || currentStep === 2.2) && cardsStep2 && (
              <DecisionTreeStep2
                subTitle={cardsStep2?.subTitle}
                title={cardsStep2?.title}
                breadcrumbs={breadcrumbs}
                featuredImage={cardsStep2?.foregroundImage}
                cards={cardsStep2?.decisionTreeCards}
                currentStep={currentStep}
                closeIcon={closeIcon}
                backBtnText={backBtnText}
                backBtnIcon={backBtnIcon}
                backBtnMobileIcon={backBtnMobileIcon}
                onBackClick={handleBackClick}
                onCardClick={handleClickStep2}
                closeDecisionTree={handleCloseTree}
                componentName={componentName}
              />
            )}

            {currentStep === 3 && cardsStep3 && (
              <DecisionTreeStep3
                subTitle={cardsStep3.subTitle.value}
                title={cardsStep3.title.value}
                breadcrumbs={breadcrumbs}
                cards={cardsStep3.decisionTreeCards}
                currentStep={currentStep}
                closeIcon={closeIcon}
                backBtnText={backBtnText}
                backBtnIcon={backBtnIcon}
                backBtnMobileIcon={backBtnMobileIcon}
                ctaArrowIcon={cardsStep3.ctaArrowIcon}
                ctaText={cardsStep3.ctaText}
                onBackClick={handleBackClick}
                closeDecisionTree={handleCloseTree}
                componentName={componentName}
              />
            )}
          </div>
          <div className="decision-tree__footer">
            <DecisionTreeFooter prompt={bottomText} cta1={ctaText.value} cta2={optCtaText.value} />
          </div>
        </div>
      </CSSTransition>
    </section>
  );
};

DecisionTree.defaultProps = {
  fields: {
    items: [],
    bottomText: '',
    cards: {},
    ctaText: {},
    title: {},
    subTitle: {},
    breadcrumbs: {},
    featuredImage: {},
    optCtaText: {},
    closeIcon: {},
    backBtnText: '',
    backBtnIcon: {},
    backBtnMobileIcon: {},
    widgetText: '',
    widgetIconDesktop: {},
  },
  rendering: {},
};

DecisionTree.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.array,
    bottomText: PropTypes.string,
    cards: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    title: PropTypes.shape({}),
    subTitle: PropTypes.shape({}),
    breadcrumbs: PropTypes.shape({}),
    featuredImage: PropTypes.shape({}),
    optCtaText: PropTypes.shape({}),
    closeIcon: PropTypes.shape({}),
    backBtnText: PropTypes.string,
    backBtnIcon: PropTypes.shape({}),
    backBtnMobileIcon: PropTypes.shape({}),
    widgetText: PropTypes.string,
    widgetIconDesktop: PropTypes.shape({}),
  }),
  rendering: PropTypes.shape({}),
};

export default DecisionTree;
