import React from 'react';
import PropTypes from 'prop-types';
import CardInsightsForCallout from '../Cards/CardInsightsForCallout';
import CardInsightsCallout from '../Cards/CardInsightsCallout';
import { ComponentDataAttributes } from '../../../constants/gtm';

function CardBlockInsightsCallout(props) {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { cardListing, insightListing, tagsToShow, isLatestPillsColor } = fields;
  return (
    <div
      className={'card-block insights insights__callout featured'}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row">
          <div className="col card-wrapper">
            {cardListing &&
              cardListing.map((item1, index1) => (
                <CardInsightsCallout
                  key={index1}
                  cardListing={item1}
                  componentName={componentName}
                />
              ))}

            {insightListing &&
              insightListing?.map((item2, index2) => (
                <CardInsightsForCallout
                  item={item2}
                  key={index2}
                  tagsToShow={tagsToShow}
                  ctaText={item2.ctaText}
                  componentName={componentName}
                  isLatestPillsColor={isLatestPillsColor}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

CardBlockInsightsCallout.defaultProps = {
  fields: {},
  featuredInsight: true,
};

CardBlockInsightsCallout.propTypes = {
  items: PropTypes.array,
  featuredInsight: PropTypes.bool,
  fields: PropTypes.shape({}),
};

export default CardBlockInsightsCallout;
