import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Select = ({
  id,
  labelText,
  title,
  options,
  errors,
  errorMsg,
  reg,
  cssClass,
  isRequiredField,
}) => {
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className=" form-block form-block-wrapper">
      {title && <label htmlFor={id}>{`${labelText} ${isRequiredField ? '*' : ''}`}</label>}
      <div className={`form-block ${value ? 'select-active' : ''}`}>
        <select
          id={id}
          onChange={handleChange}
          {...reg}
          className={` ${errors?.message === undefined ? '' : 'invalid error'}`}
        >
          {options?.map((option) => (
            <option key={option?.fields?.id?.value} value={option?.fields?.id?.value}>
              {option?.fields?.displayText?.value}
            </option>
          ))}
        </select>
        {errors && errors.type === 'required' && <p className="error-msg">{errors.message}</p>}
      </div>
    </div>
  );
};

Select.defaultProps = {
  id: 'id',
  labelText: 'Label',
  options: [
    {
      title: '',
      value: '0',
    },
    {
      title: 'Option 1',
      value: '1',
    },
    {
      title: 'Option 2',
      value: '2',
    },
    {
      title: 'Option 3',
      value: '3',
    },
  ],
  errorMsg: '',
};

Select.propTypes = {
  /** Field identifier  */
  id: PropTypes.string,

  /** Label text */
  labelText: PropTypes.string,

  /** Title for input field */
  title: PropTypes.string,

  /** Options to display in the drop down */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,
};

export default Select;
