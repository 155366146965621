import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import ArticleTags from '../DetailsBlocks/ArticleTags';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import ResponsivePicture from '../../../globals/icons/ResponsivePicture';
import { getContentTypeTags } from '../../../utils/HelperUtils';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

const CardInsightsEvent = ({
  title,
  subTitle,
  text,
  imgMobile,
  imgDesktop,
  ctaUrl,
  ctaText,
  cssClass,
  articleTags,
  contentType,
  fields,
  tags,
  componentName = '',
  ...featured
}) => {
  const event = featured.featured[0].fields;
  const [alltags, setAlltags] = useState([]);
  let tagsToShow = [],
    matchedValue = [];
  let objectTag = [],
    final = [];
  let keysItem = event ? Object.keys(event) : [];
  const keysToMatch = keysItem && keysItem.map((e) => e.toLowerCase());
  const tagName = () => {
    tags &&
      tags.map((i) => {
        tagsToShow.push(i?.fields?.name?.value.replace(/ /g, '').toLowerCase());
      });

    for (let i = 0; i < tagsToShow.length; i++) {
      if (keysToMatch.includes(tagsToShow[i])) {
        if (tagsToShow[i] === 'lineofbusiness') {
          tagsToShow[i] = 'lineOfBusiness';
        }
      }
    }
    matchedValue.push(tagsToShow);

    tagsToShow.map((e) => {
      objectTag.push(tags[e]);
      final = objectTag.flat(1);
    });
  };
  tagName();

  useEffect(() => {
    let arrTags = [];
    if (tagsToShow.length > 0) {
      tagsToShow.forEach(
        (tag) =>
          (arrTags = [
            ...arrTags,
            ...fields[tag].map((tagData) =>
              tagData?.fields?.subTag
                ? {
                    text: tagData?.fields?.subTag?.name,
                    icon: tagData?.fields?.subTag?.icon?.value,
                  }
                : { text: tagData?.fields?.title?.value, icon: tagData?.fields?.icon?.value }
            ),
          ])
      );
    }
    setAlltags(arrTags.slice(0, 2));
  }, []);
  return (
    <Link
      to={ctaUrl}
      className="card card__insights card__event event__listing"
      {...OtherLinkDataAttributes({
        name: componentName,
        text: text,
        url: ctaUrl,
        dataItemName: event?.Title?.value,
      })}
    >
      <div className="img-wrapper">
        <ResponsivePicture
          imageSmall={event?.image?.value}
          imageMedium={event?.image?.value}
          imageLarge={event?.image?.value}
        />
      </div>

      <div className="card-body">
        <ManageContentTypeTags tags={getContentTypeTags(event, fields)} />
        <div className="card-content">
          {event?.subTitle && <div className="sub-title">{event?.subTitle?.value}</div>}
          {event?.Title && <h3 className="title mb-12">{event?.Title?.value}</h3>}
          {event?.Content && <p className="mb-20">{event?.Content?.value}</p>}
        </div>
        <div className="cta__wrap">
          <Button
            variant="text-button"
            fontSize="small"
            text="Read More"
            arrow={true}
            dataItemName={event?.Title?.value}
            componentName={componentName}
          />
        </div>
        <div className="related-solutions">
          <ArticleTags tags={alltags} />
        </div>
      </div>
    </Link>
  );
};

CardInsightsEvent.defaultProps = {
  /** Featured image */
  imgMobile: '',
  imgDesktop: '',
  altText: '',
  articleTags: [],
  contentType: 'Event',
  /** Optional */
  subTitle: '',
  /** Card title (heading 3) */
  title: '',
  /** Card content */
  text: '',
  ctaUrl: '',
  ctaText: 'Read more',
  ctaMode: 'arrow',
  cssClass: '',
  solutionsTags: [],
  componentName: '',
};

CardInsightsEvent.propTypes = {
  imgMobile: PropTypes.string,
  imgDesktop: PropTypes.string,
  altText: PropTypes.string,
  contentType: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  solutionsTags: PropTypes.array,
  componentName: PropTypes.string,
};

export default CardInsightsEvent;
