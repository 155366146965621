import React, { useState } from 'react';
import Checkbox from '../../../globals/forms/CheckboxFn';
import { useForm } from 'react-hook-form';
import TextBox from '../ContactUs_form/TextBoxContact';
import TextAreaBox from '../ContactUs_form/TextAreaContact';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import {
  getFooterCallOutBarStylesFromBackgroundColor,
  currentLanguage,
  getBrowserWindow,
  isSCExperienceEditorActive,
} from '../../../utils/HelperUtils';

import { ButtonDataAttributes } from '../../../constants/gtm';
import axios from 'axios';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { isEmpty } from 'lodash';
import ButtonHref from '../../../globals/buttons/ButtonHref';

const SidebarCardSubscribe = ({ props, componentName }) => {
  const [cbCheck, setCbCheck] = useState(false);
  const [cbError, setCbError] = useState('');
  const [showMsg, setMsg] = useState(false);
  const [resData, setResData] = useState('');

  const {
    Description,
    hubspotFormId,
    hubspotPortalId,
    firstColumnFormFields,
    ctaText,
    ctaArrow,
    ctaLink,
    formBackGroundColor,
    isConsentRequired,
    consentText,
    successMessage,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/HubSpot/FormSubmit`;

  const patternRegexCheck = (pattern) => {
    let regex = '';
    if (pattern && pattern.match) {
      const match = pattern.match(new RegExp('^/(.*?)/([gimy]*)$'));
      regex = match ? new RegExp(match[1], match[2]) : new RegExp(pattern);
    }
    return regex;
  };

  const validateCb = () => {
    if (cbCheck === false) {
      setCbError('Please check privacy policy');
      return false;
    }
    if (cbCheck === true) {
      setCbError('');
      return true;
    }
  };

  // For GTM error
  if (errors && !isEmpty(errors) && !isSCExperienceEditorActive) {
    if (getBrowserWindow) {
      window?.dataLayer?.push({
        event: 'form_error',
        form_id: hubspotFormId?.value || '',
        form_name: 'SidebarCardSubscribe',
        form_error: 'Invalid data',
      });
    }
  }
  async function apiPost(postdata) {
    if (validateCb()) {
      try {
        const data = {
          FormId: hubspotFormId?.value,
          PortalID: hubspotPortalId?.value,
          language: currentLanguage() || 'en',
          formData: `{"fields" : ${JSON.stringify(postdata)}}`,
        };
        const apiCall = await axios.post(url, data);
        const apiStatus = await JSON.parse(apiCall?.data?.statusCode);
        if (apiStatus === 200) {
          setMsg(true);
          setTimeout(() => {
            setMsg(false);
          }, 4000);
          reset();
          setCbCheck(false);
        }
      } catch (error) {}
    }
  }

  const onSubmit = (data) => {
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'form_submit',
          form_id: hubspotFormId?.value || '',
          form_name: 'SidebarCardSubscribe',
        });
      }
    }
    let newFormatdata = [];
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newFormatdata.push({ name: `${key}`, value: `${data[key]}` });
        }
      }
    }
    newFormatdata ? apiPost(newFormatdata) : '';
  };
  const btnCss =
    getFooterCallOutBarStylesFromBackgroundColor(formBackGroundColor?.fields?.text?.value) ===
    'primary'
      ? 'violet'
      : 'white';
  return (
    <div
      className={`card sidebar sidebar__subscribe ${getFooterCallOutBarStylesFromBackgroundColor(
        formBackGroundColor?.fields?.text?.value
      )}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="title">
          <TextSitecoreField field={Description} />
        </p>
        {!resData ? (
          <div className="form-wrapper-dual">
            <div className="input">
              {firstColumnFormFields &&
                firstColumnFormFields?.map((item) => {
                  let label = item?.fields?.Label?.value;
                  let name = item?.fields?.hubspotFieldMapping?.value;
                  let isRequired = item?.fields?.isRequiredField?.value;
                  switch (item?.fields?.fieldType?.fields?.text?.value) {
                    case 'text':
                      return (
                        <TextBox
                          // Label={label}
                          type="text"
                          name={name}
                          reg={{
                            ...register(`${name}`, {
                              required: isRequired
                                ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                : '',
                              maxLength: {
                                value: item?.fields?.maxLength?.value || null,
                                message: 'Exceeded the limits',
                              },
                              pattern: {
                                value: item?.fields?.validationRegex?.fields?.text?.value
                                  ? patternRegexCheck(
                                      item?.fields?.validationRegex?.fields?.text?.value
                                    )
                                  : '',
                                message: `${item?.fields?.validationErrorMessage?.value}`,
                              },
                            }),
                          }}
                          placeholder={item?.fields?.placeholderText?.value}
                          isRequiredField={isRequired}
                          errors={errors[`${name}`]}
                        />
                      );
                    case 'richtext':
                      return (
                        <TextAreaBox
                          Label={label}
                          type="text"
                          name={name}
                          placeholder={item?.fields?.placeholderText?.value}
                          reg={{
                            ...register(`${name}`, {
                              required: isRequired
                                ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                : '',
                              maxLength: {
                                value: item?.fields?.maxLength?.value || null,
                                message: 'Exceeded the limits',
                              },
                              pattern: {
                                value: item?.fields?.validationRegex?.fields?.text?.value
                                  ? patternRegexCheck(
                                      item?.fields?.validationRegex?.fields?.text?.value
                                    )
                                  : '',
                                message: `${item?.fields?.validationErrorMessage?.value}`,
                              },
                            }),
                          }}
                          isRequiredField={isRequired}
                          errors={errors[`${name}`]}
                        />
                      );
                    default:
                      return '';
                  }
                })}
              {isConsentRequired?.value && (
                <div className="form-footer">
                  <Checkbox
                    id="dualsubscribe-check"
                    className={'contact-checkbox-label'}
                    // text={fields?.consentText?.value}
                    text={<RichTextSitecoreField field={consentText} />}
                    onClick={(e) => {
                      setCbCheck(e.target.checked);
                    }}
                  />
                  {cbError && <span className="error-message">{cbError}</span>}
                </div>
              )}
              {showMsg && (
                <div className="container-fluid">
                  <div className="submitted-message hs-main-font-element">
                    <RichTextSitecoreField field={successMessage} />
                  </div>
                </div>
              )}
              <div className="newsLetter_left">
                {ctaText?.value && firstColumnFormFields && firstColumnFormFields.length > 0 ? (
                  <button
                    className={`button primary-button medium ${btnCss} icon__right signpost contact-submit`}
                    type="submit"
                    {...ButtonDataAttributes({
                      name: componentName,
                      text: ctaText?.value,
                      variant: '',
                      arrow: true,
                      dataItemName: 'Dual footer subscribe',
                    })}
                  >
                    {ctaText?.value ? ctaText?.value : 'Subscribes Now'}
                    <div className="arrow-icon"></div>
                  </button>
                ) : (
                  !isEmpty(ctaText?.value) && (
                    <ButtonHref
                      text={<TextSitecoreField field={ctaText} /> || ctaText?.value}
                      arrow={ctaArrow?.value}
                      variant="primary-button"
                      href={ctaLink?.value?.href}
                      linkType={ctaLink?.value?.linktype}
                      componentName={componentName}
                      linkFields={ctaLink}
                      cssClass={`button primary-button medium ${btnCss} icon__right signpost contact-submit`}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`${
              getFooterCallOutBarStylesFromBackgroundColor(
                formBackGroundColor?.fields?.text?.value
              ) === 'secondary' ||
              getFooterCallOutBarStylesFromBackgroundColor(
                formBackGroundColor?.fields?.text?.value
              ) === 'violet'
                ? 'submitted-message'
                : 'submitted-message-black'
            } hs-main-font-element`}
          >
            {resData}
          </div>
        )}
      </form>
    </div>
  );
};

SidebarCardSubscribe.defaultProps = {
  Description: '',
  hubspotFormId: '',
  hubspotPortalId: '',
  firstColumnFormFields: '',
  ctaText: '',
  formBackGroundColor: 'green',
  isConsentRequired: '',
  consentText: '',
  successMessage: '',
};

export default SidebarCardSubscribe;
