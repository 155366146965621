import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { OtherNavDataAttributes } from '../../../constants/gtm';

const ActionBarSocialFollow = ({ items = [], componentName = '' }) => (
  <div className="social-follow">
    {items &&
      Array.isArray(items) &&
      items?.map((item, id) => (
        <a
          key={id}
          href={`${item.phone ? 'tel:' + item.text : item?.fields?.ctaLink?.value?.url}`}
          className={`social-item ${item.phone ? 'phone' : ''}`}
          {...OtherNavDataAttributes({
            name: componentName,
            itemLevel: 'L0',
            dataNavItemName: item?.name || '',
            dataLinkText: item?.text,
            dataLinkImageUrl: item?.fields?.ctaLink?.value?.url,
          })}
        >
          <div className="icon">
            <ImageSitecoreField field={item?.fields?.icon} alt={item?.name} />
          </div>
          <div className="text">
            <TextSitecoreField field={item?.fields?.ctaText}></TextSitecoreField>
          </div>
        </a>
      ))}
  </div>
);

ActionBarSocialFollow.defaultProps = {
  items: [],
  componentName: '',
};

ActionBarSocialFollow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  componentName: PropTypes.string,
};

export default ActionBarSocialFollow;
