import React from 'react';
import PropTypes from 'prop-types';
import TextHeader from '../TextBlocks/TextHeader';
import RichTextArea from '../TextBlocks/RichTextArea';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Player from '../Player/Player';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import ActionBarTags from '../ActionBars/ActionBarTags';
import { ComponentDataAttributes } from '../../../constants/gtm';
import {
  CONTENT_TYPE_PODCAST,
  INDUSTRY,
  LINE_OF_BUSINESS,
  SOLUTIONS,
  BUSINESS_NEED,
  COMPANY,
  PERSONA,
  CAMPAIGN,
  TOPIC,
} from '../../../constants/index';
import { withTranslation } from 'react-i18next';

const InsightsDetailBlock = ({
  breadcrumb,
  tags,
  variant,
  rendering: { componentName = '' },
  ...props
}) => {
  const { sitecoreContext, t } = props;
  const { route } = sitecoreContext;
  const { fields } = route;
  const fieldsToSelect = [
    INDUSTRY.toLowerCase(),
    LINE_OF_BUSINESS.toLowerCase(),
    SOLUTIONS.toLowerCase(),
    BUSINESS_NEED.toLowerCase(),
    COMPANY.toLowerCase(),
    PERSONA.toLowerCase(),
    CAMPAIGN.toLowerCase(),
    TOPIC.toLowerCase(),
  ];
  // Iterate through the "fields" array
  const filteredArray = [];

  for (const key in route.fields) {
    if (fieldsToSelect.includes(key.toLowerCase())) {
      filteredArray.push({
        [key]: route.fields[key],
      });
    }
  }

  // Merge all tages inside one array
  const mergedTags = new Set();

  filteredArray.forEach((item) => {
    for (const key in item) {
      if (item[key]) {
        item[key].forEach((subItem) => {
          const mergedItem = {
            id: subItem.fields.subTag ? subItem.fields.subTag.id : subItem.id,
            url: subItem.fields.subTag ? subItem.fields.subTag.url : subItem.url,
            name: subItem.fields.subTag ? subItem.fields.subTag.name : subItem.name,
            displayName: subItem.fields.subTag
              ? subItem.fields.subTag.displayName
              : subItem.displayName,
            fields: subItem.fields.subTag ? subItem.fields.subTag.fields : subItem.fields,
          };

          mergedTags.add(JSON.stringify(mergedItem));
        });
      }
    }
  });

  const uniqueMergedTags = [...mergedTags].map((str) => JSON.parse(str));

  return (
    <div className={`container-xl ${componentName}`} {...ComponentDataAttributes(componentName)}>
      <div className={`content-wrapper ${fields?.contentType?.fields?.title?.value}`}>
        {breadcrumb && variant === 'video' && <Breadcrumb items={breadcrumb} />}
        {/* {fields?.Title && fields?.subTitle && <TextHeader subTitle={fields.subTitle} />} */}
        {fields?.Content && (
          <RichTextArea customCssClass="insight_details_block_richtext" richText={fields.Content} />
        )}
        {fields?.contentType?.fields?.title?.value.toLowerCase() === CONTENT_TYPE_PODCAST && (
          <Player
            value={fields?.videoContent?.value}
            componentName={componentName}
            variant={fields?.contentType?.fields?.title?.value.toLowerCase()}
          />
        )}
        {/* <div className="tag-title-translation-text tagsblocktitle">{t('tag_title')}</div> */}
        {/* {tags && (
          <ActionBarTags
            items={uniqueMergedTags}
            componentName={componentName}
            contentType={fields?.contentType}
          />
        )} */}
      </div>
    </div>
  );
};

InsightsDetailBlock.defaultProps = {
  breadcrumb: [],
  tags: [],
  title: '',
  subTitle: '',
  richText: '',
  variant: '',
  t: () => {},
};

InsightsDetailBlock.propTypes = {
  breadcrumb: PropTypes.array,
  tags: PropTypes.array,
  variant: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  richText: PropTypes.string,
  t: PropTypes.func,
};

const InsightsDetailBlockContainer = withSitecoreContext()(InsightsDetailBlock);

export default withTranslation()(InsightsDetailBlockContainer);
