import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';

const FeaturetteBgImage = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;

  const {
    ctaLink,
    ctaStyle,
    ctaText,
    paragraphText,
    subTitle,
    title,
    backgroundImage,
    backgroundImageMobile,
    align,
    width,
    ctaArrow,
  } = fields;

  let contentWidth;
  switch (width) {
    case 'small':
      contentWidth = 'col-md-10 col-lg-6 col-xl-5';
      break;
    default:
      contentWidth = 'col-md-10 col-lg-7 col-xl-6';
  }
  return (
    <section
      className={`featurette-section standard bg-image white ${width?.fields?.text?.value} ${align?.fields?.text?.value} image`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          backgroundImageMobile && backgroundImageMobile?.value && backgroundImageMobile?.value?.src
            ? backgroundImageMobile
            : backgroundImage
        }
        imageMedium={
          backgroundImageMobile && backgroundImageMobile?.value && backgroundImageMobile?.value?.src
            ? backgroundImageMobile
            : backgroundImage
        }
        imageLarge={backgroundImage}
        imageXLarge={backgroundImage}
      />

      <div className="container-xl img">
        <div className={`row content-wrapper ${align?.fields?.text?.value}`}>
          <div className={`${contentWidth}`}>
            <div className={'featurette-body white'}>
              {subTitle && (
                <div className="sub-title">
                  <TextSitecoreField field={subTitle} />
                </div>
              )}
              {title && (
                <h2 className="title">
                  <TextSitecoreField field={title} />
                </h2>
              )}
              {paragraphText && paragraphText?.value && (
                <div className="richtext-wrapper">
                  {paragraphText && <RichTextSitecoreField field={paragraphText} />}
                </div>
              )}
              {ctaText?.value && (
                <div className="cta-wrapper">
                  <ButtonHref
                    text={ctaText?.value}
                    cssClass={ctaStyle?.fields?.text?.value}
                    variant={ctaStyle?.fields?.text?.value}
                    dataItemName={title?.value || ''}
                    arrow={ctaArrow?.value}
                    href={ctaLink?.value?.href}
                    linkType={fields?.ctaLink?.value?.linktype}
                    linkFields={fields?.ctaLink}
                    componentName={componentName}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturetteBgImage.defaultProps = {
  fields: {
    ctaLink: '',
    paragraphText: '',
    subTitle: '',
    title: '',
    richTextArea: '',
    align: { fields: { text: { value: 'left' } } },
    width: { fields: { text: { value: 'small' } } },
    ctaText: '',
    backgroundImage: '',
    ctaArrow: { value: false },
    backgroundColor: {
      fields: {
        Color: {
          value: 'primary',
        },
      },
    },
    cssClass: 'insight',
  },
};

FeaturetteBgImage.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    richTextArea: PropTypes.string,
    backgroundImage: PropTypes.string,
    ctaText: PropTypes.string,
    fields: PropTypes.shape({}),
    align: PropTypes.string,
    cssClass: PropTypes.string,

    /** Width of the text content on desktop: Small, Medium or Large */
    width: PropTypes.string,
    backgroundColor: PropTypes.shape({}),
  }),
};

export default FeaturetteBgImage;
