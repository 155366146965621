import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Checkbox from '../../../globals/forms/CheckboxFn';
import { useForm } from 'react-hook-form';
import TextBox from '../../core/ContactUs_form/TextBoxContact';
import TextAreaBox from '../../core/ContactUs_form/TextAreaContact';
import { ButtonDataAttributes, ComponentDataAttributes } from '../../../constants/gtm';
import {
  getBrowserWindow,
  isSCExperienceEditorActive,
  currentLanguage,
} from '../../../utils/HelperUtils';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import Alert from '../../core/ContactUs_form/Alert';
import './gatedsubscribe.scss';
import { isEmpty } from 'lodash';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import OverlayLoader from '../ErrorBoundary/OverlayLoader';

const FormSubscribe = (props) => {
  const [cbCheck, setCbCheck] = useState(false);
  const [cbError, setCbError] = useState('');
  const [showMsg, setMsg] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [showErrMsg, setErrMsg] = useState(false);

  const {
    fields,
    params,
    rendering: { componentName = '' },
  } = props;
  const { firstColumnFormFields, hubspotFormId, hubspotPortalId } = fields;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/HubSpot/FormSubmit`;

  const validateCb = () => {
    if (cbCheck === false) {
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_error',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'FormSubscribe',
            form_error: 'Please check privacy policy',
          });
        }
      }
      setCbError('Please check privacy policy');
      return false;
    }
    if (cbCheck === true) {
      setCbError('');
      return true;
    }
  };

  // For GTM error

  if (errors && !isEmpty(errors) && !isSCExperienceEditorActive) {
    if (getBrowserWindow) {
      window?.dataLayer?.push({
        event: 'form_error',
        form_id: hubspotFormId?.value || '',
        form_name: componentName || 'FormSubscribe',
        form_error: errors?.email?.message,
      });
    }
  }
  async function apiPost(postdata) {
    if (validateCb()) {
      setLoader(true);
      try {
        const data = {
          FormId: hubspotFormId?.value,
          PortalID: hubspotPortalId?.value,
          language: currentLanguage() || 'en',
          formData: `{"fields" : ${JSON.stringify(postdata)}}`,
        };
        const apiCall = await axios.post(url, data);
        const apiStatus = await JSON.parse(apiCall?.data?.statusCode);
        if (apiStatus === 200) {
          if (!isSCExperienceEditorActive) {
            if (getBrowserWindow) {
              window?.dataLayer?.push({
                event: 'form_submit',
                form_id: hubspotFormId?.value || '',
                form_name: componentName || 'FormSubscribe',
              });
            }
          }
          setMsg(true);
          setTimeout(() => {
            setMsg(false);
          }, 4000);
          reset();
          setCbCheck(false);
          setLoader(false);
          if (isBrowserDocumentAvailable) {
            const mainMenuScrollTo = document?.querySelector('.subscribe-form');
            mainMenuScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else {
          setLoader(false);
          setErrMsg(true);
          setTimeout(() => {
            setErrMsg(false);
            false;
          }, 4000);
        }
      } catch (error) {}
    }
  }

  const onSubmit = (data) => {
    let newFormatdata = [];
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newFormatdata.push({ name: `${key}`, value: `${data[key]}` });
        }
      }
    }
    newFormatdata ? apiPost(newFormatdata) : '';
  };
  const handleInputChange = (event) => {
    if (!formChanged) {
      setFormChanged(true);
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_start',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'FormSubscribe',
          });
        }
      }
    }
  };

  return (
    <section
      className={'form-section form-section__download left gatedsubscribe-forms'}
      {...ComponentDataAttributes(componentName)}
      style={{
        backgroundImage: <ImageSitecoreField field={fields?.backgroundImage} />,
      }}
    >
      <div className="container-xl">
        <div className={`row ${params?.formPosition}`}>
          <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">
            <div className={'content-wrapper on-primary subscribe-form'}>
              <h2 className="title">{fields?.Header?.value}</h2>
              {fields?.Description?.value && (
                <p className="sub-title">{fields?.Description?.value}</p>
              )}
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {firstColumnFormFields &&
                    firstColumnFormFields?.map((item) => {
                      let label = item?.fields?.Label?.value;
                      let name = item?.fields?.hubspotFieldMapping?.value;
                      let isRequired = item?.fields?.isRequiredField?.value;
                      const regax =
                        name === 'email'
                          ? /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                          : new RegExp(item?.fields?.validationRegex?.fields?.text?.value);
                      switch (item?.fields?.fieldType?.fields?.text?.value) {
                        case 'text':
                          return (
                            <TextBox
                              Label={label}
                              type="text"
                              name={name}
                              reg={{
                                ...register(`${name}`, {
                                  required: isRequired
                                    ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                    : '',
                                  maxLength: item?.fields?.maxLength?.value,
                                  pattern: {
                                    value: regax,
                                    message: `${item?.fields?.validationErrorMessage?.value}`,
                                  },
                                }),
                              }}
                              placeholder={item?.fields?.placeholderText?.value}
                              isRequiredField={isRequired}
                              errors={errors[`${name}`]}
                              handleInputChange={handleInputChange}
                            />
                          );
                        case 'richtext':
                          return (
                            <TextAreaBox
                              Label={label}
                              type="text"
                              name={name}
                              placeholder={item?.fields?.placeholderText?.value}
                              reg={{
                                ...register(`${name}`, {
                                  required: isRequired
                                    ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                    : '',
                                  maxLength: item?.fields?.maxLength?.value,
                                  pattern: {
                                    value: item?.fields?.validationRegex?.value,
                                    message: `${item?.fields?.validationErrorMessage?.value}`,
                                  },
                                }),
                              }}
                              isRequiredField={isRequired}
                              errors={errors[`${name}`]}
                              handleInputChange={handleInputChange}
                            />
                          );
                        default:
                          return '';
                      }
                    })}
                  {showLoader && <OverlayLoader />}
                  <div className="cta">
                    {showMsg && (
                      <div id="hubspotForm" className="hs-form-wrapper form-block">
                        <div className="hs-main-font-element">
                          <RichTextSitecoreField field={fields?.successMessage} />
                        </div>
                      </div>
                    )}
                    {showErrMsg && (
                      <div className="form-submit-error-msg hs-main-font-element">
                        <RichTextSitecoreField field={fields?.errorMessage} />
                      </div>
                    )}
                    {/* <Button text="Download the Report" variant="primary-button" cssClass="violet" arrow /> */}
                    <button
                      className="button primary-button violet icon__right signpost"
                      type="submit"
                      {...ButtonDataAttributes({
                        name: componentName,
                        text: `${fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}`,
                        variant: 'primary-button',
                        arrow: true,
                        dataItemName: 'subscribeForm',
                      })}
                    >
                      {fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}
                      <div className="arrow-icon"></div>
                    </button>
                  </div>
                  <div className="privacy-policy">
                    <Checkbox
                      id="subscribeform-check"
                      text={<RichTextSitecoreField field={fields?.consentText} />}
                      onClick={(e) => {
                        setCbCheck(e.target.checked);
                      }}
                    />
                    {cbError && <span className="error-message">{cbError}</span>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FormSubscribe.defaultProps = {
  uid: '',
  componentName: '',
  dataSource: '',
  params: {},
  fields: {
    successMessage: {},
    firstColumnFormFields: [],
    formBackGroundColor: {},
    errorMessage: {},
    Header: {},
    consentText: {},
    Description: {},
    hubspotFormId: {},
    hubspotPortalId: {},
    isConsentRequired: {},
    ctaLink: {},
    ctaIcon: {},
    ctaIconPosition: null,
    ctaArrow: {},
    ctaText: {},
    ctaStyle: {},
  },
};

FormSubscribe.propTypes = {
  Header: PropTypes.string,
  Description: PropTypes.string,
  form: PropTypes.object,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.object,
  align: PropTypes.object,
  mode: PropTypes.object,
};

export default FormSubscribe;
