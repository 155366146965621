import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';

const CardOfferingsSolution = ({ title, ctaText, backgroundColor, href, componentName = '' }) => (
  <div
    className={`card  footer-action ${getFooterCallOutBarStylesFromBackgroundColor(
      backgroundColor
    )} card-offerings-solution`}
  >
    <div>
      <h2 className="title offerings-solution-title">
        <TextSitecoreField field={title} />
      </h2>

      <div className="cta__wrap__offering">
        <ButtonHref
          cssClass={getFooterCallOutBarStylesFromBackgroundColor(backgroundColor)}
          text={ctaText}
          arrow={true}
          href={href}
          componentName={componentName}
          variant="secondary-button"
        />
      </div>
    </div>
  </div>
);

CardOfferingsSolution.defaultProps = {
  title: '',
  ctaText: '',
  href: '',
  backgroundColor: '',
  componentName: '',
};

CardOfferingsSolution.propTypes = {
  title: PropTypes.string,
  ctaText: PropTypes.string,
  backgroundColor: PropTypes.string,
  href: PropTypes.string,
  componentName: PropTypes.string,
};

export default CardOfferingsSolution;
