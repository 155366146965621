import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  getBrowserWindow,
  getLocationContactus,
  isSCExperienceEditorActive,
} from '../../../utils/HelperUtils';
import Cookies from 'js-cookie';

function SelectContact({
  title,
  isRequiredField,
  cssClass,
  id,
  errors,
  value,
  handleChange,
  reg,
  labelText,
  disabled,
  options,
  name,
  placeholder,
  countryDataVal,
  countryClick,
}) {
  const [currentCountry, setCurrentCountry] = useState(Cookies.get('countryCode'));
  const [selectvalue, setSelectValue] = useState(countryDataVal);
  const chckDisabled = countryClick && countryClick === true ? true : false;
  return (
    <div className="form-block-wrapper">
      {title && <label htmlFor={id}>{`${labelText} ${isRequiredField ? '*' : ''}`}</label>}
      <div className={`form-block ${selectvalue ? 'select-active' : ''}`}>
        <select
          id={id}
          disabled={chckDisabled}
          placeholder={placeholder}
          onChange={handleChange}
          name={name}
          {...reg}
          className={`${errors?.message === undefined ? '' : 'invalid error'}`}
          value={countryDataVal}
        >
          {options &&
            options.map((option, index) => {
              const optVal = option?.name === 'Select One' ? '' : option?.name;
              return (
                <option key={index} value={optVal}>
                  {option?.displayName}
                </option>
              );
            })}
        </select>
        {errors && errors.type === 'required' && <p className="error-msg">{errors.message}</p>}
      </div>
    </div>
  );
}

SelectContact.defaultProps = {
  id: '',
  labelText: '',
  options: [],
  errorMsg: '',
};

SelectContact.propTypes = {
  /** Field identifier  */
  id: PropTypes.string,

  /** Label text */
  labelText: PropTypes.string,

  /** Title for input field */
  title: PropTypes.string,

  /** Options to display in the drop down */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,
};

export default SelectContact;
