import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import './dataBlock.scss';
import { useMediaQuery } from 'react-responsive';

const DataBlock = ({ Data, showSymbol, chartName, chartComponentName, order, setOrder }) => {
  const {
    title,
    descLabel,
    description,
    size,
    color,
    chartType,
    useGradient,
    data,
    cta,
    backgroundColor,
  } = Data;
  let ChartComponent;
  const isMobile = useMediaQuery({ maxWidth: 991 });
  switch (chartType) {
    case 'bar':
      ChartComponent = lazy(() => import('./BarChart/BarChart'));
      break;
    case 'bar-alt':
      ChartComponent = lazy(() => import('./BarAlt/BarAlt'));
      break;
    case 'bubble':
      ChartComponent = lazy(() => import('./BubbleChart/BubbleChart'));
      break;
    case 'doughnut':
      ChartComponent = lazy(() => import('./DoughnutChart/DoughnutChart'));
      break;
    case 'line':
      ChartComponent = lazy(() => import('./LineChart/LineChart'));
      break;
    case 'linear-gauge':
      ChartComponent = lazy(() => import('./LinearGauge/LinearGauge'));
      break;
    default:
      ChartComponent = lazy(() => import('./BarChart/BarChart'));
      break;
  }

  return (
    <section className={`data-block data-block--${size.toLowerCase()} data-block--${chartType}`}>
      <div className="data-block__container">
        {title && (
          <div
            className={`data-block__title-container ${
              chartComponentName === 'NavigationExpandableGrid' ? 'col-8' : ''
            }${
              chartComponentName !== 'NavigationExpandableGrid' && chartType === 'bar' && !isMobile
                ? 'col-10'
                : ''
            }`}
          >
            <h4 className="data-block__title">{title}</h4>
            {(description || descLabel) && (
              <div className="data-block__desc-container">
                {descLabel && <label className="data-block__label">{descLabel}</label>}
                {description && <p className="data-block__text">{description}</p>}
              </div>
            )}
          </div>
        )}
        {chartComponentName === 'NavigationExpandableGrid' && (
          <div className="col search_header search_filter col-3 absolute-block">
            <div className="search_filter_wrapper wrapper_3">
              <div className="filterDropdown dropdown_open ">
                <div className="filterDropdownTitle ">
                  <span className="filter__title">
                    {order === 'TopFiveHighest' ? 'Highest' : 'Lowest'}
                  </span>
                </div>
                <div className="filter_options__Dropdown">
                  <div className="filter_options__Dropdown__bottom">
                    <div className="dropdown__item_all checkall highest">
                      <div
                        className="form-block checkbox "
                        onClick={(e) => {
                          setOrder('TopFiveHighest');
                        }}
                      >
                        <input
                          type="checkbox"
                          id="highest"
                          className="highest"
                          checked={order === 'TopFiveHighest' ? true : false}
                        />
                        <label htmlFor="highest">Highest</label>
                      </div>
                    </div>
                    <div className="dropdown__item lowest">
                      <div
                        className="form-block checkbox "
                        onClick={(e) => {
                          setOrder('TopFiveLowest');
                        }}
                      >
                        <input
                          type="checkbox"
                          id="lowest"
                          className="lowest"
                          checked={order === 'TopFiveLowest' ? true : false}
                        />
                        <label htmlFor="lowest">Lowest</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="data-block__chart-container">
          <Suspense fallback={<div className="data-block__fallback">Loading Chart...</div>}>
            <ChartComponent
              showSymbol={showSymbol}
              data={data}
              size={size}
              color={color}
              backgroundColor={backgroundColor}
              useGradient={useGradient}
              chartName={chartName}
              chartComponentName={chartComponentName}
            />
          </Suspense>
        </div>
      </div>
    </section>
  );
};

DataBlock.defaultProps = {
  size: 'M',
  useGradient: false,
};

DataBlock.propTypes = {
  title: PropTypes.string,
  descLabel: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.oneOf(['blue-violet', 'green-blue']),
  size: PropTypes.oneOf(['Tiny', 'XXS', 'XS', 'S', 'M', 'L', 'XL']),
  chartType: PropTypes.oneOf(['bar', 'bar-alt', 'bubble', 'doughnut', 'line', 'linear-gauge'])
    .isRequired,
  useGradient: PropTypes.bool,
  data: PropTypes.object.isRequired,
  cta: PropTypes.element,
};

export default DataBlock;
