import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Checkbox from '../../../globals/forms/CheckboxFn';
import { useForm } from 'react-hook-form';
import TextBox from '../ContactUs_form/TextBoxContact';
import TextAreaBox from '../ContactUs_form/TextAreaContact';
import { ButtonDataAttributes } from '../../../constants/gtm';
import OverlayLoader from '../ErrorBoundary/OverlayLoader';
import {
  getBrowserWindow,
  isBrowserDocumentAvailable,
  isSCExperienceEditorActive,
  currentLanguage,
} from '../../../utils/HelperUtils';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import Alert from '../ContactUs_form/Alert';
import './HubSpotGatedInsightForm.scss';
import { isEmpty } from 'lodash';

const HubSpotGatedInsightForm = (props) => {
  const [cbCheck, setCbCheck] = useState(false);
  const [cbError, setCbError] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { firstColumnFormFields, hubspotFormId, hubspotPortalId } = fields;
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/HubSpot/FormSubmit`;
  useEffect(() => {
    if (sessionStorage) {
      setTimeout(() => {
        let utmValues = sessionStorage.getItem('utmReferrer');
        utmValues = !isEmpty(utmValues) ? JSON.parse(utmValues) : {};
        Object.entries(utmValues).map(([key, value]) => setValue(key.toLowerCase(), value));
      }, 2000);
    }
  }, []);
  const validateCb = () => {
    if (cbCheck === false) {
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_error',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'FormGated',
            form_error: 'Invalid data',
          });
        }
      }
      setCbError('Please check privacy policy');
      return false;
    }
    if (cbCheck === true) {
      setCbError('');
      return true;
    }
  };

  // For GTM error
  if (errors && !isEmpty(errors) && !isSCExperienceEditorActive) {
    if (getBrowserWindow) {
      window?.dataLayer?.push({
        event: 'form_error',
        form_id: hubspotFormId?.value || '',
        form_name: componentName || 'FormGated',
        form_error: 'Invalid data',
      });
    }
  }
  async function apiPost(postdata) {
    // Validate checkbox
    if (validateCb()) {
      setShowLoader(true);
      try {
        const data = {
          FormId: hubspotFormId?.value,
          PortalID: hubspotPortalId?.value,
          language: currentLanguage() || 'en',
          formData: `{"fields" : ${JSON.stringify(postdata)}}`,
        };
        // Make API call to submit form data
        const apiCall = await axios.post(url, data);
        const apiStatus = await JSON.parse(apiCall?.data?.statusCode);
        if (apiStatus === 200) {
          if (!isSCExperienceEditorActive) {
            if (getBrowserWindow) {
              setShowMsg(true);
              const message = await JSON.parse(apiCall?.data?.message);
              if (message?.redirectUri !== '') {
                const filename = message?.redirectUri.split('/').pop();
                if (filename.includes('.pdf')) {
                  // Download PDF file
                  fetch(message?.redirectUri).then((response) => {
                    response.blob().then((blob) => {
                      const fileURL = window.URL.createObjectURL(blob);
                      let documentData = document.createElement('a');
                      documentData.href = fileURL;
                      documentData.download = filename;
                      documentData.click();
                    });
                  });
                } else {
                  // Open URL in new tab
                  window.open(message?.redirectUri, '_blank');
                }
              }
            }
          }
          // Hide success message after 15 seconds
          setTimeout(() => {
            setShowMsg(false);
          }, 15000);
          reset();
          setCbCheck(false);
          setShowLoader(false);
          // Push form submit event to dataLayer
          if (!isSCExperienceEditorActive) {
            getBrowserWindow &&
              window?.dataLayer?.push({
                event: 'form_submit',
                form_id: hubspotFormId?.value || '',
                form_name: componentName || 'FormGated',
              });
          }
        } else {
          // Show error message for 4 seconds
          setShowLoader(false);
          setShowErrMsg(true);
          setTimeout(() => {
            setShowErrMsg(false);
            false;
          }, 4000);
        }
      } catch (error) {
        console.error('fetch error', error);
      }
    }
  }

  const onSubmit = (data) => {
    let newFormatdata = [];
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newFormatdata.push({ name: `${key}`, value: `${data[key]}` });
        }
      }
    }
    newFormatdata ? apiPost(newFormatdata) : '';
  };
  const handleInputChange = (event) => {
    if (!formChanged) {
      setFormChanged(true);
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_start',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'FormGated',
          });
        }
      }
    }
  };

  const sectionBackground = fields?.backgroundImage?.value?.src;
  const backgroundValue =
    fields?.formBackGroundColor?.fields?.text?.value === 'violet' ? 'violet' : '';
  return (
    <section
      className={`form-section form-section__download left gatedsubscribe-forms hubspot-gatedinsights ${backgroundValue}`}
    >
      <div className="container-xl">
        <div className={'row left'}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 gated-lg-12">
            <div className={`content-wrapper on-primary gated-form bk-${backgroundValue}`}>
              <h2 className="title">{fields?.Header?.value}</h2>
              {fields?.Description?.value && (
                <p className="sub-title">{fields?.Description?.value}</p>
              )}
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {firstColumnFormFields &&
                    firstColumnFormFields?.map((item) => {
                      let label = item?.fields?.Label?.value;
                      let name = item?.fields?.hubspotFieldMapping?.value;
                      let isRequired = item?.fields?.isRequiredField?.value;
                      const regEx =
                        name === 'email'
                          ? /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                          : new RegExp(item?.fields?.validationRegex?.fields?.text?.value);
                      switch (item?.fields?.fieldType?.fields?.text?.value) {
                        case 'text':
                          return (
                            <TextBox
                              Label={label}
                              type="text"
                              name={name}
                              reg={{
                                ...register(`${name}`, {
                                  required: isRequired
                                    ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                    : '',
                                  maxLength: item?.fields?.maxLength?.value,
                                  pattern: {
                                    value: regEx,
                                    message: `${item?.fields?.validationErrorMessage?.value}`,
                                  },
                                }),
                              }}
                              placeholder={item?.fields?.placeholderText?.value}
                              isRequiredField={isRequired}
                              errors={errors[`${name}`]}
                              handleInputChange={handleInputChange}
                            />
                          );
                        case 'richtext':
                          return (
                            <TextAreaBox
                              Label={label}
                              type="text"
                              name={name}
                              placeholder={item?.fields?.placeholderText?.value}
                              reg={{
                                ...register(`${name}`, {
                                  required: isRequired
                                    ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                    : '',
                                  maxLength: item?.fields?.maxLength?.value,
                                  pattern: {
                                    value: item?.fields?.validationRegex?.value,
                                    message: `${item?.fields?.validationErrorMessage?.value}`,
                                  },
                                }),
                              }}
                              isRequiredField={isRequired}
                              errors={errors[`${name}`]}
                              handleInputChange={handleInputChange}
                            />
                          );
                        case 'hidden':
                          return (
                            <TextBox
                              type="hidden"
                              name={name}
                              reg={{
                                ...register(`${name}`, {
                                  value: item?.fields?.placeholderText?.value,
                                }),
                              }}
                              value={item?.fields?.placeholderText?.value}
                            />
                          );
                        default:
                          return '';
                      }
                    })}
                  {showLoader && <OverlayLoader />}
                  <div className="cta">
                    {showMsg && (
                      <div id="hubspotForm" className="hs-form-wrapper form-block">
                        <div className="hs-main-font-element ">
                          <RichTextSitecoreField field={fields?.successMessage} />
                        </div>
                      </div>
                    )}
                    {showErrMsg && (
                      <div className="form-submit-error-msg hs-main-font-element">
                        <RichTextSitecoreField field={fields?.errorMessage} />
                      </div>
                    )}
                    {/* <Button text="Download the Report" variant="primary-button" cssClass="violet" arrow /> */}
                    <button
                      className={`button primary-button ${backgroundValue} icon__right signpost`}
                      type="submit"
                      {...ButtonDataAttributes({
                        name: componentName,
                        text: `${fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}`,
                        variant: 'primary-button',
                        arrow: true,
                        dataItemName: fields?.Header?.value || 'GatedForm',
                      })}
                    >
                      {fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}
                      <div className="arrow-icon"></div>
                    </button>
                  </div>
                  <div className="privacy-policy">
                    <Checkbox
                      id="hubspot-gatedform-check"
                      text={<RichTextSitecoreField field={fields?.consentText} />}
                      onClick={(e) => {
                        setCbCheck(e.target.checked);
                      }}
                      className={backgroundValue}
                    />
                    {cbError && <span className="error-message">{cbError}</span>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HubSpotGatedInsightForm.defaultProps = {
  uid: '',
  componentName: '',
  dataSource: '',
  params: {},
  fields: {
    successMessage: {},
    firstColumnFormFields: [],
    formBackGroundColor: {},
    errorMessage: {},
    Header: {},
    consentText: {},
    Description: {},
    hubspotFormId: {},
    hubspotPortalId: {},
    isConsentRequired: {},
    ctaLink: {},
    ctaIcon: {},
    ctaIconPosition: null,
    ctaArrow: {},
    ctaText: {},
    ctaStyle: {},
  },
};

HubSpotGatedInsightForm.propTypes = {
  Header: PropTypes.string,
  Description: PropTypes.string,
  form: PropTypes.object,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.object,
  align: PropTypes.object,
  mode: PropTypes.object,
};

export default HubSpotGatedInsightForm;
