import React from 'react';
import ActionBarSocial from '../ActionBarSocial/index';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import PropTypes from 'prop-types';
import FooterLanguageSelector from '../SiteFooter/FooterLanguageSelector';

/**
 * Renders the social connect section in the footer.
 *
 * @param {Object} props - The props object containing fields and rendering data.
 * @param {Object} props.fields - The fields object containing CopyRightsText, GlobalIcon, and LinksCollection.
 * @param {Object} props.rendering - The rendering object containing componentName.
 * @param {string} props.rendering.componentName - The name of the component being rendered.
 * @returns {JSX.Element} - The JSX element representing the social connect section in the footer.
 */
function FooterSocialConnect(props) {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { CopyRightsText, GlobalIcon, LinksCollection, countriesCtaLink } = fields;
  return (
    <div className="footer-bottom-links" {...ComponentDataAttributes(componentName)}>
      <div className="bdr-top"></div>
      <div className="row footer-bottom">
        <div className="col-xl-4 col-lg-4 col-sm-12 copyright">
          <p>
            <TextSitecoreField field={CopyRightsText} />
          </p>
        </div>
        <div className="col-xl-4 col-lg-5 col-sm-12 social-follow justify-center">
          <ActionBarSocial props={LinksCollection} />
        </div>
        <div className="col-xl-4 col-lg-3 col-sm-12 footer-lang-selector text-right">
          <FooterLanguageSelector icon={GlobalIcon} countriesCtaLink={countriesCtaLink} />
        </div>
      </div>
    </div>
  );
}

FooterSocialConnect.defaultProps = {
  fields: {},
};
FooterSocialConnect.propTypes = {
  fields: PropTypes.shape({}),
};
export default FooterSocialConnect;
