import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import BreadcrumbClass from '../../core/Breadcrumb/Breadcrumb';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

import html2canvas from 'html2canvas';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { isEmpty } from 'lodash';
import Button from '../../../globals/buttons/Button';
import { isSCExperienceEditorActive } from '../../../utils/HelperUtils';

const BannerStandard = ({ variant, ...props }) => {
  const {
    Breadcrumb = [],
    fields = {},
    rendering: { componentName },
  } = props;

  const {
    Content,
    backgroundColor,
    backgroundImage,
    backgroundImageMobile,
    ctaIconPosition,
    ctaLink,
    ctaStyle,
    ctaText,
    frostedGlass,
    optCtaIconPosition,
    optCtaLink,
    optCtaStyle,
    optCtaText,
    optShowCTAIcon,
    showCTAIcon,
    title,
    textColor,
    frostedGlassColor,
    downloadCTAButton,
  } = fields;
  const isDownload = downloadCTAButton?.value;
  const breadcrumbItems =
    Breadcrumb && Array.isArray(Breadcrumb) && Breadcrumb[0]?.props?.fields?.data?.item;
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  const downloadPdfDocument = (rootElementId, downloadFileName) => {
    if (!isSCExperienceEditorActive) {
      const input = document.getElementById(rootElementId);
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        import('jspdf').then((jsPDF) => {
          const doc = new jsPDF.jsPDF('pt', 'mm', 'a4', true);
          let position = 0;
          doc.addImage(imgData, 'JPEG', 10, 0, imgWidth, imgHeight + 20);
          heightLeft -= pageHeight;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'JPEG', 10, position, imgWidth, imgHeight + 20);
            heightLeft -= pageHeight;
          }

          doc.save(`${downloadFileName}.pdf`);
        });
      });
    }
  };

  return (
    <section
      className={`banner-section PageBannerL2 standard banner banner__${variant} ${
        backgroundColor?.fields?.text
      } ${
        frostedGlass?.value === true &&
        !isEmpty(frostedGlassColor?.fields?.text?.value) &&
        variant === 'solutions'
          ? 'glass'
          : ''
      }`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageMedium={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageLarge={backgroundImage}
        imageXLarge={backgroundImage}
      />
      <div className={`${isMobileScreen ? '' : 'container-xl'} PageBannerL2-container`}>
        <div className="row content-wrapper">
          <div
            className={`col-xl-8 col-lg-7 col-md-7 ${
              isMobileScreen
                ? ''
                : frostedGlass?.value === true &&
                  !isEmpty(frostedGlassColor?.fields?.text?.value) &&
                  (frostedGlassColor?.fields?.text?.value === 'light'
                    ? 'frost frost__light'
                    : 'frost frost__dark')
            }`}
          >
            {breadcrumbItems && (
              <BreadcrumbClass
                items={breadcrumbItems}
                cssClass={`${
                  textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
                componentName={componentName}
                // textColor={`${
                //   textColor?.fields?.text?.value === 'white' ? 'secondary' : 'primary'
                // }`}
              />
            )}

            <h1
              className={`title ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              }`}
            >
              <TextSitecoreField field={title} />
            </h1>

            <div
              className={`sub-title ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              }`}
            >
              <RichTextSitecoreField field={Content} />
            </div>

            <div className="cta__wrap">
              {!isDownload ? (
                ctaLink &&
                ctaText &&
                !isEmpty(ctaText?.value) &&
                !isEmpty(ctaLink?.value?.href) && (
                  <ButtonHref
                    text={<TextSitecoreField field={ctaText} />}
                    href={
                      !isEmpty(ctaLink?.value?.href) || !isEmpty(ctaLink?.value?.anchor)
                        ? ctaLink?.value?.href + ctaLink?.value?.anchor
                        : ctaLink?.value?.href
                    }
                    cssClass={!isMobileScreen ? textColor?.fields?.text?.value : 'black'}
                    variant={ctaStyle ? ctaStyle?.fields?.text?.value : 'primary-button'}
                    arrow={showCTAIcon ? showCTAIcon.value : false}
                    linkType={ctaLink?.value?.linktype}
                    linkFields={ctaLink}
                    iconPosition={
                      ctaIconPosition?.fields?.text?.value === 'right'
                        ? 'icon__right'
                        : 'icon__left'
                    }
                    dataItemName={title?.value || componentName}
                    componentName={componentName}
                  />
                )
              ) : (
                <Button
                  text={<TextSitecoreField field={ctaText} />}
                  cssClass={!isMobileScreen ? textColor?.fields?.text?.value : 'black'}
                  variant={ctaStyle ? ctaStyle?.fields?.text?.value : 'primary-button'}
                  arrow={showCTAIcon ? showCTAIcon.value : false}
                  linkType={ctaLink?.value?.linktype}
                  linkFields={ctaLink}
                  iconPosition={
                    ctaIconPosition?.fields?.text?.value === 'right' ? 'icon__right' : 'icon__left'
                  }
                  dataItemName={title?.value || componentName}
                  componentName={componentName}
                  BtnFunction={() => {
                    downloadPdfDocument('FramDataBlock_Container', title?.value);
                  }}
                />
              )}
              {optCtaLink &&
                optCtaText &&
                !isEmpty(optCtaText?.value) &&
                !isEmpty(optCtaLink?.value?.href) && (
                  <ButtonHref
                    text={<TextSitecoreField field={optCtaText} />}
                    href={optCtaLink?.value?.href}
                    cssClass={
                      isMobileScreen && !isEmpty(textColor)
                        ? 'black'
                        : textColor?.fields?.text?.value
                    }
                    variant={optCtaStyle ? optCtaStyle?.fields?.text?.value : 'text-button'}
                    arrow={optShowCTAIcon ? optShowCTAIcon?.value : false}
                    linkType={optCtaLink?.value?.linktype}
                    linkFields={optCtaLink}
                    iconPosition={
                      optCtaIconPosition?.fields?.text?.value === 'right'
                        ? 'icon__right'
                        : 'icon__left'
                    }
                    dataItemName={title?.value || componentName}
                    componentName={componentName}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const BannerStandardL2 = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'Breadcrumb' }])(BannerStandard)
);

BannerStandard.defaultProps = {
  variant: 'solutions',
  BreadCrumb: [],
  fields: {
    Content: {},
    backgroundColor: {},
    textColor: {
      fields: {
        text: {
          value: 'black',
        },
      },
    },
    backgroundImage: {},
    backgroundImageMobile: {},
    ctaIconPosition: {},
    ctaLink: {},
    ctaStyle: {},
    ctaText: {},
    downloadCTAButton: {},
    frostedGlass: {},
    frostedGlassColor: {},
    optCtaIconPosition: {},
    optCtaLink: {},
    optCtaStyle: {},
    optCtaText: {},
    optShowCTAIcon: {},
    showCTAIcon: {},
    title: {},
  },
};

BannerStandard.propTypes = {
  BreadCrumb: PropTypes.array,
  variant: PropTypes.string,
  fields: PropTypes.shape({
    Content: PropTypes.shape({}),
    backgroundColor: PropTypes.shape({}),
    backgroundImage: PropTypes.shape({}),
    backgroundImageMobile: PropTypes.shape({}),
    ctaIconPosition: PropTypes.shape({}),
    ctaLink: PropTypes.shape({}),
    ctaStyle: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    downloadCTAButton: PropTypes.shape({}),
    frostedGlass: PropTypes.shape({}),
    optCtaIconPosition: PropTypes.shape({}),
    optCtaLink: PropTypes.shape({}),
    optCtaStyle: PropTypes.shape({}),
    optCtaText: PropTypes.shape({}),
    optShowCTAIcon: PropTypes.shape({}),
    showCTAIcon: PropTypes.shape({}),
    title: PropTypes.shape({}),
  }),
};

export default BannerStandardL2;
