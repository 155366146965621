import React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Routes, useParams } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import { onetrustCountryCode } from './constants/index.js';
import {
  getBrowserWindow,
  getCountryLanguageFromCookie,
  getGlobalLanguageFromCookie,
  getLanguageConfig,
  isBrowserDocumentAvailable,
  getCountryLangForOT,
} from './utils/HelperUtils';
import ErrorBoundary from './components/core/ErrorBoundary/ErrorBoundary';
import { Helmet } from 'react-helmet';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /pat

export const parseRouteParams = (url) => {
  // TODO: If needed fetch just country from url.
  const { country, language } = getLanguageConfig(url);
  let languageToSC =
    getCountryLanguageFromCookie(country) || getGlobalLanguageFromCookie() || language || 'en';
  // const route = url.replace(LANGUAGE_REG_EXP, '');
  const route = url;
  return {
    route: route.startsWith('/') ? route : `/${route}`,
    language: languageToSC,
  };
};

const JssRoute = (props) => {
  const params = useParams();
  const url = params['*'];

  return <RouteHandler {...parseRouteParams(url)} url={url} isSSR={props.isSSR} />;
};
// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  componentDidMount() {
    const globalLang = getGlobalLanguageFromCookie();
    if (globalLang && window.location.pathname === '/') {
      window.location = `/${globalLang}`;
    }
  }

  render() {
    const { path, Router, graphQLClient } = this.props;
    // const lang = getCountryLangForOT(path); // commented once we gor confirmation from szymon.rutkowski about one trust codes need to un comment and comment the below line
    const lang = parseRouteParams(path).language;
    const languageMap = onetrustCountryCode; // please add one trust country code in constants/index.js if any special country missed one trust code
    const dataLanguage = languageMap[lang] || lang;
    return (
      <>
        {/* OneTrust Cookies Consent Notice start for mpgtalentsolutions.com  */}
        <Helmet>
          <script
            type={'text/javascript'}
            src={'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'}
            data-domain-script={`${process.env.REACT_APP_SITECORE_ONETRUST_KEY}`}
            data-language={dataLanguage}
          ></script>
          {getBrowserWindow && isBrowserDocumentAvailable && (
            <script type={'text/javascript'}>
              {`function OptanonWrapper() {                
               
                document.addEventListener('click', function (e) {
                  if (e.target && e.target.id == 'ot-sdk-btn') {
                    window?.Optanon?.ToggleInfoDisplay();
                  }
                });
                
            }`}
            </script>
          )}
        </Helmet>
        {/*  OneTrust Cookies Consent Notice end for mpgtalentsolutions.com */}

        <ApolloProvider client={graphQLClient}>
          <ErrorBoundary>
            <SitecoreContext componentFactory={componentFactory} layoutData={this.props.ssrState}>
              <Router location={path} context={{}}>
                <Routes>
                  <Route path="*" element={<JssRoute isSSR={!!this.props.ssrState} />} />
                </Routes>
              </Router>
            </SitecoreContext>
          </ErrorBoundary>
        </ApolloProvider>
        <button
          id="ot-sdk-btn"
          className="ot-sdk-show-settings cookie-btn-style"
          style={{ display: 'none' }}
        ></button>
      </>
    );
  }
}

export default AppRoot;
