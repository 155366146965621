export const GTMDataItemType = Object.freeze({
  COMPONENT: 'component',
  BUTTON: 'button',
  LINK: 'link',
  NAVIGATION_TOP: 'nav-top',
  NAVIGATION_FOOTER: 'nav-footer',
  NAVIGATION_OTHER: 'nav-other',
  BREADCRUMB: 'breadcrumb',
  TAG: 'tag',
  NAVIGATION_FOOTER_SOCIAL: 'nav-footer-social',
});
const GTMDataAttributeType = Object.freeze({
  dataItemType: 'data-item-type',
  dataItemName: 'data-item-name',
  dataComponentName: 'data-component-name',
  dataNavItemLevel: 'data-nav-item-level',
  dataNavItemName: 'data-nav-item-name',
  dataLinkText: 'data-link-text',
  dataLinkImageUrl: 'data-link-image-url',
  dataTagIconUrl: 'data-tag-icon-url',
  dataButtonText: 'data-button-text',
  dataButtonArrow: 'data-button-arrow',
  dataButtonVariant: 'data-button-variant',
});

export const ComponentDataAttributes = (name) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.COMPONENT,
  [GTMDataAttributeType.dataComponentName]: name,
});
export const ButtonDataAttributes = ({ name, text, arrow, variant, dataItemName = '' }) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.BUTTON,
  [GTMDataAttributeType.dataItemName]: dataItemName,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataButtonText]: text,
  [GTMDataAttributeType.dataButtonArrow]: arrow,
  [GTMDataAttributeType.dataButtonVariant]: variant,
});
export const NavTopDataAttributes = ({
  name,
  itemLevel = 'L0',
  dataNavItemName,
  dataLinkText,
  dataLinkImageUrl = '',
}) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.NAVIGATION_TOP,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataNavItemLevel]: itemLevel,
  [GTMDataAttributeType.dataNavItemName]: dataNavItemName,
  [GTMDataAttributeType.dataLinkText]: dataLinkText,
  [GTMDataAttributeType.dataLinkImageUrl]: dataLinkImageUrl,
});

export const OtherLinkDataAttributes = ({ name, text, url, dataItemName = '' }) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.LINK,
  [GTMDataAttributeType.dataItemName]: dataItemName,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataLinkText]: text,
  [GTMDataAttributeType.dataLinkImageUrl]: url,
});

export const OtherNavDataAttributes = ({ name, text, url, dataItemName = '' }) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.NAVIGATION_OTHER,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataItemName]: dataItemName,
  [GTMDataAttributeType.dataLinkText]: text,
  [GTMDataAttributeType.dataLinkImageUrl]: url,
});

export const NavigationSocialLinkFooterDataAttributes = ({
  name,
  dataNavItemLevel = 'L0',
  dataNavItemName,
  linkText,
  imageUrl,
}) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.NAVIGATION_FOOTER_SOCIAL,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataNavItemLevel]: dataNavItemLevel,
  [GTMDataAttributeType.dataNavItemName]: dataNavItemName,
  [GTMDataAttributeType.dataLinkText]: linkText,
  [GTMDataAttributeType.dataLinkImageUrl]: imageUrl,
});

export const NavigationFooterDataAttributes = ({
  name,
  dataNavItemLevel,
  dataNavItemName,
  linkText,
  url,
}) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.NAVIGATION_FOOTER,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataNavItemLevel]: dataNavItemLevel,
  [GTMDataAttributeType.dataNavItemName]: dataNavItemName,
  [GTMDataAttributeType.dataLinkText]: linkText,
  [GTMDataAttributeType.dataLinkImageUrl]: url,
});

export const BreadcrumbDataAttributes = ({ name, dataItemName = '', linkText }) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.BREADCRUMB,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataItemName]: dataItemName,
  [GTMDataAttributeType.dataLinkText]: linkText,
});

export const TagDataAttributes = ({ name, dataItemName = '', linkText, tagIconUrl }) => ({
  [GTMDataAttributeType.dataItemType]: GTMDataItemType.TAG,
  [GTMDataAttributeType.dataComponentName]: name,
  [GTMDataAttributeType.dataItemName]: dataItemName,
  [GTMDataAttributeType.dataLinkText]: linkText,
  [GTMDataAttributeType.dataTagIconUrl]: tagIconUrl,
});
