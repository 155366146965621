import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SearchCardArticleTags from '../DetailsBlocks/ArticleTags';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import LocationGlobeBlack from '../../../../src/static/images/icon-location-global-black.svg';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags } from '../../../utils/HelperUtils';
import './searchResultCard.scss';

export default function SearchResultCard({
  id,
  title,
  description,
  descBullets,
  date,
  relevance,
  popularity,
  type,
  solutions,
  topics,
  tags,
  location,
  jobType,
  eventTime,
  cardCTA,
  item,
  fields,
}) {
  const SearchResultsCardRef = useRef(null);

  // convert the date from "YYYY-MM-DD" to LocaleString
  const dateString = date;
  const newDate = new Date(dateString);
  const formattedDate = newDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const handleCardClick = (cardCTA) => {
    // alert('Card Clicked - Navigate to ' + cardCTA);
  };

  return (
    <>
      <a
        href={cardCTA}
        className="search_result_card_link"
        // onClick={(event) => {
        //   event.preventDefault();
        //   // handleCardClick(cardCTA);
        // }}
      >
        <div
          ref={SearchResultsCardRef}
          className={['search_results_card', type ? type : 'standard_card'].join(' ')}
        >
          <div className="row">
            <div className="col-lg-12">
              <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
            </div>
            <div className="col-lg-12">
              <div className="card-body">
                {type === 'webinar' || type === 'podcast' ? (
                  <div className="pre_title">{topics[0]}</div>
                ) : null}
                <div className="title">{title}</div>
                {type === 'careers' || type === 'event' ? (
                  <>
                    <div className="job_information">
                      <div className="location">
                        <img src={LocationGlobeBlack} alt="location icon"></img>
                        {location}
                      </div>
                      {type === 'careers' && <div className="jobtype">{jobType}</div>}
                      {type === 'event' && <div className="event_time">{eventTime}</div>}
                    </div>
                  </>
                ) : null}
                <div className="card-description">
                  {type === 'solution' ? (
                    descBullets && (
                      <ul className={[`descBullets ${type}`].join(' ')}>
                        {descBullets.map((item, index) => (
                          <li key={'descBullets' + index}>{item.text}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    // <p>{description}</p>
                    <p>
                      <RichTextSitecoreField field={{ value: description }} />
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  );
}

SearchResultCard.defaultProps = {
  id: 0,
  title: '',
  description: '',
  date: '',
  solutions: [],
  topics: [],
  tags: [],
  type: '',
  cardCTA: '',
};

SearchResultCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  descBullets: PropTypes.array,
  date: PropTypes.string.isRequired,
  relevance: PropTypes.number.isRequired,
  popularity: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  solutions: PropTypes.array,
  topics: PropTypes.array,
  tags: PropTypes.array,
  location: PropTypes.string,
  jobType: PropTypes.string,
  eventTime: PropTypes.string,
  cardCTA: PropTypes.string.isRequired,
};
