import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi, Link as JssLink, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { ButtonDataAttributes } from '../../constants/gtm';
import { checkJSSObject, checkHref } from '../../utils/HelperUtils';
import { isObject } from 'lodash';

const ButtonHref = (props) => {
  const {
    cssClass,
    text,
    fontSize,
    variant,
    icon,
    iconPosition,
    arrow,
    href,
    linkType = 'internal',
    linkFields,
    BtnFunction,
    componentName = '',
    dataItemName = '',
    addMoreClass = '',
  } = props;
  // const isJSSObject = checkJSSObject(href);
  const ButtonContent = () => (
    <div
      className={`button ${addMoreClass} ${variant.toLowerCase()} ${cssClass} ${fontSize} ${iconPosition} ${
        arrow ? 'signpost' : ''
      }`}
      onClick={BtnFunction}
      {...ButtonDataAttributes({
        name: componentName,
        text: isObject(text) ? text?.props?.field?.value : text?.value || text,
        variant,
        arrow,
        dataItemName: isObject(dataItemName)
          ? dataItemName?.props?.field?.value
          : dataItemName?.value || dataItemName,
      })}
      // {...accessibleOnClick(BtnFunction)}
    >
      {icon && !arrow && (
        <img src={mediaApi.updateImageUrl(icon)} className={`icon ${iconPosition}`} alt={text} />
      )}
      {arrow && !icon && <div className="arrow-icon"></div>}
      {text}
    </div>
  );
  const isJSSObject = checkJSSObject(href);
  const isHrefVlaue = isJSSObject ? checkHref(href) : href;
  const ButtonLink =
    linkType === 'internal' ? (
      <Link
        className="buttonhref-anchor"
        to={isHrefVlaue}
        target={linkFields?.value?.target || linkFields?.target}
      >
        <ButtonContent />
      </Link>
    ) : (
      <JssLink className="buttonhref-anchor" field={linkFields}>
        <ButtonContent />
      </JssLink>
    );

  return ButtonLink;
};

ButtonHref.defaultProps = {
  text: '',
  dataItemName: '',
  fontSize: 'medium',
  cssClass: '',
  icon: '',
  iconPosition: 'icon__right',
  variant: 'primary-button',
  arrow: false,
  href: '',
};

ButtonHref.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  dataItemName: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  /** Enabled for SignPost CTA */
  arrow: PropTypes.bool,

  /** Size of Button Text */
  fontSize: PropTypes.oneOf(['medium', 'small']),

  /** Icon Source and Position */
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['icon__left', 'icon__right']),

  /** Button Color */
  cssClass: PropTypes.string,

  /** Solid, Outline or Text */
  variant: PropTypes.oneOf(['primary-button', 'secondary-button', 'text-button', 'nav-button']),

  href: PropTypes.string,
};

export default withSitecoreContext()(ButtonHref);
