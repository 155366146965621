import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';

const SidebarCardBiography = ({ title, img, items }) => (
  <div className="sidebar__bio">
    {img && (
      <div className="image-wrapper">
        <ImageSitecoreField field={img} />
      </div>
    )}
    <div className="title">{title}</div>
    {items &&
      items?.map((item) => (
        <a key={item.id} href={item?.fields?.ctaLink?.value?.url} className="item">
          <div className="text">{item?.fields?.ctaText?.value}</div>
        </a>
      ))}
  </div>
);

SidebarCardBiography.defaultProps = {
  title: '',
  img: '',
  altText: '',
  items: [],
};

SidebarCardBiography.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  altText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SidebarCardBiography;
