import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import './searchBar.scss';
import {
  getBrowserWindow,
  isBrowserDocumentAvailable,
  isSCExperienceEditorActive,
  sitecoreHostUrl,
} from '../../../../utils/HelperUtils';
import { fetchSearchResult } from '../../../../service/index';
import { ButtonDataAttributes } from '../../../../constants/gtm';

// eslint-disable-next-line react/display-name
const SearchBar = React.forwardRef(
  (
    {
      scrolled,
      setSearchOptions,
      setIsSearchSuggestions,
      setHeaderSuggestions,
      setHearderSearchData,
      handleClickAway,
      showSearch,
      setShowSearch,
      isExpanded,
      setIsExpanded,
      mobNavBtn,
      setMobNavBtn,
      mobMenuShow,
      searchButtonText,
      setsearchQuery,
      ...props
    },
    ref
  ) => {
    const [suggestionRes, setsuggestionRes] = useState([]);
    const searchButtonRef = useRef();
    const { componentName = 'SiteHeader' } = props;
    const [query, setQuery] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [searchQueryState, setsearchQueryState] = useState('');
    const location = useLocation();

    const navigate = useNavigate();
    const queryParams = getBrowserWindow ? new URLSearchParams(location.search) : '';

    useImperativeHandle(ref, () => ({
      focusSearchButton: () => {
        if (searchButtonRef.current) {
          searchButtonRef.current.focus();
        }
      },
    }));

    const handleIconClick = () => {
      setIsExpanded(!isExpanded);
      setSearchOptions(!isExpanded);
      setShowSearch(false);
      setIsSearchSuggestions(false);
      setQuery('');
      setsearchQuery('');
      mobNavBtn && setMobNavBtn(!mobNavBtn);
    };
    const handleInputChange = async (event) => {
      event.persist();
      const changedValue = event.target.value.replace(/[^a-zA-Z0-9"*\s]/g, '');
      const value = changedValue.replace(/"/g, '"');
      setQuery(changedValue);
      setsearchQuery(value);
      setIsTyping(true);
      if (value.length >= 3) {
        const result = await fetchSearchResult('', 10, 0, 'en', false, true, value);
        const searchResultApi = result?.data?.searchResult ? result?.data?.searchResult : [];
        const suggestionData = searchResultApi.map((item) => {
          return item.title;
        });
        setIsSearchSuggestions(true);
        setShowSearch(true);

        const filteredSuggestions = suggestionData.filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
        const highlightedSuggestions = filteredSuggestions.map((suggestion) => {
          const highlightedSuggestion = highlightMatch(suggestion, value);
          return highlightedSuggestion;
        });
        setHeaderSuggestions(highlightedSuggestions);
        setHearderSearchData(suggestionData);
        return suggestionData;
      } else if (value.length === 0) {
        setShowSearch(false);
      } else {
        setIsSearchSuggestions(false);
        setHeaderSuggestions([]);
        setHearderSearchData([]);
      }
    };

    const handleQueryBlur = () => {
      setIsTyping(false);
    };

    const handleSearchClick = (query) => {
      if (query !== '') {
        queryParams.set('search', query);
        const newSearch = `?${queryParams.toString()}`;

        // Define a callback function to push the event before navigation
        const pushEventAndNavigate = () => {
          if (!isSCExperienceEditorActive && getBrowserWindow) {
            window.dataLayer &&
              window.dataLayer.push({
                event: 'search',
                search_term: query,
              });
          }

          navigate({ pathname: '/site-search', search: newSearch });
        };
        // Check if the current location is '/site-search'
        if (location?.pathname === '/site-search') {
          navigate(0, { state: { pushEventAndNavigate } });
        } else {
          pushEventAndNavigate();
        }
      }
    };

    useEffect(() => {
      if (isBrowserDocumentAvailable) {
        document?.addEventListener('click', handleClickAway);
        return () => document?.removeEventListener('click', handleClickAway);
      }
    }, []);

    // highlight the matching text
    const highlightMatch = (text, value) => {
      // Add value parameter
      if (typeof text !== 'string') {
        return text;
      }

      const queryWithoutSpaces = value.replace(/\s/g, '');
      const queryRegex = new RegExp(`(${queryWithoutSpaces.split('').join('\\s*')})`, 'gi');
      const matches = text.toLowerCase().match(queryRegex);
      if (matches) {
        const parts = text.split(queryRegex);
        return (
          <>
            {parts &&
              parts?.map((part, index) =>
                matches.includes(part.toLowerCase()) ? (
                  <span key={index} className="highlight">
                    {part.replace(new RegExp(queryWithoutSpaces, 'gi'), (match) => `${match}`)}
                  </span>
                ) : (
                  part
                )
              )}
          </>
        );
      }
      return text;
    };

    // hide when click away
    const searchBarRef = useRef(null);

    return (
      <div
        ref={searchBarRef}
        className={`searchBarWrapper ${mobMenuShow || scrolled ? 'white__background' : ''}`}
      >
        <div className={`searchBarInputWrapper ${isExpanded ? 'expanded' : ''} `}>
          <div
            className={`searchBarInput ${isExpanded ? 'expanded' : ''} ${
              showSearch ? 'close_visible' : ''
            } `}
          >
            <input
              type="text"
              value={query}
              aria-label="Search"
              placeholder="What are you searching for?"
              onChange={handleInputChange}
              onKeyDown={props.handleSearch}
              onBlur={handleQueryBlur}
            />
            {showSearch && (
              <button
                ref={searchButtonRef}
                className="searchBarBtnSearch"
                onClick={() => {
                  if (!isSCExperienceEditorActive) {
                    handleSearchClick(query);
                  }
                }}
              >
                {searchButtonText?.value}
              </button>
            )}
            <button className="searchBarBtnClose desktop" onClick={handleIconClick}>
              Close
            </button>
          </div>

          {isExpanded ? (
            <>
              <button
                className="searchBarButton"
                onClick={() => handleSearchClick(query)}
                {...ButtonDataAttributes({
                  name: componentName,
                  text: '',
                  variant: 'icon',
                  arrow: false,
                  dataItemName: '',
                })}
              >
                {searchButtonText?.value}
              </button>

              <button
                className="searchBarBtnClose mobile"
                onClick={handleIconClick}
                {...ButtonDataAttributes({
                  name: componentName,
                  text: '',
                  variant: 'icon',
                  arrow: false,
                  dataItemName: '',
                })}
              >
                Mobile Toggle
              </button>
            </>
          ) : (
            <button
              className="searchBarButton"
              onClick={handleIconClick}
              {...ButtonDataAttributes({
                name: componentName,
                text: '',
                variant: 'icon',
                arrow: false,
                dataItemName: '',
              })}
            >
              {searchButtonText?.value}
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default SearchBar;

SearchBar.propTypes = {
  scrolled: PropTypes.bool,
  onSearch: PropTypes.func,
  setSearchOptions: PropTypes.func,
  setIsSearchSuggestions: PropTypes.func,
  setHeaderSuggestions: PropTypes.func,
  setHearderSearchData: PropTypes.func,
  handleClickAway: PropTypes.func,
  showSearch: PropTypes.bool,
  setShowSearch: PropTypes.func,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  mobNavBtn: PropTypes.bool,
  setMobNavBtn: PropTypes.func,
  mobMenuShow: PropTypes.bool,
};
