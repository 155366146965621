import React from 'react';
import i18n from 'i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { layoutServiceFactory } from './lib/layout-service-factory';
import config from './temp/config';
import Layout from './Layout';
import { get } from 'lodash';
import {
  getBrowserWindow,
  getCountryLanguageFromCookie,
  getGlobalLanguageFromCookie,
  getLanguageConfig,
  isBrowserDocumentAvailable,
  isSCExperienceEditorActive,
} from './utils/HelperUtils';
import OverlayLoader from './components/core/ErrorBoundary/OverlayLoader';
import { globalSiteRedirectionMap } from './constants/index.js';

/* eslint-disable no-console */

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.
class RouteHandler extends React.Component {
  state = {
    loadedGTM: false,
    loading: false,
    loadedRouteData: false,
    notFound: true,
    defaultLanguage: config?.defaultLanguage,
  };
  constructor(props) {
    super(props);
  }

  /**
   * Pushes data to the dataLayer object for Google Tag Manager.
   * @param {Object} routeData - The route data object containing information about the current route.
   */
  pushDataLayer = (routeData = {}, isSSR = false) => {
    if (!isSCExperienceEditorActive) {
      const routeFields = isSSR
        ? get(routeData, 'sitecoreContext.route.fields', {})
        : get(routeData, 'sitecore.route.fields', {});
      const { pageCategory = '', Title = '' } = routeFields;
      const context = get(routeData, 'sitecore.context', {});
      const { language = '' } = context;
      const { country } = getLanguageConfig(window?.location?.pathname);
      let lang = getCountryLanguageFromCookie(country) || getGlobalLanguageFromCookie();
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'page_load',
          page_path: `${window?.location?.pathname}`,
          page_url: `${window?.location?.href}`,
          page_title: Title?.value,
          page_category: pageCategory?.value,
          language: language,
          region: country || 'Global',
          preferences_language: lang || 'en',
          preferences_region: country,
        });
      }
    }
  };

  addGTMScriptFromPageScriptSitecoreRes = (routeData, isSSR = false) => {
    const pageScripts = isSSR
      ? get(routeData, 'sitecoreContext.route.fields.pageScript', [])
      : get(routeData, 'sitecore.route.fields.pageScript', []);
    // remove HTML comments <!-->
    // const regex = /<!--(.*?)-->/g;
    pageScripts &&
      Array.isArray(pageScripts) &&
      pageScripts?.length > 0 &&
      pageScripts.forEach((pageScript, index) => {
        let scriptToLoad = get(pageScript, 'fields.pageScript.value', '');
        const loadInHead = get(pageScript, 'fields.addScriptInHeadTag.value', false);
        if (scriptToLoad) {
          if (isBrowserDocumentAvailable) {
            if (loadInHead) {
              let script = new DOMParser().parseFromString(
                scriptToLoad.replace(/^"'|'"$/gi, ''),
                'text/html'
              );
              script.id = `gtm-iframe-${index}`;
              document?.head?.appendChild(script.body.firstElementChild);
            } else {
              let script2 = document?.createElement('script');
              script2.text = scriptToLoad.replace(/(<([^>]+)>)/gi, '').replace(/^"|"$/gi, '');
              script2.id = `gtm-body-script-${index}`;
              script2.type = 'text/javascript';
              document?.body?.appendChild(script2);
            }
          }
        }
        if (index === pageScripts.length - 1) {
          this.setState({ loadedGTM: true }, () => {
            this.pushDataLayer(routeData, isSSR);
          });
        }
      });
  };

  componentDidMount() {
    // Adding GTM in componentDidMount if isSSR - (if node proxy)
    if (this.props.isSSR) {
      const layoutData = this.props;
      this.addGTMScriptFromPageScriptSitecoreRes(layoutData, this.props.isSSR);
    } else {
      this.updateLayoutData();
    }
  }

  // componentDidCatch() {}

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  /**
   * Updates the layout data for the current route.
   * @function
   * @name updateLayoutData
   * @memberof RouteHandler
   * @instance
   * @returns {void}
   */
  updateLayoutData() {
    const language = this.getLanguage();

    // instantiate the dictionary service.
    const layoutServiceInstance = layoutServiceFactory.create();

    if (!isSCExperienceEditorActive) {
      this.setState({ loading: true });
    }

    // get the route data for the new route
    layoutServiceInstance
      .fetchLayoutData(this.props.route, language)
      .then((routeData) => {
        if (!isSCExperienceEditorActive) {
          this.setState({ loadedRouteData: true });
          if (!this.state.loadedGTM) {
            this.addGTMScriptFromPageScriptSitecoreRes(routeData, this.props.isSSR);
          } else {
            this.pushDataLayer(routeData);
          }
          this.props.updateSitecoreContext(routeData);
        }
      })
      .catch((e) => {
        console.error('Route data fetch error', e);
      })
      .finally(() => {
        if (!isSCExperienceEditorActive) {
          this.setState({ loading: false });
        }
      });
  }

  /**
   * Returns the language from the props or sitecoreContext or defaultLanguage.
   * @returns {string} The language.
   */
  getLanguage() {
    return this.props.language || this.props.sitecoreContext.language || config.defaultLanguage;
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.getLanguage();

    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
    }

    this.props.updateSitecoreContext({ language: newLanguage });
  }

  /**
   * Updates the component when the props change.
   * @param {Object} previousProps - The previous props object.
   */
  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.url;
    const newRoute = this.props.url;

    // if in Sitecore editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isSCExperienceEditorActive) {
      // getBrowserWindow && window?.location?.assign(newRoute);
      return;
    }

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    this.updateLanguage();
    this.updateLayoutData();
  }

  /**
   * Renders the appropriate component based on the current route and state.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { loading } = this.state;
    const layoutData = this.props.sitecoreContext;
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    // `route` is null in case if route is not found
    if (layoutData?.route === null) {
      let lang = layoutData?.language;
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          // Parse language from the URL
          const urlLanguage = window.location.pathname?.split('/');
          if (!lang.includes('-') && !globalSiteRedirectionMap[urlLanguage[1]]) {
            // Combine both URL language to form the new language
            lang = urlLanguage[2] + '-' + urlLanguage[1].toLowerCase();
          }
          if (lang.includes('-')) {
            // Split lang into parts using the hyphen
            const langParts = lang.split('-');
            // Use the first part as the language code and the second part as the country code
            const simplifiedLang = langParts[1].toLowerCase() + '/' + langParts[0].toLowerCase();
            // Update lang with the simplified format
            lang = simplifiedLang;
          }
          /** Redirecting to 404 page. */
          window.location.pathname = lang + '/404-page';
        }
      }
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (
      (!isSCExperienceEditorActive && !layoutData.route && !this.state.loadedRouteData) ||
      loading
    ) {
      return <OverlayLoader />;
    }

    // Render the app's root structural layout
    return (
      <>
        {layoutData && (
          <Layout
            route={layoutData.route}
            brand={layoutData?.site?.name}
            language={layoutData?.language}
          />
        )}
      </>
    );
  }
}

export default withSitecoreContext({ updatable: true })(RouteHandler);
