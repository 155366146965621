import React from 'react';
import PropTypes from 'prop-types';
import { NavigationFooterDataAttributes } from '../../../constants/gtm';
function SiteFooterNavList(e) {
  return (
    <>
      <section key={e?.items?.id} className={`footer-nav-block ${e.items.displayName}-section`}>
        <a
          href={e?.items?.fields?.Link?.value?.href}
          {...NavigationFooterDataAttributes({
            name: 'SiteFooter',
            dataNavItemLevel: 'L0',
            dataNavItemName: e?.items?.displayName || '',
            linkText: e?.items?.fields?.Link?.value?.href || '',
            url: '',
          })}
        >
          <div className="title">{e.items.displayName}</div>
        </a>
        {e.items.fields.LinksCollection.map((item, index) => (
          <nav key={`${index}-${item.id}`}>
            <a
              key={item?.id}
              href={item?.fields?.ctaLink?.value?.href}
              className={`${'solution' ? 'main-item' : ''} ${item.subTitle ? 'sub-title' : ''}`}
              {...NavigationFooterDataAttributes({
                name: 'SiteFooter',
                dataNavItemLevel: 'L1',
                dataNavItemName: item?.fields?.ctaText?.value || '',
                linkText: item?.fields?.ctaLink?.value?.href || '',
                url: '',
              })}
            >
              {item?.fields?.ctaText?.value || ''}
            </a>
          </nav>
        ))}
      </section>
    </>
  );
}

SiteFooterNavList.defaultProps = {
  e: {},
};

SiteFooterNavList.propTypes = {
  e: PropTypes.shape({}),
};

export default SiteFooterNavList;
