import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardSolution from '../Cards/CardSolution';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
const CardBlockSolutions = (props) => {
  const {
    type,
    backgroundColor,
    fields,
    rendering: { componentName = '' },
  } = props;
  const { title, subTitle, ctaLink, ctaText, ctaArrow, solutions } = fields;
  const [activeIndex, setActiveIndex] = useState(-1);
  return (
    <section
      className={`card-block solutions solutions__callout ${backgroundColor} ${
        type === 'component' ? 'component' : 'sub-component'
      }`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="col section-header">
            {title?.value && (
              <h2 className="title">
                <TextSitecoreField field={title} />
              </h2>
            )}
            {subTitle?.value && (
              <p className="sub-title">
                <TextSitecoreField field={subTitle} />
              </p>
            )}
          </div>
          {ctaText?.value && (
            <div className="cta-wrapper col-lg-4 content-end">
              <ButtonHref
                text={ctaText?.value || ctaLink?.value}
                href={ctaLink?.value?.href}
                variant="secondary-button"
                arrow={ctaArrow?.value}
                linkType={ctaLink?.value?.linktype}
                linkFields={ctaLink}
                componentName={componentName}
                dataItemName=""
              />
            </div>
          )}
        </div>

        <div className="row">
          <div className="content-wrapper col">
            {solutions &&
              solutions?.map((item, index) => (
                <CardSolution
                  key={item.id}
                  title={item?.fields?.title}
                  text={item?.fields?.description}
                  icon={item?.fields?.image}
                  altText={item?.fields?.image?.alt || 'alt'}
                  ctaText={item?.fields?.linkTxt.value}
                  href={item?.fields?.link?.value?.href}
                  linkFields={item?.fields?.link}
                  linkType={item?.fields?.link?.value?.linktype}
                  isActive={activeIndex === index}
                  onShow={() => {
                    setActiveIndex(index);
                  }}
                  componentName={componentName}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

CardBlockSolutions.defaultProps = {
  text: '',
  backgroundColor: 'on-primary',
  type: 'component',
  fields: {
    title: '',
    subTitle: '',
    ctaLink: '',
    ctaText: '',
    ctaArrow: '',
  },
};

CardBlockSolutions.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  type: PropTypes.oneOf(['component', 'sub-component']),
  backgroundColor: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
      altText: PropTypes.string,
      cssClass: PropTypes.string,
    })
  ),
};

export default CardBlockSolutions;
