/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import CarouselChart from './CarouselChart';
import CarouselForDataBlock from './CarouselForDataBlock';
import { ComponentDataAttributes } from '../../../../constants/gtm';
import './DataBlockCarousal.scss';
import Slider from 'react-slick';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import { isEmpty } from 'lodash';

const DataBlockcarousel = (props) => {
  const {
    fields = {},
    rendering: { componentName = '' },
  } = props;
  const { Chart = [], insightitems, backgroundTextColor, title, subTitle, Content } = fields;

  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    arrows: true,
    cssEase: '.card',
    accessibility: true,
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">0{i + 1}</span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">0{Chart && Chart.length}</span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          infinite: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section
        className={`carousel datablock_carousel standard insights cards__${
          insightitems && insightitems.length
        } ${
          !isEmpty(backgroundTextColor) && backgroundTextColor?.fields?.text?.value === 'primary'
            ? 'secondary'
            : 'secondary'
        }`}
        {...ComponentDataAttributes(componentName)}
      >
        <div className="container-xl">
          <div className="row carouselTitle">
            <div className="col-lg-8">
              <h2 className="title">{title && <TextSitecoreField field={title} />}</h2>
              <p className="sub-title">{Content && <TextSitecoreField field={Content} />}</p>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row">
            <div className="col carousel-wrapper big_card twi_bigcard">
              <Slider {...settingsSliderNav}>
                {Chart &&
                  Array.isArray(Chart) &&
                  Chart.map((e, i1) => {
                    if (e?.fields?.Chart) {
                      return <CarouselChart data={e} key={i1} />;
                    }
                    return '';
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <CarouselForDataBlock data={props} />
    </>
  );
};

DataBlockcarousel.defaultProps = {
  fields: {
    backgroundTextColor: 'secondary',
    chart: {},
    chartDescription: {},
    chartTitle: {},
    chartHeader: {},
    chartShowCTAIcon: {},
    chartCTAText: {},
    chartCTAIconPosition: {},
    chartCTAStyle: {},
    chartCTALink: {},
    chartCTAIcon: {},
    title: {},
    description: {},
    ctaLink: {},
    ctaIcon: {},
    ctaIconPosition: {},
    ctaArrow: {},
    ctaText: {},
    ctaStyle: {},
    isShowGlobalButton: {},
    maxResult: {},
  },
};

DataBlockcarousel.propTypes = {
  fields: PropTypes.shape({
    backgroundTextColor: PropTypes.string,
    chart: PropTypes.shape({}),
    chartDescription: PropTypes.shape({}),
    chartTitle: PropTypes.shape({}),
    chartHeader: PropTypes.shape({}),
    chartShowCTAIcon: PropTypes.shape({}),
    chartCTAText: PropTypes.shape({}),
    chartCTAIconPosition: PropTypes.shape({}),
    chartCTAStyle: PropTypes.shape({}),
    chartCTALink: PropTypes.shape({}),
    chartCTAIcon: PropTypes.shape({}),
    title: PropTypes.shape({}),
    description: PropTypes.shape({}),
    ctaLink: PropTypes.shape({}),
    ctaIcon: PropTypes.shape({}),
    ctaIconPosition: PropTypes.shape({}),
    ctaArrow: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    ctaStyle: PropTypes.shape({}),
    isShowGlobalButton: PropTypes.shape({}),
    maxResult: PropTypes.shape({}),
  }),
};

export default DataBlockcarousel;
