import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from './../../../globals/link/ButtonLink';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';
import { ComponentDataAttributes } from '../../../constants/gtm';

const SidebarCardRelatedSolution = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { links, title, backgroundColor } = fields;
  const compiledBackgroundColor = getFooterCallOutBarStylesFromBackgroundColor(
    backgroundColor?.fields?.text?.value
  );
  return (
    <section className="sidebar-links-section" {...ComponentDataAttributes(componentName)}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div
              className={`card sidebar-links sidebar sidebar__solutions__relative ${compiledBackgroundColor}`}
            >
              <div className="card-body">
                <h2
                  className={`title related title__${
                    links && (links?.length > 2 ? 'multiple' : links?.length)
                  }`}
                >
                  {title && <TextSitecoreField field={title} />}
                </h2>
                <div className="cta__wrap sidecard related related-links">
                  {links &&
                    links.map((item, index) => (
                      <ButtonHref
                        key={index}
                        text={<TextSitecoreField field={item?.fields?.ctaText} />}
                        href={item?.fields?.ctaLink?.value?.href}
                        cssClass={`${
                          compiledBackgroundColor === 'violet' ||
                          compiledBackgroundColor === 'secondary'
                            ? 'white'
                            : compiledBackgroundColor === 'primary'
                            ? 'violet'
                            : 'black'
                        } related`}
                        arrow={item?.fields?.ctaArrow ? item?.fields?.ctaArrow?.value : false}
                        variant={`${item?.fields?.ctaStyle?.fields?.text?.value}`}
                        iconPosition={`${
                          item?.fields?.ctaIconPosition?.fields?.text?.value === 'right'
                            ? 'icon__right'
                            : 'icon__left'
                        }`}
                        linkType={item?.fields?.ctaLink?.value?.linktype}
                        linkFields={item?.fields?.ctaLink}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SidebarCardRelatedSolution.defaultProps = {
  fields: {},
  title: '',
  ctaText: '',
  backgroundColor: '',
  links: [],
};

SidebarCardRelatedSolution.propTypes = {
  title: PropTypes.string,
  ctaText: PropTypes.string,
  backgroundColor: PropTypes.string,
  items: PropTypes.array,
  fields: PropTypes.object,
};

export default SidebarCardRelatedSolution;
