import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';

const CardFooterAction = (props) => {
  const { fields, componentName } = props;
  const { Content, backgroundColor, optCtaText, optCtaLink, ctaStyle } = fields;
  return (
    <div
      className={`card footer-action ${getFooterCallOutBarStylesFromBackgroundColor(
        backgroundColor?.fields?.text?.value
      )}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="card-body">
        <h2 className="title">
          <TextSitecoreField field={Content} />
        </h2>
        <div className="cta__wrap">
          {optCtaText?.value && optCtaLink?.value?.href && (
            <ButtonHref
              text={optCtaText?.value}
              arrow={true}
              cssClass={backgroundColor?.fields?.text?.value === 'white' ? 'violet' : 'white'}
              href={optCtaLink?.value?.href}
              variant={ctaStyle?.fields?.text?.value || 'primary-button'}
              componentName={componentName}
              linkFields={optCtaLink}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CardFooterAction.defaultProps = {
  fields: {
    Content: {},
    backgroundColor: {},
    optCtaText: {},
    optCtaLink: {},
    componentName: '',
    ctaStyle: {},
  },
};

CardFooterAction.propTypes = {
  fields: PropTypes.shape({
    Content: PropTypes.shape({}),
    backgroundColor: PropTypes.shape({}),
    optCtaText: PropTypes.shape({}),
    optCtaLink: PropTypes.shape({}),
    componentName: PropTypes.string,
    ctaStyle: PropTypes.shape({}),
  }),
};

export default CardFooterAction;
