import React from 'react';
import SiteFooterNavList from './SiteFooterNavList';
import PropTypes from 'prop-types';

function SiteFooterNav({ props }) {
  const { fields = {} } = props;
  return (
    <>
      <div className="row footer-nav">
        {fields?.FooterNavigation?.map((e, index) => (
          <div className="col-lg-3 col-6" key={index}>
            <SiteFooterNavList items={e} />
          </div>
        ))}
      </div>
    </>
  );
}

SiteFooterNav.defaultProps = {
  fields: {},
};
SiteFooterNav.propTypes = {
  fields: PropTypes.shape({}),
};

export default SiteFooterNav;
