import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from './../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from './../../sitecorefields/ImageSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';

const FeaturettePromo = ({ fields }) => {
  const {
    rendering: { componentName = '' },
  } = props;
  const {
    align,
    backgroundImage,
    ctaArrow,
    ctaLink,
    ctaText,
    paragraphText,
    title,
    backgroundColor = 'primary',
  } = fields;
  return (
    <section
      className={`featurette-section promo ${align?.fields?.text?.value} ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className={`row content-wrapper ${align?.fields?.text?.value}`}>
          {backgroundImage && (
            <div className="col-lg-4 col-md-6">
              <div className="featurette-img">
                <ImageSitecoreField field={backgroundImage} />
              </div>
            </div>
          )}
          <div className="col">
            <div className="featurette-body">
              <h2
                className={`title ${backgroundColor === 'primary' ? 'on-primary' : 'on-secondary'}`}
              >
                {<TextSitecoreField field={title} />}
              </h2>
              <p
                className={`richtext-wrapper ${
                  backgroundColor && backgroundColor === 'primary' ? 'on-primary' : 'on-secondary'
                }`}
              >
                {paragraphText && <TextSitecoreField field={paragraphText} />}
              </p>
              {ctaText && (
                <div className="cta-wrapper">
                  <ButtonHref
                    href={ctaLink.value.href}
                    text={<TextSitecoreField field={ctaText} />}
                    cssClass={`${
                      backgroundColor && backgroundColor === 'primary' ? 'black' : 'white'
                    }`}
                    variant="secondary-button"
                    arrow={ctaArrow.value}
                    componentName={componentName}
                    dataItemName={title?.value}
                    linkFields={ctaLink}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturettePromo.defaultProps = {
  fields: {
    title: {
      value: '',
    },
    paragraphText: {
      value: '',
    },
    subTitle: {
      value: '',
    },
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    align: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'right',
        },
      },
    },
    ctaLink: {
      value: {
        href: '',
      },
    },
    ctaIcon: {
      value: {},
    },
    ctaIconPosition: null,
    ctaArrow: {
      value: false,
    },
    ctaText: {
      value: '',
    },
    ctaStyle: null,
    backgroundColor: 'primary',
  },
};

FeaturettePromo.PropTypes = {
  fields: PropTypes.shape(
    { title: PropTypes.shape({ value: PropTypes.string }) },
    { paragraphText: PropTypes.shape({ value: PropTypes.string }) },
    { subTitle: PropTypes.shape({ value: PropTypes.string }) },
    {
      backgroundImage: PropTypes.shape({
        value: PropTypes.shape(
          { src: PropTypes.string },
          { alt: PropTypes.string },
          { width: PropTypes.string },
          { height: PropTypes.string }
        ),
      }),
    },
    {
      align: PropTypes.shape(
        { id: PropTypes.string },
        { url: PropTypes.string },
        { name: PropTypes.string },
        { displayName: PropTypes.string },
        {
          fields: PropTypes.shape({
            text: PropTypes.shape({ value: PropTypes.string }),
          }),
        },
        { ctaLink: PropTypes.shape({ value: PropTypes.shape({ href: PropTypes.string }) }) },
        { ctaIcon: PropTypes.shape({ value: PropTypes.shape({}) }) },
        { ctaIconPosition: PropTypes.string },
        { ctaArrow: PropTypes.shape({ value: PropTypes.bool }) },
        { ctaStyle: PropTypes.shape({ value: PropTypes.string }) },
        { backgroundColor: PropTypes.string }
      ),
    }
  ),
};
export default FeaturettePromo;
