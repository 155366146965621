import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const HomeHeroBanner = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;

  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    <section
      className={`home-banner-block banner-section
      ${
        fields?.frostedGlass?.value === true &&
        !isEmpty(fields?.frostedGlassColor?.fields?.text?.value) &&
        (fields?.frostedGlassColor?.fields?.text?.value ? 'glass' : '')
      }`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          isMobileScreen && !isEmpty(fields?.backgroundImageMobile?.value)
            ? fields?.backgroundImageMobile
            : fields?.backgroundImage
        }
        imageMedium={
          isMobileScreen && !isEmpty(fields?.backgroundImageMobile?.value)
            ? fields?.backgroundImageMobile
            : fields?.backgroundImage
        }
        imageLarge={fields?.backgroundImage}
        imageXLarge={fields?.backgroundImage}
      />
      <div
        className={`container-xl ${
          fields?.frostedGlass?.value === true &&
          !isEmpty(fields?.frostedGlassColor?.fields?.text?.value) &&
          (fields?.frostedGlassColor?.fields?.text?.value ? 'no-mobile-padding' : '')
        }`}
      >
        <div className="row content-wrapper">
          <div
            className={`col-xl-8 col-lg-7 col-md-7 ${
              fields?.frostedGlass?.value === true &&
              !isEmpty(fields?.frostedGlassColor?.fields?.text?.value) &&
              (fields?.frostedGlassColor?.fields?.text?.value === 'light'
                ? 'frost frost__light'
                : 'frost frost__dark')
            }`}
          >
            {fields?.subTitle && (
              <p
                className={`sub-title-home ${
                  fields?.textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
              >
                <TextSitecoreField field={fields?.subTitle} />
              </p>
            )}
            {fields?.title && (
              <h1
                className={`title-home ${
                  fields?.textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
              >
                <TextSitecoreField field={fields?.title} />
              </h1>
            )}
            {fields?.paragraphText && (
              <p
                className={`description ${
                  fields?.textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
              >
                <TextSitecoreField field={fields?.paragraphText} />
              </p>
            )}
            <div className="cta__wrap">
              {fields?.ctaLink &&
                fields?.ctaText &&
                !isEmpty(fields?.ctaText?.value) &&
                !isEmpty(fields?.ctaLink?.value?.href) && (
                  <ButtonHref
                    text={fields?.ctaText?.value}
                    cssClass={fields?.textColor?.fields?.text?.value}
                    arrow={fields?.showCTAIcon?.value}
                    href={fields?.ctaLink?.value?.href}
                    linkType={fields?.ctaLink?.value?.linktype}
                    linkFields={fields?.ctaLink}
                    variant="primary-button"
                    componentName={componentName}
                  />
                )}
              {fields?.optCtaLink &&
                fields?.optCtaText &&
                !isEmpty(fields?.optCtaText?.value) &&
                !isEmpty(fields?.optCtaLink?.value?.href) && (
                  <ButtonHref
                    text={fields?.optCtaText?.value}
                    cssClass={fields?.textColor?.fields?.text?.value}
                    variant="text-button"
                    arrow={fields?.optShowCTAIcon?.value}
                    href={fields?.optCtaLink?.value?.href}
                    linkType={fields?.optCtaLink?.value?.linktype}
                    componentName={componentName}
                    linkFields={fields?.optCtaLink}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeHeroBanner.defaultProps = {
  fields: {
    backgroundColor: 'primary',
    backgroundImage: {},
    frostedGlass: { value: false },
    ctaArrow: { value: true },
  },
  subTitle: '',
  title: '',
  text: '',
};

HomeHeroBanner.propType = {
  fields: PropTypes.shape({}),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaCssClass: PropTypes.string,
  buttons: PropTypes.array,
  background: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary']),
};

export default HomeHeroBanner;
