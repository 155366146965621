import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ButtonHref from '../../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import DataBlock from '../FrameDataBlock/DataBlock/DataBlock';
import { useMediaQuery } from 'react-responsive';

const SubNavPanel = ({
  title,
  text,
  ctaText,
  linkHeading,
  links,
  cssClass,
  backgroundColor,
  ctaLink,
  icon,
  APIID,
  name,
  ChartHeading,
  chartComponentName,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const chartSize = isMobile ? 'XS' : 'S';
  const [barChartData, setRespData] = useState([]);
  const [order, setOrder] = useState('TopFiveHighest');
  const apiGet = async () => {
    try {
      const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/json/${APIID}`;
      const apiCall = await axios.get(url);
      const apiRes = await apiCall?.data;
      setRespData(apiRes);
    } catch (error) {}
  };
  const orderValues = (value) => {
    setOrder(value);
  };
  useEffect(() => {
    apiGet();
  }, [order]);

  // const barChartData = [
  //   {
  //     CountryCode: 'USA',
  //     Country: 'United States',
  //     Total: 3.0,
  //     Availability: 0.0,
  //     CostEfficiency: 0.0,
  //     Regulatory: 0.0,
  //     Productivity: 0.0,
  //   },
  //   {
  //     CountryCode: 'SGP',
  //     Country: 'Singapore',
  //     Total: 2.9,
  //     Availability: 0.0,
  //     CostEfficiency: 0.0,
  //     Regulatory: 0.0,
  //     Productivity: 0.0,
  //   },
  //   {
  //     CountryCode: 'CAN',
  //     Country: 'Canada',
  //     Total: 2.7,
  //     Availability: 0.0,
  //     CostEfficiency: 0.0,
  //     Regulatory: 0.0,
  //     Productivity: 0.0,
  //   },
  //   {
  //     CountryCode: 'AUS',
  //     Country: 'Australia',
  //     Total: 2.5,
  //     Availability: 0.0,
  //     CostEfficiency: 0.0,
  //     Regulatory: 0.0,
  //     Productivity: 0.0,
  //   },
  //   {
  //     CountryCode: 'IRL',
  //     Country: 'Ireland',
  //     Total: 2.5,
  //     Availability: 0.0,
  //     CostEfficiency: 0.0,
  //     Regulatory: 0.0,
  //     Productivity: 0.0,
  //   },
  // ];
  const bar = (Chart) => {
    const primaryDataLabel =
      Chart &&
      Chart?.map((item) => {
        return item?.Country;
      });
    const primaryDataValue =
      Chart &&
      Chart?.map((item) => {
        return item[name];
      });
    const barDataStructure = {
      title: ChartHeading,
      chartType: 'bar',
      size: chartSize,
      data: {
        datasets: [
          {
            barPercentage: 1.0,
            borderRadius: 100,
            categoryPercentage: 0.8,
            data: primaryDataValue,
            label: ChartHeading,
          },
        ],
        labels: primaryDataLabel,
        yValues: {
          min: 0,
          max: Math.max(...primaryDataValue) + 2,
        },
      },
    };
    return barDataStructure;
  };
  const chartDatas = {
    chartType: 'bar',
    size: 'S',
    data: {
      datasets: [
        {
          barPercentage: 1,
          borderRadius: 100,
          categoryPercentage: 0.8,
          data: [2.8, 2.7, 2.6, 2.4, 2.4],
          label: 'Workforce rankings',
        },
      ],
      labels: ['United States', 'Singapore', 'Canada', 'Hong Kong', 'Netherlands'],
      yValues: {
        max: 2.9,
        min: 2,
      },
    },
    title: 'Global Workforce Total Rankings 2021',
    backgroundColor: 'white',
  };
  return (
    <div className={`flex-container row subnav subnav__panel fade-in ${cssClass}`}>
      <div className="flex-container1">
        {!isMobile && <img src={icon?.src} alt={icon?.alt} />}
        {title?.value && (
          <h3 className="title grid-title">{<TextSitecoreField field={title} />}</h3>
        )}
        <div className="sub-title">{<TextSitecoreField field={text} />}</div>
        <div className="cta__wrap">
          <ButtonHref
            text={ctaText}
            variant="text-button"
            href={ctaLink}
            fontSize={backgroundColor === 'primary' ? 'black' : 'white'}
            {...(ctaText ? { arrow: true } : {})}
            componentName="ExpandableGrid"
            dataItemName={title?.value}
          />
        </div>
      </div>

      <div className="flex-container2">
        <div className="wrapper">
          <div className="related-solution label small">
            {<TextSitecoreField field={linkHeading} />}
          </div>
          <div className="links">
            {order === 'TopFiveHighest' && barChartData?.TopFiveHighest && (
              <DataBlock
                chartComponentName={chartComponentName}
                chartName={name}
                setOrder={orderValues}
                Data={bar(barChartData?.TopFiveHighest)}
                order={order}
              />
            )}
            {order === 'TopFiveLowest' && barChartData?.TopFiveLowest && (
              <DataBlock
                chartComponentName={chartComponentName}
                chartName={name}
                setOrder={orderValues}
                order={order}
                Data={bar(barChartData?.TopFiveLowest)}
              />
            )}
            {/* {links &&
            links.map((link) => (
              <Button
                key={link.id}
                text={link.name}
                variant="text-button"
                cssClass={backgroundColor === 'primary' ? 'black' : 'white'}
                // fontSize={backgroundColor === 'primary' ? 'small' : 'white'}
                arrow
                componentName="ExpandableGrid"
                dataItemName={linkHeading?.value}
                BtnFunction={() => {
                  location.href = link?.fields?.Link?.value?.href;
                }}
              />
            ))} */}
          </div>
          {/* <select
            className="absolute-block"
            id="selectNoErrorTitle"
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          >
            <option value="TopFiveHighest">Highest</option>
            <option value="TopFiveLowest">Lowest</option>
          </select> */}
        </div>
      </div>
    </div>
  );
};

SubNavPanel.defaultProps = {
  title: '',
  subTitle: '',
  ctaText: '',
  ctaLink: '',
  links: [],
  cssClass: '',
};

SubNavPanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  links: PropTypes.array,
  cssClass: PropTypes.string,
};

export default SubNavPanel;
