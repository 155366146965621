import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, text, onChange, value, icon }) => {
  return (
    <div className={'form-block checkbox '} onChange={onChange}>
      <input type="checkbox" id={id} className={`${value}`} />
      <label htmlFor={id}>{text}</label>
      {icon && icon?.src !== undefined && icon?.src !== '' && (
        <img src={icon?.src} alt={icon?.alt} />
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  id: 'id',
  text: '',
};

Checkbox.propTypes = {
  /** Field identifier  */
  id: PropTypes.string,

  /** Label text */
  text: PropTypes.string,
};

export default Checkbox;
