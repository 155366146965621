import React from 'react';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isDataExists } from './utils/HelperUtils';
import { isEmpty } from 'lodash';
import FavIcon from './globals/icons/Favicon.ico';
const MetaTags = (props) => {
  const { sitecoreContext } = props;
  const { Title, pageDescription, pageTitle, pageOgImage, pageTwitterImage } =
    sitecoreContext?.route?.fields;
  return (
    <>
      <Helmet>
        <title>{(pageTitle && pageTitle?.value) || 'Page'}</title>
        <meta
          name="description"
          content={(pageDescription && pageDescription?.value) || 'Page Description'}
        />
        <meta property="og:title" content={(pageTitle && pageTitle?.value) || 'OG Title'} />
        <meta name="referrer" content="no-referrer"></meta>
        <meta
          property="twitter:title"
          content={(pageTitle && pageTitle?.value) || 'Twitter Title'}
        />
        <meta
          property="og:description"
          content={(pageDescription && pageDescription?.value) || 'OG Description'}
        />
        <meta
          property="twitter:description"
          content={(pageDescription && pageDescription?.value) || 'Twitter Description'}
        />
        <meta
          property="og:image"
          content={(pageOgImage && pageOgImage?.value?.src) || 'Page Og Image'}
        />
        <meta
          property="og:image:secure_url"
          content={(pageOgImage && pageOgImage?.value?.src) || 'Page Og secure URL'}
        />

        <meta
          property="og:image:width"
          content={(pageOgImage && pageOgImage?.value?.width) || 1200}
        />
        <meta
          property="og:image:height"
          content={(pageOgImage && pageOgImage?.value?.height) || 630}
        />
        <meta
          property="twitter:image"
          content={(pageTwitterImage && pageTwitterImage?.value?.src) || 'Page Twitter Image'}
        />
        <meta
          property="twitter:image:width"
          content={(pageTwitterImage && pageTwitterImage?.value?.width) || 1200}
        />
        <meta
          property="twitter:image:height"
          content={(pageTwitterImage && pageTwitterImage?.value?.height) || 630}
        />
        {sitecoreContext?.MetaData?.canonicalUrl &&
        !isEmpty(sitecoreContext?.MetaData?.canonicalUrl) ? (
          <link rel="canonical" href={sitecoreContext?.MetaData?.canonicalUrl} />
        ) : (
          <link rel="canonical" href={sitecoreContext?.route?.fields?.canonicalUrl?.href} />
        )}
        {sitecoreContext?.MetaData?.favicon && !isEmpty(sitecoreContext?.MetaData?.favicon) ? (
          <link
            rel="shortcut icon"
            href={mediaApi.updateImageUrl(sitecoreContext?.MetaData?.favicon)}
          />
        ) : (
          <link rel="shortcut icon" href={mediaApi.updateImageUrl(FavIcon)} />
        )}
        <meta
          property="og:url"
          content={
            sitecoreContext?.MetaData?.canonicalUrl &&
            !isEmpty(sitecoreContext?.MetaData?.canonicalUrl)
              ? sitecoreContext?.MetaData?.canonicalUrl
              : sitecoreContext?.route?.fields?.canonicalUrl?.href
          }
        />

        <meta
          property="og:locale"
          key={`${sitecoreContext?.language}`}
          content={sitecoreContext?.language}
        />

        {isDataExists(sitecoreContext?.MetaData?.alternateUrls)
          ? sitecoreContext?.MetaData?.alternateUrls.map((item, index) => {
              const { alternativeUrl, language } = item;
              return (
                <link
                  rel="alternate"
                  key={`${language}_${index}`}
                  href={alternativeUrl}
                  hrefLang={language}
                />
              );
            })
          : null}
      </Helmet>
    </>
  );
};
export default withSitecoreContext()(MetaTags);
MetaTags.defaultProps = {
  metaData: {
    fields: {},
    sitecoreContext: {},
  },
};
MetaTags.propTypes = {
  metaData: PropTypes.shape({
    fields: PropTypes.shape({}),
    sitecoreContext: PropTypes.shape({}),
  }),
};
