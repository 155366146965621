import React from 'react';
import PropTypes from 'prop-types';
import DecisionTreeCard from '../DecisionTreeCard/DecisionTreeCard';
import CloseButton from '../../../globals/buttons/CloseButton/CloseButton';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import './decisionTreeStep1.scss';

const DecisionTreeStep1 = ({
  subTitle,
  title,
  closeIcon,
  cards,
  onClick,
  closeDecisionTree,
  componentName,
}) => {
  return (
    <section className="dt-step1">
      <div className="dt-step1__container">
        <div className="dt-step1__header">
          <div className="dt-step1__btn-container">
            <CloseButton icon={closeIcon} cssClass="black" onClick={closeDecisionTree} />
          </div>
          <div className="dt-step1__title-container">
            <h2 className="dt-step1__sub-title">
              {subTitle && subTitle?.value && (
                <TextSitecoreField field={subTitle}></TextSitecoreField>
              )}
            </h2>
            <h3 className="dt-step1__title">
              {title && title?.value && <TextSitecoreField field={title}></TextSitecoreField>}
            </h3>
          </div>
        </div>
        <div className="dt-step1__card-container">
          {cards &&
            cards.decisionTreeCards.map((card, index) => (
              <DecisionTreeCard
                key={index}
                index={index}
                title={card?.fields?.title}
                text={card?.fields?.subTitle}
                icon={card?.fields?.image?.value}
                arrowIcon={card?.fields?.arrowBtnIcon}
                variant={1}
                onClick={onClick}
                componentName={componentName}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

DecisionTreeStep1.defaultProps = {
  subTitle: '',
  title: '',
  closeIcon: {},
  cards: [],
  onClick: () => {},
  closeDecisionTree: () => {},
};

DecisionTreeStep1.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  closeIcon: PropTypes.shape({}),
  cards: PropTypes.array,
  onClick: PropTypes.func,
  closeDecisionTree: PropTypes.func,
};

export default DecisionTreeStep1;
