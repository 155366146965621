/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import DataBlockInsightChart from '../DataBlockInsightChart/DataBlockInsightChart';
import CarouselInsights from '../../core/Carousels/CarouselInsights';
import './dataBlockInsight.scss';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import CustomCarouselDataBlockInsight from '../Carousels/CustomCarouselDatablockInsight';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { mapBackgroundColor } from '../../../utils/HelperUtils';

const DataBlockInsight = (props) => {
  const {
    fields = {},
    rendering: { componentName = '' },
  } = props;
  const {
    Chart,
    chartDescription,
    chartTitle,
    chartHeader,
    chartShowCTAIcon,
    chartCTAText,
    chartCTAIconPosition,
    chartCTAStyle,
    chartCTALink,
    chartCTAIcon,
    title,
    description,
    ctaLink,
    ctaIcon,
    ctaIconPosition,
    ctaArrow,
    ctaText,
    ctaStyle,
    isShowGlobalButton,
    maxResult,
    insightitems,
    backgroundTextColor,
  } = fields;
  const backgroundColor = mapBackgroundColor(backgroundTextColor?.fields?.text?.value);
  const getSize = () => {
    if (isTiny) {
      return 'Tiny';
    } else if (isMobile) {
      return 'XXS';
    } else if (isTablet) {
      return 'S';
    }
    return 'M';
  };
  return (
    <section
      className={`insights-section data ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="col section-header">
            {title && title?.value && (
              <h2
                className={`title ${backgroundColor === 'primary' ? 'on-primary' : 'on-secondary'}`}
              >
                <TextSitecoreField field={title} />
              </h2>
            )}
            {description && (
              <p
                className={`sub-title ${
                  backgroundColor === 'primary' ? 'on-primary' : 'on-secondary'
                }`}
              >
                <TextSitecoreField field={description} />
              </p>
            )}
          </div>
          {ctaLink && (
            <div className="cta-wrapper col-lg-4">
              <Button
                text={ctaText?.value}
                variant="secondary-button"
                cssClass="white"
                arrow
                BtnFunction={() => {
                  location.href = ctaLink?.value?.href;
                }}
                componentName={componentName}
              />
            </div>
          )}
        </div>
      </div>
      <DataBlockInsightChart
        header={chartHeader}
        title={chartTitle}
        description={chartDescription}
        ctaText={chartCTAText}
        ctaLink={chartCTALink}
        data={{ Chart: Chart }}
        componentName={componentName}
      />

      {/* <DataBlockFeatureInsight Chart={Chart} /> */}
      <CustomCarouselDataBlockInsight data={props} componentName={componentName} />
    </section>
  );
};

DataBlockInsight.defaultProps = {
  fields: {
    backgroundColor: 'secondary',
    chart: {},
    chartDescription: {},
    chartTitle: {},
    chartHeader: {},
    chartShowCTAIcon: {},
    chartCTAText: {},
    chartCTAIconPosition: {},
    chartCTAStyle: {},
    chartCTALink: {},
    chartCTAIcon: {},
    title: {},
    description: {},
    ctaLink: {},
    ctaIcon: {},
    ctaIconPosition: {},
    ctaArrow: {},
    ctaText: {},
    ctaStyle: {},
    isShowGlobalButton: {},
    maxResult: {},
  },
};

DataBlockInsight.propTypes = {
  fields: PropTypes.shape({
    backgroundColor: PropTypes.string,
    chart: PropTypes.shape({}),
    chartDescription: PropTypes.shape({}),
    chartTitle: PropTypes.shape({}),
    chartHeader: PropTypes.shape({}),
    chartShowCTAIcon: PropTypes.shape({}),
    chartCTAText: PropTypes.shape({}),
    chartCTAIconPosition: PropTypes.shape({}),
    chartCTAStyle: PropTypes.shape({}),
    chartCTALink: PropTypes.shape({}),
    chartCTAIcon: PropTypes.shape({}),
    title: PropTypes.shape({}),
    description: PropTypes.shape({}),
    ctaLink: PropTypes.shape({}),
    ctaIcon: PropTypes.shape({}),
    ctaIconPosition: PropTypes.shape({}),
    ctaArrow: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    ctaStyle: PropTypes.shape({}),
    isShowGlobalButton: PropTypes.shape({}),
    maxResult: PropTypes.shape({}),
  }),
};

export default DataBlockInsight;
