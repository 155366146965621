import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import ArticleHeader from './ArticleHeader';
import ActionBarSocialFollow from '../ActionBars/ActionBarSocialFollow';
import SingleBio from './SingleBiography';
import SidebarCardBiography from '../SidebarCards/SidebarCardBiography';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const LeaderBio = (props) => {
  const {
    fields,
    bcItems,
    rendering: { componentName = '' },
  } = props;
  const breadcrumbItems =
    Breadcrumb && Array.isArray(bcItems) && bcItems[0]?.props?.fields?.data?.item;
  return (
    <section className="article-block biography" {...ComponentDataAttributes(componentName)}>
      <div className="container-xl">
        <div className="row">
          <div className="col-xl-10">
            <div className="row">
              <div className="col">
                <div className="article-header-container">
                  <Breadcrumb items={breadcrumbItems} />
                  <ArticleHeader title={fields?.Name} subTitle={fields?.jobTitle} />
                  {fields?.socialLinkList?.fields?.ctaLink?.value?.url && (
                    <ActionBarSocialFollow
                      items={fields?.socialLinkList}
                      componentName={componentName}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <SingleBio bodyContent={fields?.bodyContent} />
              </div>
              <div className="col-md-4">
                <SidebarCardBiography
                  img={fields?.cardImage}
                  title={fields?.downloadTitle?.value}
                  items={fields?.downloadLinkList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LeaderBioContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'bcItems' }])(LeaderBio)
);

LeaderBio.defaultProps = {
  bcItems: [],
  mediaDownloads: [],
  socialItems: [],
  profileImage: '',
};

LeaderBio.propTypes = {
  bcItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  mediaDownloads: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  socialItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  profileImage: PropTypes.string,
};

export default LeaderBioContainer;
