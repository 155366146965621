import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { NavTopDataAttributes } from '../../../../../constants/gtm';

const HeaderSubTag = ({
  text,
  icon,
  fontSize,
  variant,
  cssClass,
  iconPosition,
  onClick,
  componentName,
  hoverIcon,
}) => {
  const [iconHover, setIconhover] = useState(false);
  function MouseOver(event) {
    setIconhover(true);
  }
  function MouseOut(event) {
    setIconhover(false);
  }
  return (
    <button
      className={`button width-max tag ${variant} ${cssClass} ${fontSize}`}
      onClick={onClick}
      onMouseEnter={() => MouseOver()}
      onMouseLeave={() => MouseOut()}
      {...NavTopDataAttributes({
        name: componentName,
        itemLevel: 'L1',
        dataNavItemName: text,
        dataLinkText: text,
        dataLinkImageUrl: '',
      })}
    >
      {!iconHover
        ? icon && (
            <img
              src={mediaApi.updateImageUrl(icon)}
              className={`icon ${iconPosition}`}
              alt={text ? text : ''}
            />
          )
        : icon && (
            <img
              src={mediaApi.updateImageUrl(hoverIcon)}
              className={`icon ${iconPosition}`}
              alt={text ? text : ''}
            />
          )}
      {text}
    </button>
  );
};

HeaderSubTag.defaultProps = {
  variant: '',
  cssClass: '',
  fontSize: '',
  icon: '',
  text: '',
  iconPosition: 'icon__left',
  onClick: () => {},
  componentName: '',
};

HeaderSubTag.propTypes = {
  /** White, Black, Outline or Outline On-Secondary */
  cssClass: PropTypes.string,
  /** Article, Outline, or Solid */
  variant: PropTypes.string,
  /** Font Size (Default Medium) */
  fontSize: PropTypes.oneOf(['medium', 'small']),
  /** Optional */
  icon: PropTypes.string,
  text: PropTypes.string,
  iconPosition: PropTypes.string,
  onClick: PropTypes.func,
  componentName: PropTypes.string,
};

export default HeaderSubTag;
