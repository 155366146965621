import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

const CardTeamProfile = ({ data, componentName = '' }) => {
  const { fields = {} } = data;
  const cssClass = '';
  const { profileImage, profileName, jobTitle, ctaLink, ctaArrow, ctaText } = fields;
  return (
    <div className={`card team-profile ${cssClass}`}>
      <div className="card-body">
        {profileImage?.value?.src && (
          <ImageSitecoreField
            className="headshot"
            field={profileImage}
            alt={profileImage?.value?.alt}
          />
        )}
        <a
          href={ctaLink?.value?.href}
          className="name-link"
          {...OtherLinkDataAttributes({
            name: componentName,
            text: profileName?.value || '',
            url: ctaLink?.value?.href,
            dataItemName: profileName?.value || '',
          })}
        >
          {profileName && (
            <h3 className="name">
              <TextSitecoreField field={profileName} />
            </h3>
          )}
        </a>
        {jobTitle && (
          <p className="job-title">
            <TextSitecoreField field={jobTitle} />
          </p>
        )}
        <ButtonHref
          text={<TextSitecoreField field={ctaText} /> || ctaText?.value}
          href={ctaLink?.value?.href}
          variant="text-button"
          arrow={ctaArrow?.value}
          linkType={ctaLink?.value?.linktype}
          linkFields={ctaLink}
          dataItemName={profileName?.value || ''}
          componentName={componentName}
        />
      </div>
    </div>
  );
};

CardTeamProfile.defaultProps = {
  data: {
    fields: {},
  },
};

CardTeamProfile.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({}),
  }),
};

export default CardTeamProfile;
