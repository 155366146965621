import { getLanguageConfig } from '../../utils/HelperUtils';
import { postAPI } from '../helper';

export const fetchCardBlockLargeList = (
  InsightsFilters,
  recordsPerPage,
  pageNumber,
  language,
  sortPopular,
  sortRelevance,
  searchKeyword
) => {
  const currentUrl = window?.location?.pathname.replace('/', '');
  const languageVal = getLanguageConfig(currentUrl);
  const url = '/api/services/TSInsightsSearch/CardListingLarge';
  let insightsFilters = InsightsFilters || 'types=article,casestudy,event,podcast,research,webinar';
  if (!insightsFilters.includes('types=')) {
    insightsFilters = `${`'types=article,casestudy,event,podcast,research,webinar|${insightsFilters}`}`;
  }

  const data = {
    limit: `${recordsPerPage}`,
    offset: `${pageNumber}`,
    sortPopular,
    sortRelevance,
    language: `${languageVal?.language}`,
    sortPopular,
    sortRelevance,
    InsightsFilters: insightsFilters,
    searchKeyword,
  };
  const result = postAPI(url, data);
  return result;
};
