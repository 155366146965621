import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import AnchorTag from '../../../globals/buttons/AnchorTag';
import { ComponentDataAttributes, NavigationFooterDataAttributes } from '../../../constants/gtm';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from '../../../globals/buttons/Button';
import { cookiePolicy } from '../../../utils/HelperUtils';

function SiteFooterDisclaimer(props) {
  const {
    fields,
    sitecoreContext,
    rendering: { componentName = '' },
  } = props;
  const { DisclaimerLinks, Logos, text } = fields;

  const allAlt = Logos.map((item) => item?.fields?.logo?.value.alt);
  const lengthArr = allLogo?.length;
  const firstAlt = allAlt?.slice(0, 1);
  const restAlt = allAlt?.slice(1, lengthArr);
  const allLogo = Logos.map((item) => item?.fields?.logo?.value);
  const allHref = Logos.map((item) => item?.fields?.ctaUrl?.value?.href);

  const itemsObj = allLogo.map((id, index) => {
    return {
      logos: allLogo[index],
      href: allHref[index],
    };
  });
  const country_code = sitecoreContext?.route?.name;
  const firstLogo = itemsObj?.slice(0, 1);
  let restLogo = '';
  let restLogoMorethanThreeForGermany = '';
  if (country_code !== 'Germany') {
    restLogo = itemsObj?.slice(1, lengthArr);
  } else {
    restLogo = itemsObj?.slice(1, 4);
    restLogoMorethanThreeForGermany = itemsObj?.slice(4, lengthArr);
  }

  return (
    <div className="footer-disclaimer" {...ComponentDataAttributes(componentName)}>
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <div className="footer-disclaimer-icons">
              <a
                href={firstLogo[0]?.href}
                className="icon icon--1 full"
                {...NavigationFooterDataAttributes({
                  name: componentName,
                  dataNavItemLevel: 'L0',
                  dataNavItemName: 'Manpower Logo',
                  linkText: firstAlt || '',
                  url: firstLogo[0]?.href || '',
                })}
              >
                <ImageSitecoreField
                  field={firstLogo[0]?.logos && firstLogo[0].logos}
                  alt={firstAlt}
                />
              </a>
              <br />
              {restLogo &&
                restLogo?.map((item, index) => (
                  <a
                    href={item?.href}
                    className={`icon icon--${index + 1}`}
                    key={`${index}-${item}`}
                    {...NavigationFooterDataAttributes({
                      name: componentName,
                      dataNavItemLevel: 'L0',
                      dataNavItemName: 'Manpower Logo',
                      linkText: restAlt || '',
                      url: item?.href || '',
                    })}
                  >
                    <ImageSitecoreField field={item?.logos} alt={restAlt} />
                  </a>
                ))}
              {country_code === 'Germany' ? <br /> : ''}
              {country_code === 'Germany' &&
                restLogoMorethanThreeForGermany &&
                restLogoMorethanThreeForGermany?.map((item, index) => (
                  <a
                    href={item?.href}
                    className={`icon icon--${index + 1}`}
                    key={`${index}-${item}`}
                    {...NavigationFooterDataAttributes({
                      name: componentName,
                      dataNavItemLevel: 'L0',
                      dataNavItemName: 'Manpower Logo',
                      linkText: restAlt || '',
                      url: item?.href || '',
                    })}
                  >
                    <ImageSitecoreField field={item?.logos} alt={restAlt} />
                  </a>
                ))}
            </div>
            <p>
              <TextSitecoreField field={text} />
            </p>

            <div className="footer-disclaimer-links">
              {DisclaimerLinks?.map((disLink) =>
                disLink?.fields?.isCookieSettingLink &&
                disLink?.fields?.isCookieSettingLink?.value ? (
                  <Button
                    key={disLink?.fields?.ctaText?.value}
                    text={disLink?.fields?.ctaText?.value}
                    BtnFunction={cookiePolicy}
                  />
                ) : (
                  <AnchorTag
                    text={disLink?.fields?.ctaText?.value}
                    key={disLink?.fields?.ctaText?.value}
                    href={disLink?.fields?.ctaLink?.value?.href}
                    linkType={disLink?.fields?.ctaLink?.value?.linktype}
                    linkFields={disLink?.fields?.ctaLink}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SiteFooterDisclaimer.defaultProps = {
  mp: true,
  exp: true,
  ts: true,
  jw: false,
  fields: {},
};

SiteFooterDisclaimer.propTypes = {
  mp: PropTypes.bool,
  exp: PropTypes.bool,
  ts: PropTypes.bool,
  jw: PropTypes.bool,
  fields: PropTypes.object,
};

export default withSitecoreContext()(SiteFooterDisclaimer);
