const filterKeyEnter = (handler) => {
  return (e) => {
    if (e.keyCode === 13) {
      handler(e);
    }
  };
};

export const accessibilityButtonProps = (handler, tabIndex) => ({
  role: 'button',
  tabIndex: tabIndex || 0,
  onKeyDown: filterKeyEnter(handler),
  onClick: handler,
});
