import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../../src/globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import './decisionTreeFooter.scss';

const DecisionTreeFooter = ({ prompt, cta1, cta2 }) => {
  const ctaOne = (
    <ButtonHref
      text={cta1}
      variant="secondary-button"
      cssClass="white"
      fontSize="small"
      arrow={true}
      onClick={() => {
        // location.href = '/search';
      }}
      componentName="DecisionTree"
    />
  );
  const ctaTwo = (
    <ButtonHref
      text={cta2}
      cssClass="violet"
      fontSize="small"
      onClick={() => {
        // location.href = '/contact';
      }}
      componentName="DecisionTree"
    />
  );

  return (
    <section className="dt-footer">
      <div className="dt-footer__container">
        <p className="dt-footer__text">
          <TextSitecoreField field={prompt}></TextSitecoreField>
        </p>
        <div className="dt-footer__cta-container">
          {ctaOne}
          {ctaTwo}
        </div>
      </div>
    </section>
  );
};

DecisionTreeFooter.defaultProps = {
  prompt: '',
  cta1: '',
  cta2: '',
};

DecisionTreeFooter.propTypes = {
  prompt: PropTypes.string,
  cta1: PropTypes.element,
  cta2: PropTypes.element,
};

export default DecisionTreeFooter;
