/* eslint-disable no-unused-vars */
import React from 'react';
// import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './TwoColumnContainer.scss';
const TwoColumnContainer = (props) => {
  const { rendering } = props;
  const { placeholders } = rendering;
  const leftLayout = Math.ceil((props?.params?.leftSideSize / 100) * 12);
  return (
    <section className="two-column-container">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-8 col-xl-7 col-md-12">
            <Placeholder name="jss-left" rendering={props?.rendering} />
          </div>
          <div className="col-lg-4 offset-lg-0 col-xl-4 offset-xl-1 col-md-12">
            <Placeholder name="jss-right" rendering={props?.rendering} />
          </div>
        </div>
      </div>
    </section>
  );
};
TwoColumnContainer.defaultProps = {
  fields: {},
  rendering: {
    placeholders: {},
  },
};
// col-xl-6 col-lg-8 col-md-10 col-sm-12
TwoColumnContainer.prototype = {
  fields: PropTypes.shape({}),
  rendering: PropTypes.shape({}),
};

export default TwoColumnContainer;
