import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import CardInsightsListing from '../Cards/CardInsightsListing';
import Pagination from '../Navigation/Pagination/Pagination';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardBlockInsightsListing = (props) => {
  const [resData, setResData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const {
    fields = {},
    rendering: { componentName = '' },
  } = props;
  const { recordsToShow, sourceLocation, templateName } = fields;
  let limitValue = recordsToShow?.value;

  useEffect(() => {
    async function apiPost() {
      try {
        const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/TSContentSearch/InsightSearch?itemId=${sourceLocation?.id}&limit=${limitValue}&offset=${pageNumber}&templateName=${templateName?.fields?.title?.value}&language=en`;
        const apiCall = await axios.get(url);
        setResData(apiCall?.data);
      } catch (error) {}
    }
    apiPost();
  }, [pageNumber, limitValue]);

  const sendDataToParent = (index) => {
    setPageNumber(index);
  };

  return (
    <>
      <section className="card-block insights" {...ComponentDataAttributes(componentName)}>
        <div className="container-xl">
          <div className="row">
            <div className="col">
              <div className={'columns cards'}>
                {resData &&
                  resData?.searchResult?.map((item) => (
                    <CardInsightsListing
                      key={item?.id}
                      contentType={item?.contentType}
                      latest={item?.IsLatest}
                      title={item?.title}
                      text={item?.subTitle}
                      ctaUrl={item?.url}
                      ctaLinkHref={item?.ctaLink?.value?.href || ''}
                      ctaText="Read More"
                      date={item?.publicationDate}
                      mediaLength={item?.mediaLength}
                      componentName={componentName}
                      fields={fields}
                      item={item}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {resData && resData.searchResult?.length > 4 && (
                <Pagination
                  itemsPerPage={limitValue}
                  totalItems={resData.totalCount}
                  currentPage={pageNumber}
                  sendDataToParent={sendDataToParent}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

CardBlockInsightsListing.defaultProps = {
  items: [],
  fields: {},
};

CardBlockInsightsListing.propTypes = {
  items: PropTypes.array,
  fields: PropTypes.shape({}),
};

export default CardBlockInsightsListing;
