import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../../../scripts/breakpoints';
import NavLink from '../../../../../../globals/navLink';
import './solutions.scss';
import { NavTopDataAttributes } from '../../../../../../constants/gtm';

export default function SolutionsComponent({ data }) {
  const { fields = {} } = data;
  const { linkCollection } = fields;
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <div className="subNavigationComponent solutions">
      <div className="subNavigationComponent ">
        <div className={'subNavComponent mobileNav'}>
          <div className="subNavComponent__inner">
            <div className="subNavComponent__left">
              {isMobile && (
                <a href={fields?.Link?.value?.href} className="subNavComponent__component-name">
                  {data?.displayName}
                </a>
              )}
            </div>
            <div className="subNavComponent__right">
              <div className={`subNavComponent__list ${isMobile ? 'mobile' : ''}`}>
                {linkCollection?.map((link, index) => {
                  return (
                    <React.Fragment key={`solutions_nav__item--${index}`}>
                      <NavLink url={link?.fields?.Link}>
                        {link?.displayName !== '' ? link?.displayName : link?.name}
                      </NavLink>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SolutionsComponent.defaultProps = {
  fields: {},
};

SolutionsComponent.propTypes = {
  data: PropTypes.object,
};
