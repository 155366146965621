import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import videoIcon from '../../../../src/assets/icons/standard/video-icon.png';
import defaultImage from '../../../../src/assets/icons/standard/defaultImage.png';
import { isEmpty } from 'lodash';
const VideoCardOutcome = ({
  title,
  img,
  field,
  openModal,
  componentName = '',
  backgroundColor,
}) => {
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  const handleClick = (videoData) => {
    openModal({ show: true, data: videoData });
  };

  return (
    <div className={`${backgroundColor} video-cards`}>
      <a
        href="javascript:void(0)"
        onClick={() => handleClick(field)}
        className="card col-lg-4"
        {...OtherLinkDataAttributes({
          name: componentName,
          dataItemName: title || '...',
        })}
      >
        {!isEmpty(img) ? (
          <ResponsiveImage
            imageSmall={img}
            imageMedium={img}
            imageLarge={img}
            imageXLarge={img}
            cssClass="card-image"
          />
        ) : (
          <div
            className={`${
              !isMobileScreen ? 'card-body-image-carousel' : 'card-body-image-carousel'
            }`}
          >
            <img src={defaultImage} alt="default image" />
          </div>
        )}
        <img src={videoIcon} className="videoIcon" alt="videoIcon" />
      </a>
      <p className="card-text">{title}</p>
    </div>
  );
};

VideoCardOutcome.defaultProps = {
  title: '',
  text: '',
  ctaText: '',
  ctaUrl: '',
  img: '',
  field: {},
  componentName: '',
};

VideoCardOutcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  img: PropTypes.string,
  componentName: PropTypes.string,
};

export default VideoCardOutcome;
