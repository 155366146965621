import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Chevron } from './chevron.svg';
import './dataBlockStatistic.scss';

const DataBlockStatistic = ({ Data }) => {
  const { type, color, size, data, secondaryData } = Data;
  const isNumberCard = type === 'number';
  const isPercentageCard = type === 'percentage';
  const isRankingCard = type === 'ranking';
  const isMedium = size.toLowerCase() === 'm';

  return (
    <section
      className={`col-12 db-statistic db-statistic--${type} db-statistic--${color} db-statistic--${size.toLowerCase()} ${
        secondaryData && 'db-statistic--secondary'
      }`}
    >
      <div className="db-statistic__container">
        <div className="db-statistic__primary-container">
          {isNumberCard && <p className="db-statistic__pre-text">{data.preText}</p>}
          <div className="db-statistic__value-container">
            <p className="db-statistic__value">
              {data.statisticValue}
              {isPercentageCard && <span className="db-statistic__percentage">%</span>}
              {isRankingCard && (
                <span className="db-statistic__value-suffix">/{data.statisticSuffix}</span>
              )}
            </p>
          </div>
          <p className="db-statistic__post-text">
            <span>{data?.postText?.split(' ')?.slice(0, 3)?.join(' ')}</span>
            <br />
            <span>
              {data?.postText.split(' ')[3].replace(/\s+/g, '') +
                ' ' +
                data?.postText.split(' ')[4]}
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

DataBlockStatistic.defaultProps = {
  color: 'green',
  size: 'S',
};

DataBlockStatistic.propTypes = {
  type: PropTypes.oneOf(['number', 'percentage', 'ranking']).isRequired,
  color: PropTypes.oneOf(['blue', 'green', 'violet']),
  size: PropTypes.oneOf(['S', 'M']),
  data: PropTypes.object.isRequired,
  secondaryData: PropTypes.object,
};

export default DataBlockStatistic;
