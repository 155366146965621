import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../components/sitecorefields/ImageSitecoreField';

const ResponsivePicture = ({ imageSmall, imageMedium, imageLarge, altText, cssClass }) => (
  <picture className={`${cssClass}`}>
    <source srcSet={imageLarge} media="(min-width: 992px)" />
    <source srcSet={imageMedium} media="(min-width: 768px)" />
    <ImageSitecoreField field={imageSmall} alt={altText} />
  </picture>
);

ResponsivePicture.defaultProps = {
  imageSmall: '',
  imageMedium: '',
  imageLarge: '',
  altText: 'image',
  cssClass: '',
};

ResponsivePicture.propTypes = {
  imageSmall: PropTypes.string,
  imageMedium: PropTypes.string,
  imageLarge: PropTypes.string,
  altText: PropTypes.string,
  cssClass: PropTypes.string,
};

export default ResponsivePicture;
