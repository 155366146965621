import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import VideoCardOutcome from './VideoCardOutcome';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { isBrowserDocumentAvailable } from '../../../utils/HelperUtils';
import './VideoCarousel.scss';
import ModalPopup from './ModalPopup';
import ButtonHref from '../../../globals/buttons/ButtonHref';
const CarouselCustomerOutcomes = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;

  const { Title, VideoCarousel, Description } = fields;
  const [showModal, setShowModal] = useState({ show: false, data: null });
  const [showFullModal, setShowFullModal] = useState(false);
  let slidesToShow = 0;
  const openModal = (data) => {
    setShowModal(data);
  };
  const closeModal = () => {
    setShowModal({ show: false, data: null });
  };
  switch (VideoCarousel?.length) {
    case 1:
      slidesToShow = 1;
      break;
    case 2:
      slidesToShow = 2;
      break;
    default:
      slidesToShow = 2.2;
  }

  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: VideoCarousel && VideoCarousel.length === 1 ? 1 : 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    accessibility: true,
    afterChange: () => {
      hideAriaHiddenTiles();
    },
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">
          {i < 9 ? '0' : ''}
          {i + 1}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">
          {VideoCarousel.length < 10 ? '0' : ''}
          {VideoCarousel && VideoCarousel?.length - 2}
        </span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: false,
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1.2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  function hideAriaHiddenTiles() {
    if (isBrowserDocumentAvailable) {
      Array.from(document?.querySelectorAll('.slick-slide')).forEach((slide) => {
        slide.style.visibility = slide.classList?.contains('slick-active') ? 'visible' : 'hidden';
      });
    }
  }

  useEffect(() => {
    hideAriaHiddenTiles();
  });
  return (
    <section
      className="carousel standard  video-carousel"
      {...ComponentDataAttributes(componentName)}
    >
      <ModalPopup
        showModal={showModal}
        closeModal={closeModal}
        setShowModal={setShowModal}
        showFullModal={showFullModal}
        setShowFullModal={setShowFullModal}
      />
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="col section-header">
            <div className="col-lg-8">
              {Title?.value && (
                <h2 className="title">
                  <TextSitecoreField field={Title} />
                </h2>
              )}
              {Description?.value && (
                <p className="description">
                  <TextSitecoreField field={Description} />
                </p>
              )}
            </div>
          </div>
          <div className="cta-wrapper col-lg-4 content-end">
            {fields?.ctaText?.value && (
              <ButtonHref
                text={fields?.ctaLink?.value?.rext || fields?.ctaText?.value}
                href={fields?.ctaLink?.value?.href}
                variant="secondary-button"
                arrow={fields?.ctaArrow?.value}
                linkType={fields?.ctaLink?.value?.linktype}
                linkFields={fields?.ctaLink}
                componentName={componentName}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col carousel-wrapper related-insights">
            <Slider {...settingsSliderNav}>
              {VideoCarousel?.map((item) => (
                <VideoCardOutcome
                  key={item?.id}
                  id={item?.id}
                  field={item?.fields}
                  title={item?.fields?.VideoTitle?.value}
                  openModal={openModal}
                  closeModal={closeModal}
                  img={item?.fields?.ThumbnailImage?.value}
                  componentName={componentName}
                  backgroundColor={item?.fields?.BackgroundColour?.value}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

CarouselCustomerOutcomes.defaultProps = {
  fields: {},
  title: 'How we have helped our customers',
  cssClass: '',
};

CarouselCustomerOutcomes.propTypes = {
  /** Section Header H2 */
  title: PropTypes.string,
  cssClass: PropTypes.string,

  /** Cards */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      ctaText: PropTypes.string,
    })
  ),
  fields: PropTypes.shape({}),
};

export default CarouselCustomerOutcomes;
