import React from 'react';
import { BreadcrumbDataAttributes } from '../../../constants/gtm';
import PropTypes from 'prop-types';

/**
 * Renders a breadcrumb link with the specified text and URL.
 * @param {Object} props - The component props.
 * @param {string} [props.componentName=''] - The name of the component.
 * @param {string} [props.text=''] - The text to display for the link.
 * @param {string} [props.url=''] - The URL to link to.
 * @returns {JSX.Element} - The rendered breadcrumb link.
 */
const BreadcrumbLink = ({ componentName = '', text = '', url = '' }) => (
  <li>
    <a
      {...BreadcrumbDataAttributes({
        name: componentName,
        dataItemName: text,
        linkText: text,
      })}
      href={url}
    >
      {text}
    </a>
  </li>
);

export default BreadcrumbLink;

BreadcrumbLink.defaultProps = {
  componentName: '',
  text: '',
  url: '',
};

BreadcrumbLink.propTypes = {
  componentName: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
};
