import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const DatablockTwoColumnContainer = () => {
  const { rendering } = props;
  const { placeholders } = rendering;
  const leftLayout = Math.ceil((props?.params?.leftSideSize / 100) * 12);
  return (
    <section className="two-column">
      <div className="two-column-container">
        <div className="row">
          <div
            className={`left-container col-xl-${leftLayout} col-lg-${leftLayout} col-md-12 col-sm-12`}
          >
            <Placeholder name="jss-left" rendering={props?.rendering} />
          </div>
          <div
            className={`right-container col-xl-${12 - leftLayout} col-lg-${
              12 - leftLayout
            } col-md-12 col-sm-12`}
          >
            <Placeholder name="jss-right" rendering={props?.rendering} />
          </div>
        </div>
      </div>
    </section>
  );
};

DatablockTwoColumnContainer.defaultProps = {
  fields: {},
  rendering: {
    placeholders: {},
  },
};
// col-xl-6 col-lg-8 col-md-10 col-sm-12
DatablockTwoColumnContainer.prototype = {
  fields: PropTypes.shape({}),
  rendering: PropTypes.shape({}),
};

export default DatablockTwoColumnContainer;
