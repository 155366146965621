import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import './popularSearchResultsCard.scss';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getBrowserWindow } from '../../../utils/HelperUtils';

export default function PopularSearchResultsCard(props) {
  const { popularSearchText, popularSearchKeywords } = props;
  const PopSearchResultsCardRef = useRef(null);
  const navigate = useNavigate();
  const queryParams = getBrowserWindow ? new URLSearchParams(location.search) : '';
  return (
    <div
      ref={PopSearchResultsCardRef}
      className={['card', 'search_results_card', 'popular_results_card '].join(' ')}
    >
      <div className="row">
        <div className="col-lg-12 popular-col-lg-12">
          <div className="card-body popular-card-body">
            <div className="title">
              <TextSitecoreField field={popularSearchText} />
            </div>
            <div className="card-description">
              {popularSearchKeywords && (
                <ul className={['searchBullets'].join(' ')}>
                  {popularSearchKeywords?.map((item, index) => (
                    <li key={'searchBullets' + index}>
                      <button
                        className="searchBulletBtn"
                        onClick={() => {
                          queryParams.set('search', item?.fields?.text?.value);
                          const newSearch = `?${queryParams.toString()}`;
                          navigate({ pathname: '/site-search', search: newSearch });
                          location?.pathname === '/site-search' ? navigate(0) : '';
                        }}
                      >
                        {item?.fields?.text?.value}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PopularSearchResultsCard.defaultProps = {
  popularSearchText: '',
  popularSearchKeywords: [],
};

PopularSearchResultsCard.propTypes = {
  popularSearchText: PropTypes.string,
  popularSearchKeywords: PropTypes.array,
};
