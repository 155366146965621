export const getBarAlt = (origData, chartType) => {
  let newChartData;
  if (chartType === 'Bar alt') {
    newChartData = {
      title: origData?.fields?.title?.value,
      descLabel: origData?.fields?.dateLabel?.value,
      description: origData?.fields?.dateValue?.value,
      chartType: origData?.fields?.type?.value,
      data: {
        labels: origData?.fields?.dataSet.map((item) => item?.fields?.name?.value),
        datasets: [
          {
            label: origData?.fields?.hoverText?.value,
            data: origData?.fields?.dataSet.map((item) => item?.fields?.value?.value),
            borderRadius: 100,
            barPercentage: 1.0,
            categoryPercentage: 0.8,
          },
        ],
        yValues: {
          min: -4,
          max: 9,
          stepSize: 1,
        },
      },
    };
  } else if (chartType === 'Bar') {
    newChartData = {
      title: origData?.fields?.title?.value,
      descLabel: origData?.fields?.dateLabel?.value,
      description: origData?.fields?.dateValue?.value,
      chartType: origData?.fields?.type?.value,
      data: {
        labels: origData?.fields?.dataSet.map((item) => item?.fields?.name?.value),
        datasets: [
          {
            label: origData?.fields?.hoverText?.value,
            data: origData?.fields?.dataSet.map((item) => item?.fields?.value?.value),
            borderRadius: 100,
            barPercentage: 1.0,
            categoryPercentage: 0.8,
          },
        ],
        yValues: {
          min: 2,
          max: 2.9,
        },
      },
    };
  } else if (chartType === 'Doughnut') {
    newChartData = {
      chartType: origData?.fields?.type?.value,
      data: {
        labels: origData?.fields?.dataSet.map((item) => item?.fields?.name?.value),
        datasets: [
          {
            label: origData?.fields?.hoverText?.value,
            fill: 'start',
            data: origData?.fields?.dataSet.map((item) => item?.fields?.value?.value),
            borderWidth: 0,
            borderColor: 'transparent',
          },
        ],
      },
    };
  } else if (chartType === 'linear-gauge') {
    newChartData = {
      chartType: origData?.fields?.type?.value,
      data: {
        labels: origData?.fields?.dataSet.map((item) => item?.fields?.name?.value),
        datasets: [
          {
            label: origData?.fields?.hoverText?.value,
            data: origData?.fields?.dataSet.map((item) => item?.fields?.value?.value),
          },
        ],
      },
    };
  } else if (chartType === 'Number') {
    newChartData = {
      chartType: origData?.fields?.type?.value,
      data: {
        preText: origData?.fields?.preText?.value,
        statisticValue: origData?.fields?.statisticValue?.value,
        postText: origData?.fields?.postText?.value,
      },
    };
  }
  return newChartData;
};
