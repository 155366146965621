import React from 'react';
import PropTypes from 'prop-types';
import './decisionTreeBreadcrumb.scss';
import { BreadcrumbDataAttributes } from '../../../constants/gtm';

const DecisionTreeBreadcrumb = ({ breadcrumbs }) => {
  return (
    <section className="dt-breadcrumb">
      <div className="dt-breadcrumb__container">
        {breadcrumbs &&
          breadcrumbs.map((breadcrumb, index) => (
            <div
              key={index}
              className="dt-breadcrumb__item"
              {...BreadcrumbDataAttributes({
                name: 'Breadcrumb',
                dataItemName: 'DecisionTree',
                linkText: breadcrumb || '',
              })}
            >
              {breadcrumb}
            </div>
          ))}
      </div>
    </section>
  );
};

DecisionTreeBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.array,
};

DecisionTreeBreadcrumb.defaultProps = {
  breadcrumbs: [],
};

export default DecisionTreeBreadcrumb;
