import React from 'react';
import PropTypes from 'prop-types';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import BreadcrumbClass from '../Breadcrumb/Breadcrumb';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import Player from '../Player/Player';
import Tag from '../../../globals/buttons/Tag';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import {
  CONTENT_TYPE_ARTICLE_STR,
  CONTENT_TYPE_PODCAST_STR,
  CONTENT_TYPE_REPORT_STR,
  CONTENT_TYPE_VIDEO_STR,
  CONTENT_TYPE_WEBINAR_STR,
} from '../../../constants/contenttype';
import moment from 'moment';
import { getCountryLangForOT } from '../../../utils/HelperUtils';
import { useLocation } from 'react-router-dom';

const BannerInsights = (props) => {
  const {
    fields,
    Breadcrumb = [],
    sitecoreContext,
    rendering: { componentName = '' },
  } = props;
  const { route } = sitecoreContext;
  const contentTypeValue = !isEmpty(route?.fields) ? route?.fields?.contentType?.name : '';
  const contentValue = !isEmpty(route?.fields)
    ? route?.fields?.contentType?.fields?.title?.value
    : '';
  const {
    align,
    author,
    backgroundImage,
    backgroundImageMobile,
    ctaArrow,
    ctaLink,
    ctaStyle,
    ctaText,
    paragraphText,
    subTitle = {},
    title,
    videoFallbackImage,
  } = fields;
  const getResourcesValues = () => {
    switch (contentTypeValue) {
      case CONTENT_TYPE_PODCAST_STR:
        return sitecoreContext?.route?.fields?.podcastResources;
      case CONTENT_TYPE_ARTICLE_STR:
        return {
          publicationDate: sitecoreContext?.route?.fields?.publicationDate?.value
            ? sitecoreContext?.route?.fields?.publicationDate?.value
            : '',
          contentType: sitecoreContext?.route?.fields?.contentType?.fields?.title?.value
            ? sitecoreContext?.route?.fields?.contentType?.fields?.title?.value
            : '',
        };
      case CONTENT_TYPE_REPORT_STR:
        return {
          publicationDate: sitecoreContext?.route?.fields?.publicationDate?.value
            ? sitecoreContext?.route?.fields?.publicationDate?.value
            : '',
          contentType: sitecoreContext?.route?.fields?.contentType?.fields?.title?.value
            ? sitecoreContext?.route?.fields?.contentType?.fields?.title?.value
            : '',
        };
      case CONTENT_TYPE_VIDEO_STR:
        return sitecoreContext?.route?.fields?.videoContent
          ? sitecoreContext?.route?.fields?.videoContent?.value
          : '';
      case CONTENT_TYPE_WEBINAR_STR:
        return sitecoreContext?.route?.fields?.videoContent
          ? sitecoreContext?.route?.fields?.videoContent?.value
          : '';
      default:
        return {};
    }
  };
  const resourceValues = getResourcesValues(contentTypeValue);
  const alignValue = align?.fields?.text?.value || '';
  const authorValue = author?.value || '';
  const ctaArrowValue = ctaArrow?.value || '';
  const ctaTextValue = ctaText?.value;
  const paragraphTextValue = paragraphText?.value;
  const subTitleValue = subTitle?.value;
  const titleValue = title?.value;
  const backgroundColor = 'on-secondary';
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });

  const breadcrumbItems =
    Breadcrumb && Array.isArray(Breadcrumb) && Breadcrumb[0]?.props?.fields?.data?.item;
  const location = useLocation();
  const getCountry = getCountryLangForOT(location.pathname);
  const countryVal =
    getCountry && !isEmpty(getCountry) && getCountry.includes('-')
      ? `${getCountry.split('-')[1]}`
      : '';
  const dateValue = countryVal === 'us' || countryVal === 'ca' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  return (
    <section
      className={`banner-section insights subpage ${contentTypeValue}`}
      {...ComponentDataAttributes(componentName)}
    >
      {contentTypeValue === CONTENT_TYPE_PODCAST_STR ||
      contentTypeValue === CONTENT_TYPE_ARTICLE_STR ||
      contentTypeValue === CONTENT_TYPE_REPORT_STR ? (
        <div className="container-xl">
          <div className={`row content-wrapper ${alignValue}`}>
            <div
              className={`col-lg-7 content L3Content ${alignValue === 'left' ? 'offset-lg-1' : ''}`}
            >
              {breadcrumbItems && (
                <BreadcrumbClass
                  items={breadcrumbItems}
                  cssClass={`${
                    backgroundColor && backgroundColor?.fields?.text?.value === 'secondary'
                      ? 'on-secondary'
                      : 'on-primary'
                  }`}
                  componentName={componentName}
                  // textColor={`${
                  //   backgroundColor && backgroundColor?.fields?.text?.value === 'secondary'
                  //     ? 'secondary'
                  //     : 'primary'
                  // }`}
                />
              )}
              {!isEmpty(titleValue) && (
                <h1 className="title_l3">
                  <TextSitecoreField field={title} />
                </h1>
              )}
              {!isEmpty(subTitleValue) && (
                <div className="sub-title">
                  <TextSitecoreField field={subTitle} />
                </div>
              )}

              {!isEmpty(paragraphTextValue) && (
                <div className="large_l3">
                  <RichTextSitecoreField field={paragraphText} />
                </div>
              )}
              {(contentTypeValue === CONTENT_TYPE_REPORT_STR ||
                contentTypeValue === CONTENT_TYPE_ARTICLE_STR) &&
                !isEmpty(ctaTextValue) && (
                  <div className="cta-wrapper">
                    <ButtonHref
                      text={<TextSitecoreField field={ctaText} />}
                      href={ctaLink?.value?.href}
                      cssClass=""
                      variant={ctaStyle?.fields?.text?.value || 'primary-button'}
                      arrow={ctaArrowValue || false}
                      linkFields={ctaLink}
                      linkType={ctaLink?.value?.linktype}
                      dataItemName={title?.value}
                      componentName={componentName}
                    />
                  </div>
                )}
              <div className="content-type">
                {contentValue}
                {(contentTypeValue === CONTENT_TYPE_ARTICLE_STR ||
                  contentTypeValue === CONTENT_TYPE_REPORT_STR) && (
                  <div className="label">
                    {!sitecoreContext?.route?.fields?.publicationDate?.value.includes('0001') && (
                      <>
                        {authorValue && `${authorValue}, `}
                        {moment(sitecoreContext?.route?.fields?.publicationDate?.value)
                          .utc()
                          .format(dateValue)}
                      </>
                    )}
                  </div>
                )}

                {!(
                  contentTypeValue === CONTENT_TYPE_ARTICLE_STR ||
                  contentTypeValue === CONTENT_TYPE_REPORT_STR
                ) && (
                  <div className="label">
                    {!sitecoreContext?.route?.fields?.publicationDate?.value.includes('0001') &&
                      moment(sitecoreContext?.route?.fields?.publicationDate?.value)
                        .utc()
                        .format(dateValue)}
                  </div>
                )}
              </div>
              {contentTypeValue === CONTENT_TYPE_PODCAST_STR && (
                <div className="pagebannerl3-tag">
                  {resourceValues &&
                    Array.isArray(resourceValues) &&
                    resourceValues?.map((item) => {
                      const { id, fields } = item;
                      const { ctaLink, ctaText, icon } = fields;
                      return (
                        <Tag
                          key={id}
                          icon={icon}
                          text={<TextSitecoreField field={ctaText} />}
                          onClick={() => {
                            location.href = ctaLink?.value?.href;
                          }}
                          dataItemName={ctaText?.value}
                          componentName={componentName}
                        />
                      );
                    })}
                </div>
              )}
            </div>
            <div
              className={`col-lg-4 L3Image col-xl-5 col-sm-12 col-md-12 image-wrapper ${
                alignValue === 'right' || alignValue === '' ? 'offset-lg-1' : ''
              }`}
            >
              {isMobileScreen ? (
                <ImageSitecoreField field={backgroundImageMobile} />
              ) : (
                <ImageSitecoreField field={backgroundImage} />
              )}
            </div>
          </div>
        </div>
      ) : null}
      {contentTypeValue === CONTENT_TYPE_WEBINAR_STR ||
      contentTypeValue === CONTENT_TYPE_VIDEO_STR ? (
        <div>
          {resourceValues ? (
            <Player value={resourceValues} componentName={componentName} />
          ) : (
            <div>
              {!isEmpty(videoFallbackImage) && <ImageSitecoreField field={videoFallbackImage} />}
            </div>
          )}
        </div>
      ) : null}
    </section>
  );
};

BannerInsights.defaultProps = {
  Breadcrumb: [],
  sitecoreContext: {
    route: {},
  },
  fields: {},
};

BannerInsights.propTypes = {
  Breadcrumb: PropTypes.array,
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
  }),
  fields: PropTypes.shape({}),
};

const BannerInsightsContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'Breadcrumb' }])(BannerInsights)
);

export default BannerInsightsContainer;
