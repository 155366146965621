import React, { useState, useEffect } from 'react';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { getBrowserWindow } from '../../../utils/HelperUtils';
import PropTypes from 'prop-types';
import './decisionTreeCta.scss';
import { ButtonDataAttributes } from '../../../constants/gtm';

const DecisionTreeCta = ({ label, icon, onClick }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (getBrowserWindow) {
      window?.addEventListener('scroll', () => {
        setScroll(window.scrollY > 50);
      });
    }
  }, [scroll]);

  return (
    <div className="dt-cta">
      <div className={`dt-cta__wrapper ${scroll ? 'dt-cta__collapsed' : ''}`}>
        <button
          type="button"
          id="dt-cta"
          className="dt-cta__button"
          onClick={onClick}
          {...ButtonDataAttributes({
            name: 'DecisionTree',
            text: label.value,
            dataItemName: label.value,
            variant: 'primary',
            arrow: false,
          })}
        >
          <ImageSitecoreField field={icon}></ImageSitecoreField>
        </button>
        <label htmlFor="dt-cta" className="dt-cta__label">
          <TextSitecoreField field={label}></TextSitecoreField>
        </label>
      </div>
    </div>
  );
};

DecisionTreeCta.defaultProps = {
  label: '',
  icon: '',
};

DecisionTreeCta.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default DecisionTreeCta;
