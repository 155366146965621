import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../src/globals/buttons/Button';
import Tag from '../../../globals/buttons/Tag';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';

const ActionBarTags = ({ title, items, cssClass, componentName, contentType }) => {
  return (
    <div className="action-bar related-content">
      {title && (
        <div className={`title ${cssClass}`}>
          <TextSitecoreField field={title} />
        </div>
      )}
      <div className="tag-wrapper-banner width-150">
        {items &&
          items?.map((item) => (
            <Tag
              key={item?.fields?.id}
              cssClass={cssClass}
              icon={item?.fields?.icon || item?.fields?.icon?.src}
              icon2={item?.fields?.iconSecond || item?.fields?.iconSecond?.src}
              text={item?.fields.title?.value}
              variant={item?.fields?.variant}
              fontSize="small"
              dataItemName={title || ''}
              componentName={componentName}
            />
          ))}
        {contentType && (
          <Button
            text={contentType?.fields?.title?.value}
            cssClass="tag card-global"
            fontSize="small"
            componentName={componentName}
            dataItemName={contentType?.fields?.title?.value}
          />
        )}
      </div>
    </div>
  );
};

ActionBarTags.defaultProps = {
  title: '',
  items: [],
  cssClass: '',
};

ActionBarTags.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  cssClass: PropTypes.string,
};

export default ActionBarTags;
