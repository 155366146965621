import React from 'react';
import PropTypes from 'prop-types';
import { Link as JssLink, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { NavigationFooterDataAttributes } from '../../constants/gtm';

const Atag = (props) => {
  const { text, href, linkType, linkFields } = props;

  let linkVal = null;
  linkVal =
    linkType === 'internal' ? (
      <Link
        to={href}
        target={linkFields?.value?.target || linkFields?.target}
        {...NavigationFooterDataAttributes({
          name: 'FooterDisclaimer',
          dataNavItemLevel: 'L0',
          dataNavItemName: text || '',
          linkText: linkType === 'internal' ? href : linkFields?.value?.href || '',
          url: '',
        })}
      >
        {text}
      </Link>
    ) : (
      <JssLink
        field={linkFields}
        {...NavigationFooterDataAttributes({
          name: 'FooterDisclaimer',
          dataNavItemLevel: 'L0',
          dataNavItemName: text || '',
          linkText: linkType === 'internal' ? href : linkFields?.value?.href || '',
          url: '',
        })}
      >
        {text}
      </JssLink>
    );
  return (
    // <a
    //   {...NavigationFooterDataAttributes({
    //     name: 'FooterDisclaimer',
    //     dataNavItemLevel: '',
    //     dataNavItemName: 'Footer Nav link' || '',
    //     linkText: text || '',
    //     url: linkType === 'internal' ? href : linkFields?.value?.href || '',
    //   })}
    //   aria-label={text}
    // >
    linkVal
    // </a>
  );
};

Atag.defaultProps = {
  text: 'Button',
  href: '',
  linkType: 'internal',
  linkFields: '',
};

Atag.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
};

export default withSitecoreContext()(Atag);
