import React, { useState } from 'react';
import Button from '../../../globals/buttons/Button';
import axios from 'axios';
import CheckboxFn from '../../../globals/forms/CheckboxFn';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import PropTypes from 'prop-types';

function SiteFooterNewsletter(props) {
  const [focused, setFocused] = useState(false);
  const [cbCheck, setCbCheck] = useState(false);
  const [email, setEmail] = useState('');
  const [resData, setResData] = useState('');
  const [error, setError] = useState('');
  const [cbError, setCbError] = useState('');

  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    EmailPlaceholderText,
    HubSpotDescription,
    HubspotFormID,
    HubspotPortalID,
    LegalConsent,
    SignUpText,
    hubspotTitle,
  } = fields;

  const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/HubSpot/FormSubmit`;

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (email.length < 3) {
      setError('');
    }
    if (email.length >= 3) {
      validateEmail();
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i;

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return false;
    }
    setError('');
    return true;
  };
  /** This logic needs to be checked and re-written - PR comments added by Kavitha. */
  const validateCb = () => {
    if (cbCheck === false) {
      setCbError('Please check all required fields');
      return false;
    }
    if (cbCheck === true) {
      setCbError('');
      return true;
    }
  };

  async function apiPost() {
    if (email.length <= 3) {
      setError('Please enter a valid email address');
    }
    if (email.length > 3 && error === '' && validateCb()) {
      const data = {
        FormId: HubspotFormID.value,
        PortalID: HubspotPortalID.value,
        JsonData: `{"fields" : [{"name" : "email","value" : "${email}"}]}`,
      };
      try {
        const apiCall = await axios.post(url, data);
        const apiRes = await JSON.parse(apiCall?.data?.message);
        setResData(apiRes?.inlineMessage);
      } catch (error) {}
      setEmail('');
      setCbCheck(false);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && error === '') {
      apiPost();
    }
  };

  const blurFunction = () => {
    if (email.length !== 0) {
      validateEmail();
    }
  };
  return (
    <>
      <div className="container-xl" {...ComponentDataAttributes(componentName)}>
        <div className="row">
          <div className="col">
            <div className="footer-rightside">
              <div className="row footer-newsletter">
                <div className="col">
                  <div className="title newsLetter">
                    <TextSitecoreField field={hubspotTitle} />
                  </div>
                  {!resData ? (
                    <div>
                      <div className="pretext">
                        <RichTextSitecoreField field={HubSpotDescription} />
                      </div>
                      <div className="newsletter-form-container">
                        <div className={`newsletter-form ${focused && !cbCheck ? 'active' : ''}`}>
                          <div className="form-block">
                            <input
                              // onFocus={handleFocus}
                              id="email-address"
                              type="email"
                              placeholder={EmailPlaceholderText?.value}
                              autoComplete="off"
                              value={email}
                              onChange={(e) => handleChange(e)}
                              onBlur={blurFunction}
                              onKeyDown={handleKeyPress}
                            />
                          </div>
                          {error && <span className="error-message">{error}</span>}

                          <div className="newsletter-form-disclaimer">
                            <div className="message">
                              <div className="posttext">
                                <CheckboxFn
                                  text={<RichTextSitecoreField field={LegalConsent} />}
                                  onClick={(e) => {
                                    setCbCheck(e.target.checked);
                                  }}
                                />
                                {cbError && <span className="error-message">{cbError}</span>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="newsLetter_left">
                        <Button
                          text={<TextSitecoreField field={SignUpText} />}
                          variant="text-button"
                          arrow
                          componentName={componentName}
                          dataItemName={hubspotTitle?.value}
                          BtnFunction={apiPost}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>{resData}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SiteFooterNewsletter.defaultProps = {
  fields: {},
};
SiteFooterNewsletter.propTypes = {
  fields: PropTypes.object,
};

export default SiteFooterNewsletter;
