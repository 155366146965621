import React from 'react';
import SiteHeaderLanguage from './SiteHeaderLanguage';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { UtilityTextBlock } from '../DetailsBlocks/Utility';

/** contrylinks */

/** @description - to display countryliknks
 * @param {Object} props - component props
 * @returns {HTML} - html
 */
const CountryLinkAdvanced = (props) => {
  const { fields, sitecoreContext } = props;
  const { data = {} } = fields;
  const { item = {} } = data;
  const { Title = '', Description = '', region = [] } = item;
  const linkItems = {
    items: region,
    selected: [],
  };

  return (
    <>
      <UtilityTextBlock title={Title} richText={Description} />
      <SiteHeaderLanguage linkItems={linkItems} sitecoreContext={sitecoreContext} />;
    </>
  );
};

CountryLinkAdvanced.defaultProps = {
  sitecoreContext: {
    route: {},
  },
  fields: {
    data: {
      item: {
        Title: '',
        Description: '',
        region: [],
      },
    },
  },
};

CountryLinkAdvanced.propTypes = {
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
  }),
  fields: PropTypes.shape({
    data: PropTypes.shape({
      item: PropTypes.shape({
        Title: PropTypes.string,
        Description: PropTypes.string,
        region: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
};

export default withSitecoreContext()(CountryLinkAdvanced);
