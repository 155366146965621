import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../globals/buttons/Tag';

const ArticleTags = ({ videoLength, tags, date, contentType, componentName, dataItemName }) => {
  const { value } = date;
  return (
    <div className={'card-details'}>
      <div className={`content-type tags tags__${tags.length}`}>
        {tags &&
          tags?.map((tag) => (
            <Tag
              key={tag?.id}
              cssClass={tag?.cssClass || ''}
              icon={tag?.icon}
              text={tag?.text}
              variant="article"
              fontSize="small"
              componentName={componentName}
              dataItemName={dataItemName}
            />
          ))}
        {videoLength && (
          <span className="button tag article light small article-podcast">{videoLength}</span>
        )}
        {contentType && <span className="bullet">&bull;</span>}
        {contentType}
      </div>
      <div className="date">{value ? value : date}</div>
    </div>
  );
};

ArticleTags.defaultProps = {
  cssClass: '',
  contentType: '',
  date: '',
  tags: [],
};

ArticleTags.propTypes = {
  cssClass: PropTypes.string,
  contentType: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.array,
};

export default ArticleTags;
