import React from 'react';
import PropTypes from 'prop-types';
import CardBenefit from '../Cards/CardBenefit';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardBlockBenefits = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { benefitList, subTitle, title } = fields;
  return (
    <div
      className={`card-block benefits benefits__${benefitList?.length}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row section-header">
          <div className="col-lg-10">
            <h2 className="title-benefits">
              <TextSitecoreField field={title} />
            </h2>
            {subTitle && (
              <p className="sub-title">
                <TextSitecoreField field={subTitle} />
              </p>
            )}
          </div>
        </div>
        <div className="row card-wrapper">
          {benefitList &&
            benefitList?.map((item, index) => (
              <CardBenefit key={index} data={item} componentName={componentName} />
            ))}
        </div>
      </div>
    </div>
  );
};

CardBlockBenefits.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    benefitList: [],
  },
  cssClass: '',
};

CardBlockBenefits.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    subTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
    benefitList: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string,
          }),
          description: PropTypes.shape({
            value: PropTypes.string,
          }),
          image: PropTypes.shape({
            value: PropTypes.string,
          }),
          linkTxt: PropTypes.shape({
            value: PropTypes.string,
          }),
          link: PropTypes.shape({
            value: PropTypes.string,
          }),
        }),
      })
    ),
  }),
  cssClass: PropTypes.string,
};

export default CardBlockBenefits;
