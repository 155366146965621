export const content = [
  {
    type: 'bar',
    data: {
      labels: ['United States', 'Singapore', 'Canada', 'Hong Kong', 'Netherlands'],
      datasets: [
        {
          label: 'Workforce rankings',
          data: [2.8, 2.7, 2.6, 2.4, 2.4],
          borderRadius: 100,
          barPercentage: 1.0,
          categoryPercentage: 0.8,
        },
      ],
      yValues: {
        min: 2,
        max: 2.9,
      },
    },
  },
  {
    type: 'bar-alt',
    data: {
      labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
      datasets: [
        {
          label: 'Dataset label goes here',
          data: [7.2, 8.8, 7.3, 4.4, 2.8, 1.2, 0.5, -0.5, -0.9, -2.5, -3, -1.4],
          borderRadius: 100,
          barPercentage: 1.0,
          categoryPercentage: 0.8,
        },
      ],
      yValues: {
        min: -4,
        max: 9,
        stepSize: 1,
      },
    },
  },
  {
    type: 'bubble',
    data: {
      labels: ['Costa Rica', 'Puerto Rico', 'Chile', 'Canada', 'United States'],
      datasets: [
        {
          label: 'Costa Rica',
          data: [{ x: 10, y: 40, r: 15 }],
        },
        {
          label: 'Puerto Rico',
          data: [{ x: 27, y: 13, r: 15 }],
        },
        {
          label: 'Chile',
          data: [{ x: 75, y: 60, r: 15 }],
        },
        {
          label: 'Canada',
          data: [{ x: 80, y: 75, r: 15 }],
        },
        {
          label: 'United States',
          data: [{ x: 72, y: 90, r: 15 }],
        },
      ],
      xValues: {
        min: 0,
        max: 100,
      },
      yValues: {
        min: 0,
        max: 100,
      },
      xLabel: 'Region productivity',
      yLabel: 'Region innovation',
    },
  },
  {
    type: 'doughnut',
    data: {
      labels: ['Baby boomer', 'Gen X', 'Gen Y', 'Other'],
      datasets: [
        {
          label: 'Net Secondary Education',
          fill: 'start',
          data: [35, 22, 18, 25],
          borderWidth: 0,
          borderColor: 'transparent',
        },
      ],
    },
  },
  {
    type: 'line',
    data: {
      labels: [
        1960, 1962, 1964, 1966, 1968, 1970, 1972, 1974, 1976, 1978, 1980, 1982, 1984, 1986, 1988,
        1990, 1992, 1994, 1996, 1998, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018,
        2020,
      ],
      datasets: [
        {
          label: 'Net Secondary Education',
          data: [
            74, 75, 76, 77.5, 78.2, 78.5, 79, 79.2, 80, 81, 82, 83.8, 84.2, 85.2, 86, 87, 87.2,
            87.4, 87.6, 87.8, 88, 88.2, 88.4, 89.6, 91, 92.45, 92.6, 92.8, 92.9, 93, 93,
          ],
          borderWidth: 2,
          spanGaps: true,
        },
      ],
      xMaxTicks: 7,
      yValues: {
        min: 50,
        max: 100,
        stepSize: 10,
      },
      yDataSuffix: '%',
    },
  },
  {
    type: 'linear-gauge',
    data: {
      labels: ['Regulation', 'Maturity'],
      datasets: [
        {
          label: 'High',
          data: [94],
        },
        {
          label: 'Mature',
          data: [100],
        },
      ],
      xValues: {
        min: 0,
        max: 100,
      },
    },
  },
];
